import React from 'react';
import successgif from '../../assets/successful.gif';
import { Middle, Tab } from '../../utils/common';
import PropTypes from 'prop-types';

export default function Success({ message }) {
  return (
    <Middle>
      <Tab>
        <img
          src={successgif}
          alt=""
          style={{ maxWidth: '100%', height: 'Auto' }}
          width={450}
        />
        <p>{message}</p>
      </Tab>
    </Middle>
  );
}

Success.propTypes = {
  message: PropTypes.string,
};

Success.defaultProps = {
  message: 'Successful',
};
