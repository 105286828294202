import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
//import Button from '../../../components/common/Button';
import { Center } from '../../../utils/common';
import TrustGroupFinanceRecordCard from './TrustGroupFinanceRecordCard';
// import { apiGet } from '../../../utils/apiHelper';
// import { toast } from 'react-toastify';
import { RiArrowDropDownLine, RiUploadLine } from 'react-icons/ri';
import {
  TrustGroupAdminDiv,
  ArsenalTrustGroupHeader as TrustGroupHeader,
  FilterActionModal,
  OverlayModal,
  ApprovedDiv as ClearedDiv,
  ApprovedText as ClearedText,
  FundedDiv as PaidDiv,
  FundedText as Paidtext,
  PendingDiv as DefaultingDiv,
  Pendingtext as Defaultingtext,
  Wrapper,
} from './PaymentRecord';
import dummyGroup from './dummyTrustGroup';
import Success from '../../../components/modal/Success';
import Modal from '../../../components/common/NewModal';
import CSVFileUploadForm from './CSVFIleUploadForm';
import Pagination from '../../../components/common/pagination';

const PageArea = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  @media screen and (max-width: 950px){ 
    grid-template-columns: 1fr;

  }
`;

const CsvAndFilterBtn = styled.div`
  display: flex;
`

const CsvButton = styled.div`
  width: 10rem;
  height: 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
  cursor: pointer;
  margin-right: 16px;
  padding: 0 6px;
  @media screen and (max-width: 768px){ 
    width: auto;
    height: 2.7rem;
    display: flex;
    background: rgba(0, 0, 0, 0.03);
    margin-right: 8px;

  }

  @media screen and (max-width: 420px){ 
  height: 2.7rem;
  display: flex;
  background: rgba(0, 0, 0, 0.03);
}
`;

const FireActionButton = styled.div`
  height: 44px;
  background: rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 420px) {
    margin-left: 0px;
  }
`;

const FilterText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #21334f;
  margin-left: 10px;
  white-space: nowrap;
`;

const CsvFileText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #21334f;
  margin-left: 7px;
  white-space: nowrap;

  @media screen and (max-width: 420px){ 
    font-size: 10px;
    line-height: 18px;
    margin-left: 2px;
  }
`;

export default function FinanceRecord() {
  const [trustGroups, setTrustGroups] = useState(dummyGroup);
  //const [trustGroupCSVFile, setTrustGroupCSVFile] = useState(dummyGroup);
  const [actionButton, setActionButton] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const toggleModal = () => setShowModal(!showModal);
  const navigate = useNavigate();
  const ListPerPage = 4;
  const PageVisited = pageNumber * ListPerPage;
  const onCSVFileUpload = () => {
    toggleModal();
    setShowSuccess(true);
    setTimeout(() => {
      navigate('/admin/finance-record');
      setShowSuccess(false);
    }, 5000);
  };
  
 
  // useEffect(() => {
  //   getTrustGroup();
  // }, []);

  // const getTrustGroup = async () => {
  //   const config = {
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //   };

  //   await apiGet(`/admin/get-all-trust-group`, config, true).then(
  //     (res) => {
  //       console.log(res);
  //       setTrustGroups(res.data.data);
  //     },
  //     (err) => {
  //       toast.error(err.response.data.message, { position: 'top-right' });
  //     }
  //   );
  // };

  const handleSetActionButton = () => {
    setActionButton(!actionButton);
  };

  const hideNotification = () => {
    setActionButton(false);
  };

  if (showSuccess) {
    return <Success message="CSV File uploaded successfully" />;
  }
  return (
    <Wrapper>
      <TrustGroupAdminDiv>
        <TrustGroupHeader>
          Finance Record
        </TrustGroupHeader>
        <CsvAndFilterBtn>
          <CsvButton onClick={() => toggleModal()}>
            <RiUploadLine
            />
            <CsvFileText>CSV File</CsvFileText>
          </CsvButton>
          <div style={{ position: 'relative' }}>
            <FireActionButton
              onClick={handleSetActionButton}
              style={{
                background: 'rgba(20, 168, 0, 0.05)',
              }}
            >
              <FilterText style={{ color: '#14a800' }}>
                Filter Actions{' '}
              </FilterText>
              <RiArrowDropDownLine
                style={{
                  fontSize: '35px',
                  marginTop: '4px',
                  cursor: 'pointer',
                  color: '#14a800',
                }}
              />
            </FireActionButton>
            {actionButton && (
              <>
                <FilterActionModal>
                  <PaidDiv>
                    <Paidtext>Paid</Paidtext>
                  </PaidDiv>
                  <ClearedDiv>
                    <ClearedText>Cleared</ClearedText>
                  </ClearedDiv>
                  <DefaultingDiv style={{ background: 'rgba(222, 30, 30, 0.1)' }}>
                    <Defaultingtext style={{ color: '#DE1E1E' }}>
                      Defaulting
                    </Defaultingtext>
                  </DefaultingDiv>
                </FilterActionModal>
                <OverlayModal onClick={hideNotification} />
              </>
            )}
          </div>
        </CsvAndFilterBtn>
        
      </TrustGroupAdminDiv>
      <PageArea>
        {trustGroups
          .slice(PageVisited, PageVisited + ListPerPage)
          .map((trustGroup, index) => {
            return (
              <TrustGroupFinanceRecordCard
                key={index}
                title={trustGroup.title}
                startDate={trustGroup.startDate}
                totalAmountLoaned={trustGroup.totalAmountLoaned}
                rentals={trustGroup.rentals}
                unpaidBalance={trustGroup.unpaidBalance}
                members={[
                  'https://picsum.photos/70',
                  'https://picsum.photos/80',
                  'https://picsum.photos/90',
                  'https://picsum.photos/100',
                ]}
                id={trustGroup.id}
              />
            );
          })}
      </PageArea>
      <Center>
        <Pagination
          fetcheddata={trustGroups}
          itemsPerPage={4}
          setPageNumber={setPageNumber}
        />
      </Center>

      <Modal
        style={{ width: '10rem' }}
        title="Upload CSV"
        isOpen={showModal}
        closeModal={toggleModal}
      >
        <CSVFileUploadForm closeModal={onCSVFileUpload} />
      </Modal>
    </Wrapper>
  );
}
