import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import UploadIcon from '../../assets/UploadIcon.svg';

const Input = styled.div`
  width: 100;
  height: 112px;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid #bdbdbd;
  position: relative;
`;

const InputHeader = styled.div`
  border-bottom: 0.5px solid #bdbdbd;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #21334f;
  font-weight: 600;
  font-size: 12px;
`;

const InputBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
`;

const Icon = styled.img`
  width: 25.52px;
  height: 24px;
`;

const UploadText = styled.p`
  font-size: 12px;
`;

export default function UploadCard({ title, file, setFile }) {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles) => setFile(acceptedFiles),
  });

  return (
    <div>
      <Input>
        <InputHeader>{title}</InputHeader>
        <InputBody>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <div className="text-center">
              <Icon src={UploadIcon} alt="" />
              <UploadText>Browse files here</UploadText>
            </div>
          </div>
        </InputBody>
      </Input>
    </div>
  );
}
