import React, { useState } from 'react';
import SupportShell from '../assets/SupportShell.svg';
import styled from 'styled-components';
import fpsideimage from '../assets/fpsideimage.svg';
import { Link, useSearchParams } from 'react-router-dom';
import { MarginStyle, Center } from '../utils/common';
import {
  Header,
  FormWrapper,
  ImageSection,
  LoginSection,
  LogoWrapper,
  NavLabel,
  Wrapper,
  BackButton,
} from '../components/auth/common';
import Input from '../components/auth/newinput';
import Button from '../components/common/Button';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import Success from '../components/modal/Success';
import { apiPost } from '../utils/apiHelper';
import { toast } from 'react-toastify';

const LoginLogo = styled.img.attrs({
  src: `${SupportShell}`,
})``;

const SideImage = styled.img.attrs({
  src: `${fpsideimage}`,
})`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;

function ResetPasswordPage() {
  const [userData, setUserData] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState({ password: '', confirmPassword: '' });

  const { newPassword, confirmPassword } = userData;

  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const navigate = useNavigate();

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkPasswordLength = () => {
    if (newPassword.length >= 8) {
      setIsValid(true);
      setErrors((prevState) => ({
        ...prevState,
        password: '',
      }));
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        password: 'Password is too short',
      }));
      return false;
    }
  };

  const checkPasswordsMatch = () => {
    if (newPassword === confirmPassword) {
      setErrors((prevState) => ({
        ...prevState,
        confirmPassword: '',
      }));
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        confirmPassword: 'Passwords do not match',
      }));
      return false;
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validate() && checkPasswordsMatch()) {
      await apiPost(
        '/auth/user/reset-password',
        {
          passwordToken: token,
          email,
          password: newPassword,
        },
        {},
        false
      ).then(
        (res) => {
          toast.success(res.data.message, { position: 'top-right' });
          setShowSuccess(true);
          setTimeout(() => {
            navigate('/login');
          }, 5000);
        },
        (err) => {
          toast.error(err.response.data.message, { position: 'top-right' });
        }
      );
    }
  };

  const validate = () => {
    if (newPassword.length === 0) {
      setErrors((prevState) => ({
        ...prevState,
        password: 'Please enter your password',
      }));
    } else if (checkPasswordLength()) {
      setIsValid(true);
    }
    return isValid;
  };

  if (showSuccess) {
    return <Success message="Password Reset Successful" />;
  }
  return (
    <Wrapper>
      <LoginSection>
        <LogoWrapper>
          <LoginLogo />
          <NavLabel>Support Shell</NavLabel>
        </LogoWrapper>
        <FormWrapper>
          <Header>Reset password</Header>
          <form>
            <Input
              type="password"
              label="Password"
              name="newPassword"
              value={newPassword}
              onChange={changeHandler}
              onBlur={checkPasswordLength}
              helperText="Must be atleast 8 characters"
              error={errors.password}
              errorMessage={errors.password}
            />

            <MarginStyle>
              <Input
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={changeHandler}
                onBlur={checkPasswordsMatch}
                error={errors.confirmPassword}
                errorMessage={errors.confirmPassword}
              />
            </MarginStyle>

              <Button
                type="submit"
                children="Reset Password"
                onClick={submitHandler}
              />

            <Center>
              <Link to="/Login" style={{ textDecoration: 'none' }}>
                <BackButton>
                  <BiArrowBack style={{ marginRight: '15px' }} /> Back to Login
                </BackButton>
              </Link>
            </Center>
          </form>
        </FormWrapper>
      </LoginSection>
      <ImageSection>
        <SideImage />
      </ImageSection>
    </Wrapper>
  );
}
export default ResetPasswordPage;
