import React, { useState } from 'react';
//import styled from 'styled-components';
import { apiPost, uploadFile } from '../../../utils/apiHelper';
import Button from '../../../components/common/Button';
import CSVUploadCard from './CSVUploadCard';
import { ErrorToast } from '../../../utils/toast';

// const Label = styled.h3`
//   font-weight: 600;
//   font-size: 12px;
//   color: #bdbdbd;
//   margin-top: 30px;
// `;

export default function CSVFileUploadForm({ closeModal }) {
  // const [file, setFile] = useState('');
  const [csvFile, setCsvFile] = useState('');
  const [loading, setLoading] = useState(false);

  const handleUpload = async (e) => {
    setLoading(true);
    if (csvFile) {
      const file = await uploadFile(csvFile[0]);
      // setFile((prev) => {
      //   return {
      //     ...prev,
      //     file,
      //   };
      // });
      await apiPost(`/transaction/upload-document`, file, true).then(
        (res) => {
          setLoading(false);
        },
        (err) => {
          if (!err.response) {
            ErrorToast('Something went wrong');
          } else {
            ErrorToast(err.response.data.message);
          }
          setLoading(false);
        }
      );
    }
    setLoading(false);
    closeModal(e);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <CSVUploadCard
            title="Upload CSV"
            name="csvFile"
            file={csvFile}
            setFile={(e) => setCsvFile(e)}
            fileNames={csvFile && <p>{csvFile[0].name}</p>}
          />
        </div>
      </div>
      <Button
        type="button"
        onClick={(e) => handleUpload(e)}
        disabled={!csvFile}
        loading={loading}
        style={{
          background: '#14A800',
          color: '#ffffff',
          marginTop: '1rem',
          width: '100%',
        }}
      >
        Upload CSV
      </Button>
    </div>
  );
}
