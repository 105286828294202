import React from 'react';
import { useSearchParams } from 'react-router-dom';
import CalendarSection from '../../../components/userHome/CalendarSection';
import LoanBalance from '../../../components/userHome/LoanBalance';
import RepaymentPlan from '../../../components/userHome/RepaymentPlan';
import RepaymentProgress from '../../../components/userHome/RepaymentProgress';
import TransactionHistory from '../../../components/userHome/TransactionHistory';
import { useAuth } from '../../../context/auth/AuthState';
import { PageTitle } from '../../../utils/common';

export default function Home() {
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');
  const { user } = useAuth();
  return (
    <div>
      <PageTitle>Welcome back {user.fullname}</PageTitle>
      <div className="row gx-3">
        <div className="col-md-8">
          <LoanBalance status={status}/>
          <div className="row gx-3 gy-3">
            <div className="col-md-6 pt-3">
              <RepaymentProgress />
            </div>
            <div className="col-md-6 pt-3">
              <RepaymentPlan />
            </div>
          </div>
          <TransactionHistory />
        </div>
        <div className="col-md-4">
          <CalendarSection />
        </div>
      </div>
    </div>
  );
}
