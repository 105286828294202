import React from 'react';
import styled from 'styled-components';
import { PageTitle, SpaceBetween } from '../../../utils/common';
import NotificationItem from './NotificationItem';

const NotificationWrapper = styled.p`
  min-height: 100vh;
`;

const Cta = styled.p`
  color: #979797;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

export default function Notification() {
  return (
    <NotificationWrapper>
      <SpaceBetween style={{ marginBottom: '30px' }}>
        <PageTitle>Notifications</PageTitle>
        <Cta>Clear all</Cta>
      </SpaceBetween>
      <NotificationItem />
      <NotificationItem />
    </NotificationWrapper>
  );
}
