import React, { useState } from 'react';
import {
  Container,
  Paragraph,
  TitleSecurityLog,
  SettingWrapper,
  Div,
} from '../../../utils/common';
import styled from 'styled-components';
import ToggleButton from '../../../components/common/ToggleButton';

const Hr = styled.hr`
  border: 1px solid rgba(144, 144, 144, 0.2);
`;

// const Placeholder = styled.h5`
//   position: absolute;
//   width: 9px;
//   height: 18px;
//   left: 23px;
//   top: 8px;

//   font-family: Mulish;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 18px;
//   /* identical to box height */

//   color: #979797;
// `;

const CustomInput = styled(Div)`
  display: flex;
  padding-top: 5px;
  padding-left: 7px;
  padding-right: 7px;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px
  height: 34px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  color: #979797;

  
`;

export default function ManageGroups() {
  const [selected, setSelected] = useState(false);
  return (
    <SettingWrapper>

      < TitleSecurityLog>Manage Groups</ TitleSecurityLog>
      <Container>
        <Paragraph
          style={{ color: '#000000', fontWeight: 'bold', fontSize: '14px' }}
        >
          {' '}
          Set limitations to group
        </Paragraph>
      </Container>
      <Container>
        <Paragraph style={{ marginLeft: '0%' }}>
          These are settings that will determine the number of users in a group.
        </Paragraph>
      </Container>
      <Hr />
      <Container></Container>
      <Paragraph
        style={{ color: '#000000', fontWeight: 'bold', fontSize: '14px' }}
      >
        Minimum Number of Participants
      </Paragraph>

      <Container>
        <Paragraph style={{ marginLeft: '0%' }}>
          Limit how many users join a single group
        </Paragraph>
        <Container style={{ marginLeft: '10%' }}>
          {' '}
          <CustomInput> 0 </CustomInput>{' '}
        </Container>
      </Container>

      <Container style={{ width: '65%' }}></Container>
      <Paragraph
        style={{ color: '#000000', fontWeight: 'bold', fontSize: '14px' }}
      >
        Maximum Number of Participants
      </Paragraph>
      <Container>
        <Paragraph style={{ marginLeft: '0%' }}>
          Add many users join a single group
        </Paragraph>
        <Container style={{ marginLeft: '10%' }}>
          {' '}
          <CustomInput> 0 </CustomInput>{' '}
        </Container>
      </Container>

      <Paragraph
        style={{ color: '#000000', fontWeight: 'bold', fontSize: '14px' }}
      >
        {' '}
        Allow User Send you Private Message
      </Paragraph>
      <Container>
        <Paragraph style={{ marginLeft: '0%' }}>
          Any user in a group can send you a direct message
        </Paragraph>
        <Container style={{ marginLeft: '10%' }}>
          {' '}
          <ToggleButton
            selected={selected}
            toggleSelected={() => {
              setSelected(!selected);
            }}
            buttonColor="#000000"
          />{' '}
        </Container>
      </Container>
    </SettingWrapper>
  );
}
