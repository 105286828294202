import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import Logo from '../../assets/Logo.svg';
import LogoSupport from '../../assets/LogoSupport.svg';
import Contact from '../../assets/Contact.svg';
// import './HomePageContactAdmin.css';

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: #E5E5E5;
    @media (max-width: 768px){
        justify-content: start;
        padding-top: 49px;
    }
`

const ImageLogo = styled.img`
    height: 79.02333068847656px;
    width: 259px;
    left: 591px;
    border-radius: 0px;
   
`

const Article = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 720px;
    height: 413px;
    background: #FFFFFF;
    left: 365px;
    top: 200px;
    margin: 50px;
    bottom: 200px;   
    @media (max-width: 768px){
        width: 90%;
        padding: 0 16px;
    } 
`

const Image = styled.img`
       width: 183px;
       height: 103px;
       left: 264px;
       top: 100px;
       bottom: 300px;
       justify-content: center;
       align-item
       
    
`
const Heading = styled.h1`
    font-family: Mulish;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    left: 241px;
    top: 90px;
    right: 240px;
    bottom: 168px;
    color: #21334F;

`
const Text = styled.p`
    font-family: Mulish;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #21334F;
    left: 170px ;
    top: 350px ;
    right: 170px ;
    bottom: 150px;
    padding: 0px;
`
const Connect = styled.button`
       display: flex;
       flex-direction: row;
       justify-content: center;
       align-items: center;
       background: #14A800;
       width: 224px;
       height: 52px;
       left: 250px;
       top: 300px;
       border-radius: 4px;
       border: none;
       color: #fff;
       
`

// const Link = styled.a`
//   text-decoration: none;
//   color: white;
// `


export default function HomePageContactAdmin(){
      return (
        <section>
            <SectionWrapper>
            <Link to="/" ><ImageLogo src={LogoSupport} alt="Support Shell Logo" /></Link>
            <Article>
                <Image src={Contact} alt="Contact Logo" />
                <Heading>Contact Admin</Heading>
                <Text>Please contact the Admin to sign up to Support Shell</Text>
                <Connect>Contact Admin</Connect>
            </Article>
            </SectionWrapper>   
            
         </section>
       )
     }
