import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import successgif from '../../assets/successful.gif';
import { BackButton, Center, Middle, Tab } from '../../utils/common';

const Bold = styled.div`
  font-weight: bold;
  padding: 0.07rem;
`;
const Div = styled.div`
  display: flex;
  justify-content: center;
  height: 35rem;
  width: 35rem;
  margin: auto;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;
  @media (max-width: 786px) {
    width: 15rem;
    padding: 1rem;
    margin-top: 1.5rem;
  }
`;
const Img = styled.img`
  max-width: 15rem;
  height: 15rem;
  width: 15rem;
  @media (max-width: 786px) {
    max-width: 10rem;
    height: 10rem;
    width: 10rem;
  }
`;
const Heading = styled.div`
  text-align: center;
  font-family: Mulish;
  font-weight: bold;
  font-size: 45px;
  color: #000000;
  @media (max-width: 786px) {
    font-size: 25px;
  }
  @media (max-width: 426px) {
    font-size: 16px;
  }
`;
export default function UserCreated({ userId }) {
  return (
    <Div>
      <Tab>
        <Img src={successgif} alt="" />
        <Heading>Created Successfully </Heading>
        <Center>
          <BackButton
            style={{
              color: '#14A800',
              border: '#14A800 2px solid',
              background: '#ffffff',
            }}
          >
            User Login ID <Bold> {userId}</Bold>
          </BackButton>
        </Center>
        <Center>
          <Link to="/admin/home" style={{ textDecoration: 'none' }}>
            <BackButton
              style={{
                color: '#ffffff',
                border: '#14A800',
                background: '#14A800',
              }}
            >
              Go back Home
            </BackButton>
          </Link>
        </Center>
      </Tab>
    </Div>
  );
}
