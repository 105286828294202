import React, { useState } from 'react';
import { Div, MarginStyle, Paragraph } from '../../utils/common';
import styled from 'styled-components';
import Input from '../../components/common/Input';
import { FiPhoneCall } from 'react-icons/fi';
import { AiTwotoneMail } from 'react-icons/ai';
import { MdLocationPin } from 'react-icons/md';
import Button from '../../components/common/Button';
import Success from '../../components/modal/Success';
import TextArea from '../../components/common/TextArea';

const ContactForm = styled.form`
  width: 55%;
  background-color: #21334f;
  border-radius: 6px;
  padding: 3rem;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
const InputExtension = styled(Input)`
  color: #ffffff;
  p {
    color: #ffffff;
  }
`;
const ContactLayout = styled.div`
  background: #ffffff;
  padding: 3rem;
  display: flex;
  justify-content: center;
  text-align: left;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 1.2rem;
  }
`;

const ContactInfoWrapper = styled.div`
  margin-top: 5rem;
  @media (max-width: 1200px) {
    margin-top: 1rem;
  }
`;

const ContactInformation = styled.p`
  color: #000000;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 1.5rem;
  font-style: normal;
`;

export default function ContactAdmin() {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState({
    email: '',
    name: '',
    message: '',
  });

  const { name, email, message } = userData;
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkFullName = () => {
    if (
      !new RegExp(
        /^([A-Za-z]{3,16})([ ]{1})([A-Za-z]{3,16})?([ ]{1})?([A-Za-z]{3,16})$/
      ).test(name)
    ) {
      setError((prevState) => ({
        ...prevState,
        name: 'Enter fullname',
      }));
      return false;
    } else {
      setError((prevState) => ({
        ...prevState,
        name: '',
      }));
      return true;
    }
  };

  const checkeEmail = () => {
    if (
      !new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(email)
    ) {
      setError((prevState) => ({
        ...prevState,
        email: 'Enter a valid email',
      }));
      return false;
    } else {
      setError((prevState) => ({
        ...prevState,
        email: '',
      }));
      return true;
    }
  };
  const checkMessage = () => {
    if (message.length > 2) {
      setIsValid(true);
      setError((prevState) => ({
        ...prevState,
        message: '',
      }));
      return true;
    } else {
      setIsValid(false);
      setError((prevState) => ({
        ...prevState,
        message: 'Enter message',
      }));
      return false;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (checkFullName() && checkeEmail() && checkMessage()) {
      // Handle API Request
      isValid(false);
      setShowSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  if (showSuccess) {
    return <Success />;
  }
  return (
    <ContactLayout>
      <Div>
        <Div style={{ fontSize: '18px' }}>Need assistance?</Div>
        <Div
          style={{
            fontSize: '36px',
            fontWeight: 'bold',
          }}
        >
          Talk to Admin
        </Div>
        <Paragraph style={{ color: '#000000', width: '80%', fontSize: '18px' }}>
          Fill up the form and an admin will get in touch with you
        </Paragraph>
        <ContactInfoWrapper>
          <ContactInformation>
            {' '}
            <span style={{ color: '#14A800', paddingRight: '1rem' }}>
              <FiPhoneCall />
            </span>
            +234 806 674 9873
          </ContactInformation>
          <ContactInformation>
            <span style={{ color: '#14A800', paddingRight: '1rem' }}>
              <AiTwotoneMail />
            </span>{' '}
            hello@supportshell.com
          </ContactInformation>
          <ContactInformation>
            <span style={{ color: '#14A800', paddingRight: '1rem' }}>
              <MdLocationPin />
            </span>{' '}
            34, Lekki Road, Lekki, Lagos
          </ContactInformation>
        </ContactInfoWrapper>
      </Div>
      <ContactForm>
        <form style={{ fontColor: '#ffffff' }}>
          <InputExtension
            type="text"
            label="Your name"
            name="name"
            value={name}
            labelColor={'#ffffff'}
            onChange={changeHandler}
            onBlur={checkFullName}
            error={error.name && true}
            errorMessage={error.name}
          />
          <Input
            type="email"
            label="Email"
            name="email"
            value={email}
            labelColor={'#ffffff'}
            onChange={changeHandler}
            onBlur={checkeEmail}
            error={error.email && true}
            errorMessage={error.email}
          />
          <TextArea
            label="Message"
            name="message"
            value={message}
            labelColor={'#ffffff'}
            onChange={changeHandler}
            rows={7}
            columns={42}
            onBlur={checkMessage}
            error={error.message && true}
            errorMessage={error.message}
          />
          <MarginStyle>
            <Button type="submit" children="Send" onClick={submitHandler} />
          </MarginStyle>
        </form>
      </ContactForm>
    </ContactLayout>
  );
}
