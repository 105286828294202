import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
const ModalContent = styled.div`
  width: 635px;
  background: #ffffff;
`;
const ModalHeader = styled.div`
  position: relative;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ fontColor }) => (fontColor ? fontColor : '#1d2126')};
  font-weight: bold;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  @media screen and (max-width: 786px) {
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
  }
  padding-top: 1rem;
`;
const CloseButton = styled.button`
  position: absolute;
  right: 57px;
  border: 0;
  background: transparent;
  font-weight: bold;
`;

const ModalBody = styled.div`
  padding: 30px 57px;
  @media screen and (max-width: 786px) {
    padding: 15px 30px;
  }
`;

const ModalArea = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  @media screen and (max-width: 786px) {
    margin: 3rem 2rem 0rem 0rem;
  }
`;

export default function Modal({
  title,
  children,
  isOpen,
  closeModal,
  fontSize,
  fontColor,
}) {
  return (
    <ModalArea isOpen={isOpen}>
      <ModalContent>
        <ModalHeader style={{ fontSize: `${fontSize}`, color: `${fontColor}` }}>
          {title}
          <CloseButton onClick={() => closeModal()}>&times;</CloseButton>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </ModalArea>
  );
}
Modal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
