import React, { useState, useEffect } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import styled from 'styled-components';
import { Center, Container } from '../../../utils/common';
import { apiGet } from '../../../utils/apiHelper';
import { Badge } from '../../../components/userHome/TransactionHistory';
import MemberDetail from './MemberDetail';
import Button from '../../../components/common/Button';
import { toast } from 'react-toastify';
import Pagination from '../../../components/common/pagination';

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  font-family: Mulish;
  line-height: 30px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 36px 0 16px 0;
`;

// const ViewAll = styled.a`
//   width: 122px;
//   height: 35px;
//   background: rgba(20, 168, 0, 0.05);
//   border-radius: 6px;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   text-decoration: none;
//   color: #14a800;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 12px;
//   &:hover {
//     color: #14a800;
//   }
// `;

const Table = styled.table`
  width: 100%;
  @media screen and (max-width: 426px) {
    width: 36rem;
  }
`;

const TR = styled.tr``;

const THead = styled.thead`
  background: #ffffff;
  height: 48px;
  ${TR} {
    background: #ffffff;
  }
`;

const TH = styled.td`
  border: 1px solid #dadada;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 0 10px;
  text-align: center;
`;

const TBody = styled.tbody`
  ${TR} {
    .active {
      background: rgba(144, 144, 144, 0.2);
    }
    cursor: pointer;
  }
`;

const TD = styled.td`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  background: rgba(246, 246, 246, 0.49);
  border: 1px solid #dadada;
  height: 40px;
  padding: 0 10px;
`;

const Sort = styled.div`
  width: 80px;
  height: 44px;

  font-size: 14px;

  background: rgba(20, 168, 0, 0.05);
  border-radius: 4px;
  color: #000000;
  margin-top: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShowMore = styled(Button)`
  border: 1px solid #000000;
  width: 176px;
  height: 50px;
  color: #000000;
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  margin-top: 24px;
`;

const ContentArea = styled.div`
  background: #ffffff;
  border-radius: 4px;
  display: flex;
`;

export default function RegisteredMembers() {
  const [members, setMembers] = useState([]);
  const [activeIndex, setActiveIndex] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const ListPerPage = 10;
  const PageVisited = pageNumber * ListPerPage;

  const toggleShowDetails = () => setShowDetails(!showDetails);
  const closeDetails = () => {
    setActiveIndex('');
    toggleShowDetails();
  };

  useEffect(() => {
    apiGet(`/admin/members?pageNo=${pageNumber}`, {}).then(
      (res) => {
        console.log(res);
        setMembers(res.data.data.content);
      },
      (err) => {
        toast.error(err.response.data.message, { position: 'top-right' });
      }
    );
  }, [pageNumber]);

  return (
    <div>
      <Top>
        <Title>All Registered Members</Title>
        <Container>
          <Sort style={{ borderRight: '1px Solid #979797' }}>Sort By</Sort>
          <Sort>
            Newest <FaAngleDown />
          </Sort>
        </Container>
      </Top>
      <ContentArea>
        <Table>
          <THead>
            <TR>
              <TH>Name</TH>
              <TH>Trust Group</TH>
              <TH>Phone Number</TH>
              <TH>Date Created</TH>
              <TH>Registration Status</TH>
            </TR>
          </THead>
          <TBody>
            {members
              .slice(PageVisited, PageVisited + ListPerPage)
              .map((member, index) => (
                <TR
                  key={index}
                  onClick={() => {
                    if (activeIndex === '') {
                      toggleShowDetails();
                      setActiveIndex(index);
                    } else {
                      if (index === activeIndex) {
                        setActiveIndex('');
                        toggleShowDetails();
                      } else {
                        setActiveIndex(index);
                      }
                    }
                  }}
                  className={activeIndex === index && 'active'}
                >
                  <TD>
                    <input
                      style={{
                        marginRight: '15px',
                      }}
                      type="checkbox"
                      name={member.userId}
                    />
                    {member.fullName}
                  </TD>
                  <TD>{member.address}</TD>
                  <TD>
                    {new Date(member.createdAt).toLocaleDateString('en-NG')}
                  </TD>
                  <TD>
                    <Badge status={member.status === 'Pending' ? false : true}>
                      {member.status}
                    </Badge>
                  </TD>
                </TR>
              ))}
          </TBody>
        </Table>
        {showDetails && (
          <MemberDetail
            details={members[activeIndex]}
            closeDetails={closeDetails}
          />
        )}
      </ContentArea>
      <Pagination
        fetcheddata={members}
        itemsPerPage={10}
        setPageNumber={setPageNumber}
      />
    </div>
  );
}

export {
  Table,
  Top,
  TR,
  TH,
  TBody,
  TD,
  THead,
  Title,
  ShowMore,
  Sort,
  ContentArea,
};
