import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';

const ButtonComponent = styled.button`
  width: 100%;
  height: 48px;
  background: ${({ disabled }) => (disabled ? '#EBEBEB' : '#14a800')};
  border: #14a800;
  border-radius: 4px;
  color: ${({ disabled }) => (disabled ? '#21334F' : '#ffffff')};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-top: 40px;
  @media (max-width: 1200px) {
    height: 3rem;
  @media (max-width: 768px) {
    height: 2.8rem;
    margin-top: 38px;
  }
  @media (max-width: 426px) {
    height: 2.5rem;
    margin-top: 35px;
  }
`;

export default function Button({
  type,
  children,
  onClick,
  style,
  className,
  loading,
  disabled,
}) {
  return (
    <ButtonComponent
      type={type}
      onClick={onClick}
      style={style}
      className={className}
      disabled={disabled}
    >
      {children}
      <div className="spin-parent">
        {loading && (
          <ClipLoader color="white" size="40px" className="spinner" />
        )}
      </div>
    </ButtonComponent>
  );
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
};
