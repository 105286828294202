import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputField = styled.select`
  height: 50px;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  font-weight: bold;
  padding: 10px;
`;

const Label = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 10px;
  color: #21334f;
`;

const FormGroup = styled.div`
  position: relative;
`;

const SelectOption = styled.option``;
const HelperText = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #14a800;
  margin-bottom: 0;
  margin-top: 10px;
`;

const ErrorText = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #de1e1e;
  margin-bottom: 0;
  margin-top: 10px;
`;

export default function SelectInput({
  label,
  name,
  value,
  onChange,
  options,
  errorMessage,
  helperText,
  placeholder,
  style,
}) {
  return (
    <div>
      <InputArea>
        <Label>{label}</Label>
        <FormGroup>
          <InputField
            className="form-select"
            name={name}
            value={value}
            onChange={onChange}
            style={style}
            defaultValue={""}
          >
            <SelectOption value="" disabled>
              {placeholder}
            </SelectOption>
            {options.map((option, i) => (
              <SelectOption key={i} value={option.value}>
                {option.name}
              </SelectOption>
            ))}
          </InputField>
        </FormGroup>
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        <HelperText>{helperText}</HelperText>
      </InputArea>
    </div>
  );
}

SelectInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  helperText: PropTypes.string,
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

SelectInput.defaultProps = {
  placeholder: 'Select',
};
