import React, { useEffect, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
//import Slide from 'react-reveal/Slide';
import Logo from '../../assets/Logo.svg';
import Button from '../../components/common/Button';
import Ellipse15 from '../../assets/Ellipse15.svg';
import footerLogo from '../../assets/FooterLogo.svg';
// import guarantor from '../../assets/guarantor.png';
import guarantor from '../../assets/guarantor.svg';
import AboutBackground from '../../assets/AboutBackground.svg';
import AboutSupportShell from '../../assets/AboutSupportShell.svg';
import Testimony1 from '../../assets/Testimony1.svg';
import Testimony2 from '../../assets/Testimony2.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HomePagePersonal.css';
import Indeedlogo from '../../assets/Indeedlogo.svg';
import sterlingimglogo from '../../assets/sterlingimglogo.svg';
import PagalogoOnly from '../../assets/PagalogoOnly.svg';
import terragon from '../../assets/terragon.svg';
import clientlogomerck1 from '../../assets/clientlogomerck1.svg';
import interswitch_logo from '../../assets/interswitch_logo.png';
import { IoClose } from 'react-icons/io5';
import { GiHamburgerMenu } from 'react-icons/gi';

import {
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineLinkedin,
} from 'react-icons/ai';
//import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { MenuBtn, MenuBtnLink } from '../../components/navs/NavbarElement';

const Container = styled.div`
  width: 1280px;
  margin: 0 auto;
  height: auto;

  @media (max-width: 1280px) {
    width: 95%;
    padding: 0 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 0 16px;
  }
`;

const HomePageNavbar = styled.div`
  position: fixed;
  width: 100%;
  height: 6rem; 
  border-bottom: 0.2rem solid #f1f1f1;
  background: #ffffff;
  z-index: 999;
  overflow: hidden;
  top: 0;
  display: flex;
  align-items: center;

  .logo{
    z-index: 99;
  }
  
  @media (max-width:900px){
    padding: 0.5rem 0.5rem; 
   
  }
`;

const HomePageNavbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const LogoImg = styled.img`
  width: 100%;
`;

const LogoNew = styled.img`
  @media (max-width: 420px) {
    width:150px;
  }
`;

const LogoImg2 = styled.img`
  @media (max-width: 420px) {
    width: 100%;
    margin-left: 25px;
  }
`;

const HeroSection = styled.div`
  width: 100%;
  display: flex;
  background: rgba(246, 246, 246, 0.49);
`;

const HeroWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6rem;
  padding-top: 80px;

  .row1{
    width: 48%;
  }

  .row2{
    width: 48%;
  }

  img{
    width: 100%;
  }

  @media (max-width: 900px) {
    padding-top: 40px;
    flex-wrap: wrap;
    .row1{
      width: 100%;
    }
  
    .row2{
      width: 100%;
      margin-top: 26px;
    }
  }
`;

const AboutUsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 67px;
  @media (max-width: 900px) {
    flex-wrap: wrap;
    padding-bottom: 32px;
    flex-direction: column-reverse;
  }
`;

const AboutUsImgWrapper = styled.div`
  width: 48%;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
const AboutUsContent = styled.div`
  width: 48%;
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 548px;
  @media (max-width: 900px) {
    height: auto;
    flex-direction: column-reverse;
  }
`;

const GuarantorImgWrapper = styled.div`
  width: 50%;
  background: #14a800;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    width: 100%;
    padding: 32px;
  }
`;

const InfoContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 900px) {
    width: 100%;
    padding: 32px;
  }
`;

const Header6 = styled.h6`
  width: 273px;
  height: 23px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: black;

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;
const HthreeDiv = styled.div`
  width: 458px;
  margin-top: 15px;
  @media (max-width: 420px) {
    width: 343px;
    font-size: 24px;
  }
`;
const HeadingThree = styled.h1`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 55px;
  @media (max-width: 420px) {
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
    color: #21334f;
  }
`;

const ParagraphDiv = styled.div`
  margin-top: 17px;
`;

const ParagraphP = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  width: 567px;
  font-size: 20px;
  line-height: 30px;
  color: #21334f;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 420px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const PartnerSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1280px;
  margin: auto;
  margin-top: 70px;

  @media (max-width: 1280px) {
    width: 95%;
    padding: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
    justify-content: space-between;
  }
`;
const PartnerTile = styled.div`
  display: flex;
  flex: 1;
`;
const Title = styled.h6`
  width: 202px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #031839;
  @media (max-width: 420px) {
    font-size: 14px;
    width: 158px;
  }
`;
const TileLine = styled.hr`
  height: 0px;
  border: 1px solid #dadada;
  flex: 1;
  margin-right: 70px;
  @media (max-width: 420px) {
    margin-right: 0px;
    width: 181px;
  }
`;

const CarouseWrapper = styled.div`
  position: relative;
  width: 1280px;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 70px;
  display: grid;
  perspective: 100px;
  overflow: hidden;
`;
const CarouselSlide = styled.div`
display:flex;
width:250px;
align-items:center;
padding-top:20px;
padding-bottom:20px
overflow:hidden;
cursor:pointer;
img{
  transition: transform 1s;
  @media (max-width: 420px) {
    width: 150px;
  }
}
img:hover {
  transform: scale(1.5);
}
`;

const slidescroll = keyframes`
 0% { transform:translateX(0) }
 100% { transform:translateX(calc(-250px * 6)) }
`;

const CarouselTrack = styled.div`
  display: flex;
  width: calc(250 * 6);
  animation-name: ${slidescroll};
  animation-duration: 20s;
  animation-iteration-count: infinite;

  &:hover {
    animation-play-state: paused;
  }
`;

const ContentSectionH3 = styled.h3`
  font-style: normal;
  font-weight: bold;
  color: #21334f;
  font-size: 44px;
  line-height: 58px;
  justify-content: center;
  align-items: center;

  @media (max-width: 1280px) {
    font-size: 2.6rem;
  }

  @media (max-width: 420px) {
    font-size: 24px;
    line-height: 30px;
    margin-top: 0px;
  }
`;

const ContentSectionP = styled.p`
  margin-top: 50px;
  color: #21334f;
  font-size: 16px;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 30px;
  display: flex;
  overflow: hidden;

  @media (max-width: 900px) {
    width: 100%;
    margin: 0px;
    margin-bottom: 16px;
  }

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

const ButtonNew = styled.button`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 40px;
  width: 240px;
  font-size: 18px;
  border: none;
  height: 48px;
  left: 122px;
  color: #ffffff;
  background: #14a800;
  border-radius: 4px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #ffffff;
    color: green;
    border: 1px solid #14a800;
  }

  @media (max-width: 900px) {
    margin-left: 0px;
  }

  @media (max-width: 420px) {
    width: 100%;
  }
`;

const TestimonialSection = styled.div`
  width: 100%;
  background: rgba(246, 246, 246, 0.49);
  @media (max-width: 900px) {
    display: none;
  }
`;

const FooterDiv = styled.div`
  background: #2d2f48;
  width: 100%;
  height: 270px;
  overflow:hidden;

  @media (max-width:900px){
    width:100%
    justify-content: center;
    align-items:center;
  }

`;
const FooterLogoDiv = styled.div`
  width: 100%;

  @media (max-width: 420px) {
    width: 100%;
  }
  .footerLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
    margin-top: 5rem;

    @media (max-width: 420px) {
      width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const FooterHr = styled.hr`
  background: #ffffff;
  opacity: 0.2;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-top: 70px;

  @media (max-width: 900px) {
    width: 80%;
  }

  @media (max-width: 420px) {
    width: 90%;
    margin-top: 70px;
  }
`;
const FooterFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ParagraphCopy = styled.p`
  margin-left: 70px;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  @media (max-width: 420px) {
    display: none;
  }
`;

const SocialIcon1 = styled.div`
  background: #ffffff;
  border-radius: 50%;
  opacity: 0.1;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
`;

const SocialIcon2 = styled.div`
  background: #ffffff;
  border-radius: 50%;
  opacity: 0.1;
  width: 30px;
  margin-left: 10px;
  height: 30px;
  justify-content: center;
  align-items: center;
`;

const NavButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  width: 14rem;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 1rem 1.7rem;
  border-radius: 4px;
  color: #ffffff;
  background: #14a800;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #ffffff;
    color: #808080;
  }
  }

  @media (max-width: 420px) {
    width: 90%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  @media (max-width: 900px) {
    margin-bottom: 20px;
  }
`;

const Paragraph = styled.p`
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: Mulish;
  font-style: normal;
  width: 80%;
  font-weight: normal;
  font-size: 18px;
  color: #21334f;
  overflow: hidden;

  @media (max-width: 420px) {
    margin-left: 2rem;
    font-size: 12px;
  }
`;
const Heading = styled.h1`
  font-family: Mulish;
  font-style: normal;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  overflow: hidden;

  @media (max-width: 420px) {
    font-size: 16px;
    margin-left: 2rem;
  }
`;

const NewParagraph = styled.p`
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: Mulish;
  font-style: normal;
  width: 80%;
  font-weight: normal;
  font-size: 18px;
  color: #21334f;
  overflow: hidden;

  @media (max-width: 420px) {
    font-size: 12px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;
const NewHeading = styled.h1`
  font-family: Mulish;
  font-style: normal;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  overflow: hidden;

  @media (max-width: 420px) {
    font-size: 24px;
    padding: 1rem 0;
  }
`;

const Span = styled.a`
  color: #ffffff;
  margin-left: 0.8rem;
  font-size: 1.2rem;
  border: none;
`;

const CustomButton = styled.div`
  .buttonstyle {
    width: 260px;
    height: 54px;
    border-radius: 4px;
    margin-top: 35px;
    font-size: 22px;

    @media (max-width: 900px) {
      width: 100%;
    }
  }
`;

const IconLogo = styled.div`
  display: flex;
  margin-right: 70px;
  @media (max-width: 420px) {
    display: none;
  }
`;

const CopyRight = styled.div`
  @media (max-width: 420px) {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

const IconsDiv = styled.div`
  @media (max-width: 420px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TesHeader = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #031839;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow: hidden;
  padding-top: 7rem;

  @media (max-width: 420px) {
    font-size: 24px;
  }
`;
const TesParagraph = styled.p`
  margin-left: auto;
  margin-right: auto;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 420;
  font-size: 24px;
  line-height: 48px;
  text-align: center;
  color: #031839;

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

const DivContainer = styled.div`
  padding-bottom: 0px;
  height: inherit;
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

const WrapperDiv = styled.div`
  width: 40%;
  padding: 30px;

  @media (max-width: 900px) {
    width: 100%;
    padding-left: 50px;
  }
  @media (max-width: 420px) {
    padding-left: 15px;
  }
`;

const PreviousBtn = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ ...style, display: 'block', background: 'black' }}
    />
  );
};

const NextBtn = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ ...style, display: 'block', background: 'black' }}
    ></div>
  );
};

const HomePagePersonal = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <NextBtn />,
    prevArrow: <PreviousBtn />,
  };
  const newsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextBtn />,
    prevArrow: <PreviousBtn />,
  };
  const myRef = useRef(null);
  useEffect(() => myRef.current.scrollIntoView(), []);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Responsiveness
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', changeWidth);

    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, []);

  const [active, setActive] = useState(false);

  const showMenu = () => {
    setActive(!active);
  };

  return (
    <div ref={myRef}>
      <HomePageNavbar>
        <Container>
          <HomePageNavbarWrapper>
          <Link className="logo" to="/">
            <LogoNew src={Logo} alt="Support-Shell Logo" />{' '}
          </Link>
          <div className="menu-icon" onClick={showMenu}>
            {active ? <IoClose /> : <GiHamburgerMenu />}
          </div>
          <div className={active ? 'slider active' : 'slider'}>
            <ul>
              <li>
                <Link
                  to="/"
                  style={{
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                    color: '#14a800',
                  }}
                >
                  Personal
                </Link>
              </li>
              <li>
                <Link
                  to="/business"
                  style={{ fontSize: '1.1rem', fontWeight: 'bold' }}
                >
                  Business
                </Link>
              </li>
              <li>
                <Link
                  to="/login"
                  style={{ fontSize: '1.1rem', fontWeight: 'bold' }}
                >
                  Login
                </Link>
              </li>
              <li>
                <MenuBtn>
                  <MenuBtnLink to="/join">
                    <span className="signstyle">Sign Up</span>
                  </MenuBtnLink>
                </MenuBtn>
              </li>
            </ul>
          </div>
          </HomePageNavbarWrapper>
        </Container>
      </HomePageNavbar>

      <HeroSection ref={myRef}>
        <Container>
          <HeroWrapper>
            <div className="row1">
              {/* <Slide right> */}
              <Header6>
                {' '}
                No Need to Pause Your Dreams
              </Header6>
              <HthreeDiv>
                <HeadingThree>Access Funding Now, Pay Later.</HeadingThree>
              </HthreeDiv>
              <ParagraphDiv>
                <ParagraphP>
                  Join the{' '}
                  <span style={{ color: 'green' }}>Decagon Support Shell</span> to
                  Get Access to Loans to fund your ambitious goals with the group
                  of similar individual and only start paying at a time when you
                  want
                </ParagraphP>
              </ParagraphDiv>
              <CustomButton>
                <Link to="/join">
                  <Button className="buttonstyle" to="/join">
                    Join Now
                  </Button>
                </Link>
              </CustomButton>
              {/* </Slide> */}
            </div>

            <div className="row2">
              {/* <Slide left> */}
              <img src={Ellipse15} alt="img" className="ellipses"></img>
              {/* </Slide> */}
            </div>
          </HeroWrapper>
        </Container>
      </HeroSection>

      <PartnerSection>
        <PartnerTile>
          <Title>Companies that trust us</Title>
        </PartnerTile>
        <TileLine />
      </PartnerSection>

      <CarouseWrapper>
        <CarouselTrack>
          <CarouselSlide>
            <img src={Indeedlogo} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={sterlingimglogo} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={PagalogoOnly} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={terragon} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={clientlogomerck1} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
          <img src={interswitch_logo} alt="img"></img>
          </CarouselSlide>

          <CarouselSlide>
            <img src={Indeedlogo} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={sterlingimglogo} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={PagalogoOnly} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={terragon} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={clientlogomerck1} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
          <img src={interswitch_logo} alt="img"></img>
          </CarouselSlide>
        </CarouselTrack>
      </CarouseWrapper>

      <Container>
        <AboutUsWrapper>
          <AboutUsImgWrapper>
            <LogoImg src={AboutSupportShell} alt="AboutSupportShell" />
          </AboutUsImgWrapper>

          <AboutUsContent>
            <ContentSectionH3>
              What is the Decagon Support shell?
            </ContentSectionH3>
            <ContentSectionP>
              The Decagon Support Shell is a support system powered by Decagon
              that Matches individuals who do not qualify for a bank loan mainly
              because they lack guarantors to a guarantor within a verified
              Trust Group to fund their ambitious goals
            </ContentSectionP>
            <Link to="/join">
              <ButtonNew to="/join">Join Now</ButtonNew>
            </Link>
          </AboutUsContent>
        </AboutUsWrapper>
      </Container>

      <InfoWrapper>
        <GuarantorImgWrapper>
          <LogoImg
            src={guarantor}
            alt="AboutSupportShell"
            style={{ width: '100%' }}
          />
        </GuarantorImgWrapper>

        <InfoContent
          style={{
            backgroundImage: `url(${AboutBackground})`,
          }}
          className="guarantordiv"
        >
          <NewHeading className="headerstyle">
            No Guarantor? <br></br> No Problem
          </NewHeading>
          <NewParagraph
            style={{
              color: '#ffffff',
              fontSize: '16px',
              width: '90%',
            }}
          >
            Individuals who do not meet the guarantor requirements to access
            loans to fund their goals are matched with guarantors within a Trust
            group who stand as guarantors for one another; enabling faster
            access to loans to pursue their goals. Whether it’s a career or
            business goal, we’ve got you covered!
          </NewParagraph>
          <NavButton
            style={{
              background: '#ffffff',
              color: '#14a800',
            }}
            to="/join"
          >
            <span className="joinspan">Join now</span>
          </NavButton>
        </InfoContent>
      </InfoWrapper>

      {/* Alternative Testimonial slider */}
      {(false || screenWidth < 900) && (
        <div style={{ width: '100%', background: 'rgba(246, 246, 246, 0.49)' }}>
          <TesHeader>Testimonials</TesHeader>
          <TesParagraph>
            Hear What Beneficiaries of Support Shell are Saying{' '}
          </TesParagraph>
          <DivContainer>
            <Slider
              {...newsettings}
              style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
            >
              <WrapperDiv
                style={{
                  background: 'rgba(246, 246, 246, 0.49)',
                }}
              >
                <LogoImg2 src={Testimony1} alt="Testimony1" />
                <Heading
                  style={{
                    padding: '0.2rem 0px',
                    fontSize: '16px',
                    color: '#031839',
                  }}
                >
                  Ikechukwu Stonecold
                </Heading>
                <Paragraph
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    padding: '0px',
                  }}
                >
                  CEO Innovativ8 Technology
                </Paragraph>
                <Paragraph
                  style={{
                    padding: '0.1rem 0px',
                    fontSize: '14px',
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  viverra dis tempor, lacus orci at. Massa velit mi pharetra
                  faucibus bibendum. Leo sit diam aliquam arcu, gravida
                  habitasse.
                </Paragraph>
              </WrapperDiv>
              <WrapperDiv
                style={{
                  background: 'rgba(246, 246, 246, 0.49)',
                }}
              >
                <LogoImg2 src={Testimony2} alt="Testimony2" />
                <Heading
                  style={{
                    padding: '0.2rem 0px',
                    fontSize: '16px',
                    color: '#031839',
                  }}
                >
                  Mark Collins
                </Heading>
                <Paragraph
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    padding: '0px',
                  }}
                >
                  CEO Arab Money
                </Paragraph>
                <Paragraph
                  style={{
                    padding: '0.1rem 0px',
                    fontSize: '14px',
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  viverra dis tempor, lacus orci at. Massa velit mi pharetra
                  faucibus bibendum. Leo sit diam aliquam arcu, gravida
                  habitasse.
                </Paragraph>
              </WrapperDiv>

              <WrapperDiv
                style={{
                  background: 'rgba(246, 246, 246, 0.49)',
                }}
              >
                <LogoImg2 src={Testimony1} alt="Testimony1" />
                <Heading
                  style={{
                    padding: '0.2rem 0px',
                    fontSize: '16px',
                    color: '#031839',
                  }}
                >
                  Ikechukwu Stonecold
                </Heading>
                <Paragraph
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    padding: '0px',
                  }}
                >
                  CEO Innovativ8 Technology
                </Paragraph>
                <Paragraph
                  style={{
                    padding: '0.1rem 0px',
                    fontSize: '14px',
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  viverra dis tempor, lacus orci at. Massa velit mi pharetra
                  faucibus bibendum. Leo sit diam aliquam arcu, gravida
                  habitasse.
                </Paragraph>
              </WrapperDiv>
              <WrapperDiv
                style={{
                  background: 'rgba(246, 246, 246, 0.49)',
                }}
              >
                <LogoImg2 src={Testimony2} alt="Testimony2" />
                <Heading
                  style={{
                    padding: '0.2rem 0px',
                    fontSize: '16px',
                    color: '#031839',
                  }}
                >
                  Mark Collins
                </Heading>
                <Paragraph
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    padding: '0px',
                  }}
                >
                  CEO Arab Money
                </Paragraph>
                <Paragraph
                  style={{
                    padding: '0.1rem 0px',
                    fontSize: '14px',
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  viverra dis tempor, lacus orci at. Massa velit mi pharetra
                  faucibus bibendum. Leo sit diam aliquam arcu, gravida
                  habitasse.
                </Paragraph>
              </WrapperDiv>
            </Slider>
          </DivContainer>
        </div>
      )}

      {/* End of testimonial slider */}

      <TestimonialSection>
        <TesHeader>Testimonials</TesHeader>
        <TesParagraph>
          Hear What Beneficiaries of Support Shell are Saying{' '}
        </TesParagraph>
        <DivContainer>
          <Slider
            {...settings}
            style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
          >
            <WrapperDiv
              style={{
                background: 'rgba(246, 246, 246, 0.49)',
              }}
            >
              <LogoImg src={Testimony1} alt="Testimony1" />
              <Heading
                style={{
                  padding: '0.2rem 0px',
                  margin: '0px',
                  fontSize: '30px',
                  color: '#031839',
                }}
              >
                Ikechukwu Stonecold
              </Heading>
              <Paragraph
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '0px',
                }}
              >
                CEO Innovativ8 Technology
              </Paragraph>
              <Paragraph
                style={{
                  padding: '0.1rem 0px',
                  fontSize: '14px',
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                viverra dis tempor, lacus orci at. Massa velit mi pharetra
                faucibus bibendum. Leo sit diam aliquam arcu, gravida habitasse.
              </Paragraph>
            </WrapperDiv>
            <WrapperDiv
              style={{
                background: 'rgba(246, 246, 246, 0.49)',
              }}
            >
              <LogoImg src={Testimony2} alt="Testimony2" />
              <Heading
                style={{
                  padding: '0.2rem 0px',
                  margin: '0px',
                  fontSize: '30px',
                  color: '#031839',
                }}
              >
                Mark Collins
              </Heading>
              <Paragraph
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '0px',
                }}
              >
                CEO Arab Money
              </Paragraph>
              <Paragraph
                style={{
                  padding: '0.1rem 0px',
                  fontSize: '14px',
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                viverra dis tempor, lacus orci at. Massa velit mi pharetra
                faucibus bibendum. Leo sit diam aliquam arcu, gravida habitasse.
              </Paragraph>
            </WrapperDiv>

            <WrapperDiv
              style={{
                background: 'rgba(246, 246, 246, 0.49)',
              }}
            >
              <LogoImg src={Testimony1} alt="Testimony1" />
              <Heading
                style={{
                  padding: '0.2rem 0px',
                  margin: '0px',
                  fontSize: '30px',
                  color: '#031839',
                }}
              >
                Ikechukwu Stonecold
              </Heading>
              <Paragraph
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '0px',
                }}
              >
                CEO Innovativ8 Technology
              </Paragraph>
              <Paragraph
                style={{
                  padding: '0.1rem 0px',
                  fontSize: '14px',
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                viverra dis tempor, lacus orci at. Massa velit mi pharetra
                faucibus bibendum. Leo sit diam aliquam arcu, gravida habitasse.
              </Paragraph>
            </WrapperDiv>
            <WrapperDiv
              style={{
                background: 'rgba(246, 246, 246, 0.49)',
              }}
            >
              <LogoImg src={Testimony2} alt="Testimony2" />
              <Heading
                style={{
                  padding: '0.2rem 0px',
                  margin: '0px',
                  fontSize: '30px',
                  color: '#031839',
                }}
              >
                Mark Collins
              </Heading>
              <Paragraph
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '0px',
                }}
              >
                CEO Arab Money
              </Paragraph>
              <Paragraph
                style={{
                  padding: '0.1rem 0px',
                  fontSize: '14px',
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                viverra dis tempor, lacus orci at. Massa velit mi pharetra
                faucibus bibendum. Leo sit diam aliquam arcu, gravida habitasse.
              </Paragraph>
            </WrapperDiv>
          </Slider>
        </DivContainer>
      </TestimonialSection>

      <FooterDiv style={{ width: '100%' }}>
        {' '}
        <FooterLogoDiv>
          <img src={footerLogo} alt="Support Shell" className="footerLogo" />
        </FooterLogoDiv>
        <FooterHr />
        {(false || screenWidth < 420) && (
          <>
            <IconsDiv>
              <Span href="https://www.instagram.com/decagon_institute/">
                <AiOutlineInstagram />
              </Span>
              <Span href="https://twitter.com/DecagonIns">
                <AiOutlineTwitter />
              </Span>
              <Span href="https://www.linkedin.com/school/decagon/">
                <AiOutlineLinkedin />
              </Span>
            </IconsDiv>
            <CopyRight>
              &copy; 2022 Support Shell. All rights reserved
            </CopyRight>
          </>
        )}
        <FooterFlex>
          <ParagraphCopy>
            &copy; 2022 Support Shell. All rights reserved
          </ParagraphCopy>
          <IconLogo>
            <Span href="https://www.instagram.com/decagon_institute/">
              <AiOutlineInstagram />
            </Span>
            <Span href="https://twitter.com/DecagonIns">
              <AiOutlineTwitter />
            </Span>
            <Span href="https://www.linkedin.com/school/decagon/">
              <AiOutlineLinkedin />
            </Span>
          </IconLogo>
        </FooterFlex>
      </FooterDiv>
    </div>
  );
};
export default HomePagePersonal;
export {
  SocialIcon1,
  SocialIcon2,
  FooterDiv,
  FooterHr,
  FooterFlex,
  ParagraphCopy,
  PartnerSection,
  PartnerTile,
  TileLine,
};
