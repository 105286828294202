export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAIL = 'FORGOT_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOADING = 'LOADING';
export const ADDADMIN_SUCCESS = 'ADDADMIN_SUCCESS';
export const ADDADMIN_ERROR = 'ADDADMIN_ERROR';
export const ADDADMIN_FAIL = 'ADDADMIN_FAIL';
