import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import profilebackground from '../assets/profilebackground.svg';
import addavatar from '../assets/addavatar.png';
import Input from '../components/common/Input';
import Button from '../components/common/Button';
import { useAuth } from '../context/auth/AuthState';
import FileInput from '../components/common/FileInput';
import { ErrorToast } from '../utils/toast';
import UserUpdated from '../components/modal/UserUpdated';
import Modal from '../components/common/Modal';
import {
  Center,
  AuthFormArea,
  MarginStyle,
} from '../utils/common';
import { apiPatch, apiGet, uploadFile } from '../utils/apiHelper';
//import { toast } from 'react-toastify';

const ProfileDiv = styled.div`
  width: 100%;
  left: 318px;
  top: 110px;
  background: #ffffff;
  border-radius: 4px;
`;

const ProfileCoverImg = styled.img`
  width: 100%;
  height: 165px;
  left: 318px;
  top: 110px;
  object-fit: cover;
  border: none;
  background: url(${profilebackground}), rgba(0, 0, 0, 0.05);
  cursor: pointer;
  @media screen and (max-width: 786px) {
    width: 0px;
    height: 0px;
  }
`;

const ProfileUserImg = styled.img`
  top: 45px;
  width: 210px;
  height: 210px;
  border-radius: 50%;
  cursor: pointer;
  background: url(${addavatar}), #c4c4c4;
  //background: #c4c4c4;
  border: 2px solid green;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  margin: 80px;
  margin-left: 110px;
  margin-bottom: 30px;
  @media screen and (max-width: 786px) {
    width: 13rem;
    height: 13rem;
    margin: 0px;
    margin-left: 0px;
    margin-bottom: 0px;
    position: relative;
  }
  @media screen and (max-width: 426px) {
    width: 12rem;
    height: 12rem;
  }
`;

const ProfileInfo = styled.div`
  margin-left: -25px;
  margin-top: -200px;
  position: absolute;
  @media screen and (max-width: 786px) {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
  }
`;
const Headerdiv = styled.div`
  margin-left: 350px;
  margin-top: 210px;
  @media screen and (max-width: 786px) {
    margin-top: 0px;
    margin-left: 0px;
  }
  @media screen and (max-width: 426px) {
    margin-top: 0px;
    margin-left: 0px;
  }
`;

const Heading4div = styled.div`
  width: 202px;
  height: 40px;
  left: 604px;
  top: 287px;
  @media screen and (max-width: 786px) {
    width: 0px;
    height: 0px;
    left: 0px;
    top: 0px;
  }
`;

const Heading6div = styled.div`
  width: 106px;
  height: 18px;
  left: 604px;
  top: 333px;
  @media screen and (max-width: 786px) {
    width: 0px;
    height: 0px;
    left: 0px;
    top: 0px;
  }
`;

const Heading4 = styled.h5`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  @media screen and (max-width: 786px) {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 0px;
    line-height: 0px;
  }
`;

const Heading6 = styled.h6`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  @media screen and (max-width: 786px) {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 0px;
    line-height: 0px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;


const InputWidth = styled.div`
  width: 100%;
  @media screen and (max-width: 786px) {
    width: 100%;
   
  }
`;

const UserProfile = () => {
  const [userData, setUserData] = useState({
    fullName: '',
    dateOfBirth: '',
    address: '',
    email: '',
    bvn: '',
    phone: '',
    nextOfKin: '',
    nin: '',
    Uploadiddocument: '',
    Uploadletterofacceptance: '',
    termsAndConditionUrl: '',
    loanRestructureUrl: '',
    avatarUrl: '',
    avatar: '',
    title: '',
  });

  const [errors, setErrors] = useState({
    fullName: '',
    dateOfBirth: '',
    address: '',
    email: '',
    bvn: '',
    phone: '',
    nextOfKin: '',
    nin: '',
    Uploadiddocument: '',
    Uploadletterofacceptance: '',
    termsAndConditionUrl: '',
    loanRestructureUrl: '',
  });

  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');

  const toggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    apiGet(`/user/member/${user.id}`)
      .then((res) => {
        const userData = res.data.data;
        const prefillData = {
          email: userData.email,
          fullName: userData.fullName,
          phone: userData.phoneNumber,
          address: userData.address,
          dateOfBirth: userData.dateOfBirth,
          bvn: userData.bvn,
          nextOfKin: userData.nextOfKin,
          nin: userData.nin,
          avatarUrl: userData.avatarUrl,
          title: userData.title,
          loanRestructureUrl: userData.loanRestructureUrl,
        };
        setUserData(prefillData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.id]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateaddress = () => {
    if (userData.address.length <= 4) {
      setErrors((prevState) => ({
        ...prevState,
        address: 'Enter a valid address',
      }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        address: '',
      }));
      return true;
    }
  };

  const validateNextOfKin = () => {
    if (
      !new RegExp(
        /^([A-Za-z]{3,16})([ ]{1})([A-Za-z]{3,16})?([ ]{1})?([A-Za-z]{3,16})$/
      ).test(userData.nextOfKin)
    ) {
      setErrors((prevState) => ({
        ...prevState,
        nextOfKin: 'Enter your Next of kin',
      }));

      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        nextOfKin: '',
      }));
      return true;
    }
  };

  const validateNin = () => {
    if (userData.nin.length !== 11) {
      setErrors((prevState) => ({
        ...prevState,
        nin: 'Enter a valid is not Bvn number',
      }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        nin: '',
      }));
      return true;
    }
  };

  const validatePhone = () => {
    if (userData.phone.length < 8) {
      setErrors((prevState) => ({
        ...prevState,
        phone: 'Enter a valid phone number',
      }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        phone: '',
      }));
      return true;
    }
  };

  // const validateloanRestructureUrl = () => {
  //   if (userData.loanRestructureUrl.length != 99) {
  //     setErrors((prevState) => ({
  //       ...prevState,
  //       bvn: 'Enter a valid loan Restructure Url',
  //     }));
  //     return false;
  //   } else {
  //     setErrors((prevState) => ({
  //       ...prevState,
  //       bvn: '',
  //     }));
  //     return true;
  //   }
  // };

  // const validateFIleUrl = (file) => {
  //   if (!userData[file]) {
  //     setErrors((prevState) => ({ ...prevState, [file]: 'Select a file' }));
  //     return false;
  //   } else {
  //     setErrors((prevState) => ({ ...prevState, [file]: '' }));
  //     return true;
  //   }
  // };

  // const validateBvn = () => {
  //   if (userData.bvn.length !== 11) {
  //     setErrors((prevState) => ({
  //       ...prevState,
  //       bvn: 'Enter a valid Bvn number',
  //     }));
  //     return false;
  //   } else {
  //     setErrors((prevState) => ({
  //       ...prevState,
  //       bvn: '',
  //     }));
  //     return true;
  //   }
  // };

  const validateInput = () => {
    const valid = validateaddress() && validatePhone() && validateNextOfKin();
    // validateBvn() &&
    // validateNin();
    // validateFIleUrl('Uploadiddocument') &&
    // validateFIleUrl('Uploadletterofacceptance') &&
    // validateFIleUrl('termsAndConditionUrl') &&
    // validateloanRestructureUrl();
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateInput()) {
      await apiPatch('/user/update-profile', userData, {}, true).then(
        (res) => {
          setUserId(res.data.data);
          setShowSuccess(true);
          setLoading(false);
        },
        (err) => {
          if (!err.response) {
            ErrorToast('Something went wrong');
          } else {
            ErrorToast(err.response.data.message);
          }
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const updateProfileImage = async (e) => {
    e.preventDefault();
    const url = await uploadFile(userData.avatar);
    setUserData((prevState) => ({
      ...prevState,
      avatarUrl: url,
    }));
    toggleModal();
  };

  if (showSuccess) {
    return <UserUpdated userId={userId && userId} />;
  }
  return (
    <>
      <ProfileDiv>
        <ProfileCoverImg />
        <ProfileInfo>
          <ProfileUserImg onClick={() => toggleModal()} />

          <Headerdiv>
            <Heading4div>
              <Heading4>{user.fullname.split(' ')[0]}</Heading4>
            </Heading4div>

            <Heading6div>
              <Heading6>{userData.title}</Heading6>
            </Heading6div>
          </Headerdiv>
        </ProfileInfo>

        <AuthFormArea style={{ width: '100%', marginTop: '120px' }}>
          <form onSubmit={handleSubmit}>
            <Container>
              <InputWidth>
                <Input
                  autoComplete="off"
                  type="text"
                  label="Full Name"
                  name="fullName"
                  value={userData.fullName}
                  // onChange={changeHandler}
                  // onBlur={validateFullName}
                  // errorMessage={errors.fullName}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                  isTrueOrFalse={true}
                  disabled={true}
                />
              </InputWidth>
              <InputWidth>
                <Input
                  autoComplete="off"
                  type="date"
                  label="Date of Birth"
                  name="dateOfBirth"
                  value={userData.dateOfBirth}
                  placeholder="Date of Birth"
                  // onChange={changeHandler}
                  // onBlur={validateDateOfBirth}
                  // errorMessage={errors.dateOfBirth}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                  disabled={true}
                />
              </InputWidth>
            </Container>
            <Container>
              <InputWidth>
                <Input
                  autoComplete="off"
                  type="text"
                  label="Address"
                  name="address"
                  value={userData.address}
                  placeholder="Enter a Valid  Address"
                  onChange={changeHandler}
                  onBlur={validateaddress}
                  errorMessage={errors.address}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                />
              </InputWidth>
              <InputWidth>
                <Input
                  autoComplete="off"
                  type="email"
                  label="Email"
                  name="email"
                  value={userData.email}
                  // onChange={changeHandler}
                  // onBlur={validateEmail}
                  // errorMessage={errors.email}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                  disabled={true}
                />
              </InputWidth>
            </Container>

            <Container>
              <InputWidth>
                <Input
                  autoComplete="off"
                  type="text"
                  label="BVN"
                  name="bvn"
                  value={userData.bvn}
                  // onChange={changeHandler}
                  // onBlur={validateBvn}
                  // errorMessage={errors.bvn}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                  disabled={true}
                />
              </InputWidth>
              <InputWidth>
                <Input
                  type="tel"
                  label="Phone Number"
                  name="phone"
                  placeholder="Enter valid phone number"
                  value={userData.phone}
                  onChange={changeHandler}
                  onBlur={validatePhone}
                  errorMessage={errors.phone}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                />
              </InputWidth>
            </Container>

            <Container>
              <InputWidth>
                <Input
                  autoComplete="off"
                  type="text"
                  label="Next of Kin"
                  name="nextOfKin"
                  value={userData.nextOfKin}
                  placeholder="Next of kin"
                  onChange={changeHandler}
                  onBlur={validateNextOfKin}
                  errorMessage={errors.nextOfKin}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                />
              </InputWidth>
              <InputWidth>
                <Input
                  autoComplete="off"
                  type="text"
                  label="NIN"
                  name="nin"
                  value={userData.nin}
                  // onChange={changeHandler}
                  onBlur={validateNin}
                  errorMessage={errors.nin}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                  disabled={true}
                />
              </InputWidth>
            </Container>

            <Container>
              <InputWidth>
                <FileInput
                  label="Upload ID Document"
                  name="Uploadiddocument"
                  errorMessage={errors.Uploadiddocument}
                  value={userData.Uploadiddocument}
                  onChange={(e) => changeHandler(e)}
                  // onBlur={() => validateFIleUrl('Uploadiddocument')}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                  disabled={true}
                />
              </InputWidth>

              <InputWidth>
                <FileInput
                  label="Upload Letter Of Acceptance"
                  name="Uploadletterofacceptance"
                  errorMessage={errors.Uploadletterofacceptance}
                  value={userData.Uploadletterofacceptance}
                  onChange={(e) => changeHandler(e)}
                  // onBlur={() => validateFIleUrl('Uploadletterofacceptance')}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                  disabled={true}
                />
              </InputWidth>
            </Container>
            <Container>
              <InputWidth>
                <FileInput
                  label="Signed terms and Conditions document"
                  name="termsAndConditionUrl"
                  errorMessage={errors.termsAndConditionUrl}
                  value={userData.termsAndConditionUrl}
                  onChange={(e) => changeHandler(e)}
                  // onBlur={() => validateFIleUrl('termsAndConditionUrl')}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                  disabled={true}
                />
              </InputWidth>

              <InputWidth>
                <Input
                  autoComplete="off"
                  label="Loan Structure/Rental"
                  type="text"
                  name="loanRestructureUrl"
                  // errorMessage={errors.loanRestructureUrl}
                  value={userData.loanRestructureUrl}
                  // onChange={changeHandler}
                  // onBlur={validateLoanRestructureUrl}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                  disabled={true}
                />
              </InputWidth>
            </Container>
            <MarginStyle>
              <Button
                loading={loading}
                style={{ marginTop: '42px', width: '100%' }}
                type="submit"
              >
                {!loading && 'Save'}
              </Button>
            </MarginStyle>
          </form>
        </AuthFormArea>
        <Modal
          title="Upload Avatar"
          isOpen={showModal}
          closeModal={toggleModal}
        >
          <form onSubmit={updateProfileImage}>
            <InputWidth style={{ width: '80%' }}>
              <FileInput
                name="avatar"
                value={userData.avatar?.name}
                onChange={(e) =>
                  setUserData((prev) => ({
                    ...prev,
                    avatar: e.target.files[0],
                  }))
                }
                style={{
                  background: 'rgba(246, 246, 246, 0.49)',
                  width: '80%',
                }}
                disabled={false}
              />
            </InputWidth>

            <Button style={{ marginTop: '42px', width: '40%' }} type="submit">
              Upload
            </Button>
          </form>
        </Modal>
      </ProfileDiv>
    </>
  );
};

export default UserProfile;
