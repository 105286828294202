import React,  { useState }  from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from '../components/navs/Navbar';
import Sidebar from '../components/navs/Sidebar';
import Navlink from '../components/navs/Navlink';
import Signout from '../assets/Sign-out.svg';
import { BsChevronRight } from "react-icons/bs";
import { matchPath, NavLink, useLocation } from 'react-router-dom';


const MenuItemsArea = styled.div`
  padding-bottom: 10px;
  margin: 29px 0;
`;


const PageContainer = styled.div`
  height: 100vh;
  // display: flex;
  width:100%;
`;
const ContentArea = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 30px 30px;
  position: relative;
  background-color: #f5f5f5;
  z-index: 0;
  @media (max-width: 768px) {
    padding: 30px 16px;
    z-index: 9;
  }
`;

const RightContent = styled.div`
  margin-left: ${({ collapsed }) => (collapsed ? '117px' : '288px')};
  transition: margin-left 0.2s;
  height: auto;
  overflow-x: hidden;
  @media (max-width: 768px) {
    margin-left: 0px;
    height: auto;
  }
`;

const MobileTab = styled.div`
  position:fixed;
  width:100%;
  background-color :white;
  z-index:99;
  height : 100vh;
`;


const BottomNavs = styled.div`
  position: absolute;
  bottom: 29px;
`;

const BottomMenu = styled.div`
 margin-top : 30px;
 margin-left : 20px;
 font-weight : bold;
 @media (max-width: 768px) {
  margin-top: 10px;
}
`;

const Menu = styled.div `
margin-top :10px;
display :flex;
justify-content : space-between;
margin-right : 20px;
padding: 10px 10px;
border-radius :5px;
&:hover {
  background-color: rgba(20, 168, 0, 0.05);
}
`;

export default function Layout({
  pageType,
  sidebarMenuItems,
  bottomMenuItems,
  topbarMenuItems,
}) { 

  const [collapsed, setCollapsed] = useState(false);
  
  var user = JSON.parse(localStorage.getItem('user'));
  let menus = user?.superadmin  === true? sidebarMenuItems: sidebarMenuItems.filter(menu => menu.label !== 'Create Admin');

  const Link = styled(NavLink)`
    text-decoration: none;
    color : black;
  }`;

  const [show, setShow] = useState(false);
  const changeMenu = (value)=> {
   setShow(value);
  }
  console.log(pageType) 
  return (
    <PageContainer>
      <Sidebar menuItems={sidebarMenuItems} bottomMenuItems={bottomMenuItems} collapsed={collapsed} setCollapsed={setCollapsed} />
      <RightContent collapsed={collapsed}>
        <Navbar topbarMenuItems={topbarMenuItems} pageType={pageType} onMenuBarClick = {changeMenu} setShow={setShow} show={show} />
        {show ? 
        <MobileTab>
          <MenuItemsArea>
           {
            menus.map((menuItem, index) => (
              <Navlink
                key={index}
                label={menuItem.label}
                url={menuItem.multiple ? menuItem.url[0] : menuItem.url}
                path={menuItem.multiple ? menuItem.url[1] : menuItem.url}
                icon={menuItem.icon}
                activeIcon={menuItem.activeIcon}
                setShow={setShow}
              />
            ))}
            

            <hr/>

            {
              bottomMenuItems.map((menuItem, index) => (
              <Navlink
                key={index}
                label={menuItem.label}
                url={menuItem.multiple ? menuItem.url[0] : menuItem.url}
                path={menuItem.multiple ? menuItem.url[1] : menuItem.url}
                icon={menuItem.icon}
                activeIcon={menuItem.activeIcon}
                setShow={setShow}
              />
            ))}
            <BottomMenu>
              <Link to="/logout">
                <Menu>
                <div>Log Out</div> <div><BsChevronRight></BsChevronRight></div> 
                </Menu>
              </Link>
            </BottomMenu>
            <BottomMenu>
              <Menu>
                Privacy Policy
              </Menu>
            </BottomMenu>
          </MenuItemsArea>
        </MobileTab> : ""}
        <ContentArea>
          <Outlet />
        </ContentArea>
      </RightContent>
    </PageContainer>
  );
}

