import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { BiArrowBack } from 'react-icons/bi';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/Input';
import MultiSelectSort from '../../../components/common/MultiSelectInput';
import { toast } from 'react-toastify';
import { apiGet, apiPost } from '../../../utils/apiHelper';

const Wrapper = styled.div``;
const BackHeader = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #21334f;
  margin-left: 12px;
  margin-top: 3px;
`;
const BackHome = styled.div`
  display: flex;
  cursor: pointer;
   z-index:999;
   margin-left:-3px;
   border-bottom:1px solid rgba(246, 246, 246, 0.49);
`;

const TrustGroupText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #21334f;
  margin-top: 32px;
`;

const TrustGroupForm = styled.div`
  width: 100%;
  min-height: 600px;
  left: 0px;
  margin-top: 50px;
  padding: 50px 37px;
  background: #ffffff;
  @media (max-width: 720px) {
    margin-top: 30px;
    padding: 30px 16px;
  }
`;

const FormHeader = styled.div`
  width: 401px;
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const FormHeaderText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #21334f;
`;
const BoldText = styled.span`
  font-weight: bold;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;


const InputWidth = styled.div`
  width: 100%;
  @media screen and (max-width: 786px) {
    width: 100%;
   
  }
`;

const formDefaultState = {
  name: '',
  // topic: '',
  userEmails: [],
  loanAmount: 0,
  monthlyRepaymentAmount: 0,
  repaymentStartDate: '',
  monthlyDueDate: 0,
  selectedArray:[]
  
}

export default function CreateTrustGroup() {
  let navigate = useNavigate();
  const [formDetails, setFormDetails] = useState(formDefaultState);
  const [members, setMembers] = useState([]);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };

  function validateForm(){
    return +formDetails.loanAmount > 0 && +formDetails.monthlyDueDate > 0 && +formDetails.monthlyRepaymentAmount > 0 &&
    formDetails.repaymentStartDate.length > 0 && formDetails.name && formDetails.repaymentStartDate
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Do validation
    if(validateForm()) {
    formDetails.loanAmount = +formDetails.loanAmount;
    formDetails.monthlyDueDate = +formDetails.monthlyDueDate;
    formDetails.monthlyRepaymentAmount = +formDetails.monthlyRepaymentAmount;
    formDetails.monthlyDueDate = +formDetails.monthlyDueDate;
    formDetails.repaymentStartDate = new Date(
      formDetails.repaymentStartDate
      ).toISOString();
      const dataToSubmit = Object.fromEntries(Object.entries(formDetails).filter( keyValue => keyValue[0]!== 'selectedArray'))
    try {
      await apiPost(`/admin/create-trust-group`, dataToSubmit, {}, true).then(
        (res) => {
        //  toast.success(res.data.message, { position: 'top-right' });
          navigate('/admin/trust-group')
        },
        (err) => {
          toast.error(err.response.data.message, { position: 'top-right' });
        }
      );
      setFormDetails({...formDefaultState})

    

    } catch (error) {
      toast.error(error.response.data.message, { position: 'top-right' });
    }
  }else {
    toast.error('Please fill all input fields to create a trust group.')
  }
  };

  const getMembers = async () => {
    await apiGet('/admin/members/with-no-trust-group', {}, true).then((res) => {
      setMembers(res.data.data);
  
    });
  };

  useEffect(() => {
    getMembers();
  }, []);

  return (
    <Wrapper>
      <BackHome>
        <Link to="/admin/trust-group" style={{ color: 'black' }}>
          <BiArrowBack />
        </Link>
        <Link to="/admin/trust-group" style={{ textDecoration: 'none' }}>
          <BackHeader>Go Back</BackHeader>
        </Link>
      </BackHome>
      <TrustGroupText>Trust Groups</TrustGroupText>
      <TrustGroupForm>
        <FormHeader>
          <FormHeaderText>
            Collaboratively pay loan with team members.{' '}
            <BoldText> Create Trust Group.</BoldText>
          </FormHeaderText>
        </FormHeader>

        <div style={{ marginTop: '40px' }}>
          <form onSubmit={handleSubmit}>
            <Container>
            <InputWidth>
              <Input
                type="text"
                label="Title"
                name="name"
                value={formDetails.name}
                onChange={handleInputChange}
                style={{
                   background: 'rgba(246, 246, 246, 0.49)',
                   border: '1px solid #E5E5E5',
                   boxSizing: 'border-box',
                   borderRadius: '4px'
               }}
              />
               </InputWidth>
               <InputWidth>
                 <MultiSelectSort
              options={members?.map((member) => ({
                label: member.fullName,
                value: member.email,
              }))}
              defaultSelected={formDetails.selectedArray}
              label="Add Members"
              handleChange={(e) =>
                setFormDetails((prev) => ({
                  ...prev,
                  userEmails: e.map((v) => v.value),
                  selectedArray:e
                }))
              }
              value={formDetails.userEmails}
           
            />
              </InputWidth>
        
              <InputWidth>
              <Input
                type="date"
                label="Payment Start Date"
                name="repaymentStartDate"
                value={formDetails.repaymentStartDate}
                onChange={handleInputChange}
                style={{
                  background: 'rgba(246, 246, 246, 0.49)',
                  border: '1px solid #E5E5E5',
                  boxSizing: 'border-box',
                  borderRadius: '4px'
              }}
              />
              </InputWidth>
              <InputWidth>
              <Input
                type="number"
                label="Total Loan"
                name="loanAmount"
                value={formDetails.loanAmount}
                onChange={handleInputChange}
                style={{
                  background: 'rgba(246, 246, 246, 0.49)',
                  border: '1px solid #E5E5E5',
                  boxSizing: 'border-box',
                  borderRadius: '4px'
              }}
              />
               </InputWidth> 

                 <InputWidth>
              <Input
                type="number"
                label="Monthly Rentals"
                name="monthlyRepaymentAmount"
                value={formDetails.monthlyRepaymentAmount}
                onChange={handleInputChange}
                style={{
                  background: 'rgba(246, 246, 246, 0.49)',
                  border: '1px solid #E5E5E5',
                  boxSizing: 'border-box',
                  borderRadius: '4px'
              }}
              />
               </InputWidth> 
              <InputWidth>
              <Input
                type="number"
                label="Monthly Due Date"
                name="monthlyDueDate"
                value={formDetails.monthlyDueDate}
                onChange={handleInputChange}
                style={{
                  background: 'rgba(246, 246, 246, 0.49)',
                  border: '1px solid #E5E5E5',
                  boxSizing: 'border-box',
                  borderRadius: '4px'
              }}
              />
              </InputWidth>
            </Container>
         
            <Button
              // loading={loading}
              type="submit"
            >
              Create Group
            </Button>
          </form>
        </div>
      </TrustGroupForm>
    </Wrapper>
  );
}

