import React, { useState } from 'react';
import styled from 'styled-components';
import Input from '../common/Input';
import Notification from '../../assets/Notifications1.svg';
import Settings from '../../assets/Settings-2.svg';
import User from '../../assets/User.svg';
import { FaAngleDown, FaAngleRight, FaAngleUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/auth/AuthState';
import SupportShell from '../../assets/SupportShell.svg';
import footerLogo from '../../assets/footerLogo.png';
import Logo from '../../assets/Logo.svg';


const InputField = styled.div`
color :red;
@media (max-width: 768px) {
  Display : none;
}

`;
const NavbarArea = styled.div`
  width: 100%;
  height: 80px;
  padding: 0 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 100;
  background: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.04));
  overflow: visible;
  @media (max-width: 768px) {
    padding: 0 16px;     
  }
`;


const NavbarIconsArea = styled.div`
  width: 106px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
     Display : none;
  }
`;

const NavbarIcon = styled.div`
  width: 38px;
  height: 38px;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  @media (max-width: 768px) {
    Display : none;
 }
`;

const DropDown = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-block;
  float: right;
`;

const ProfileDropdown = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    Display : none;
 }
`;

const Avatar = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 100%;
`;

const Username = styled.div`
  font-size: 14px;
  font-weight: normal;
  margin-left: 5px;
`;

const DropdownCaret = styled.div`
  margin-left: 5px;
`;

const DropdownArea = styled.div`
  display: ${({ toggleDropdown }) => (toggleDropdown ? 'block' : 'none')};
  position: absolute;
  background-color: #ffffff;
  min-width: 316px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  border-radius: 6px;
  padding: 32px 26px;
  right: 0;
`;

const DropdownMenuItem = styled(Link)`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: #000000;
  &:hover {
    color: #000000;
  }
`;

const MobileMenu = styled.div `
  display: flex;
  justify-content : space-between;
  align-items: center;
  width : 78vw;
  visibility : hidden;
  position: absolute;
  @media (max-width: 768px) {
    visibility : visible;
    position :relative;
    width : 100vw;

    .logo{
      width: 160px;
    }
 }
`;

const HorizontalBar1 = styled.div `
  width: 28px;
  height: 4.5px;
  border-radius: 4.5px;
  background-color: black;
  margin: 4px 0;
  -webkit-transform: ${({ show }) => ( !show ? '' : 'rotate(-45deg) translate(-9px, 6px)')}; 
  transform: ${({ show }) => ( !show ? '' : 'rotate(-45deg) translate(-5px, 6px)')} ;

`;

const HorizontalBar2 = styled.div `
  width: 28px;
  height: 4.5px;
  border-radius: 4.5px;
  background-color: black;
  margin: 4px 0;
  opacity: ${({ show }) => ( !show ? ' ' : '0')}  ;

`;

const HorizontalBar3 = styled.div `
  width: 28px;
  height: 4.5px;
  border-radius: 4.5px;
  background-color: black;
  margin: 4px 0;
  -webkit-transform: ${({ show }) => ( !show ? '' : 'rotate(45deg) translate(-8px, -8px)')} ;
  transform: ${({ show }) => ( !show ? '' : 'rotate(45deg) translate(-6px, -7px)')} ;

`;
const MenuIcon = styled.div `
cursor: pointer;
 
`;




export default function Navbar({ topbarMenuItems, pageType, onMenuBarClick, setShow, show }) {
  // const [show, setShow] = useState(true);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const { user } = useAuth();
  let check  = false;
 
  const toggle = () => {
    setToggleDropdown(!toggleDropdown);
  };

  const toggleShow = () => {
    setShow(!show);
  }

  return (
    <NavbarArea>
      <MobileMenu>
        <div>
          <img src={Logo} alt= "logo" className="logo"/>
        </div>
        <MenuIcon onClick={() => toggleShow()}>
        <HorizontalBar1 show={show}>
          </HorizontalBar1>
          <HorizontalBar2 show={show}>
          </HorizontalBar2>
          <HorizontalBar3 show={show}>
          </HorizontalBar3>
        </MenuIcon>
      </MobileMenu>
      <InputField>
        <Input type="text" placeholder="Search" style={{ width: '445px'}} />
      </InputField>
      <NavbarIconsArea>
        <NavbarIcon>
          <img src={Notification} alt="Notification" />
        </NavbarIcon>
        <Link to={pageType === "personal" ? "/user/settings/home" : "/admin/settings/home"} style={{ textDecoration: 'none' }}>
          <NavbarIcon>
            <img src={Settings} alt="Settings" />
          </NavbarIcon>
        </Link>
      </NavbarIconsArea>
      <DropDown onClick={() => toggle()}>
        <ProfileDropdown>
          <Avatar src={User} alt="Avatar" />
          <Username>{user.fullname}</Username>
          <DropdownCaret>
            {toggleDropdown ? <FaAngleUp /> : <FaAngleDown />}
          </DropdownCaret>
        </ProfileDropdown>
        <DropdownArea toggleDropdown={toggleDropdown}>
          {topbarMenuItems.map((menu, index) => (
            <DropdownMenuItem key={index} to={menu.url}>
              <p>{menu.label}</p>
              <FaAngleRight />
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem to="/logout">
            <p>Logout</p>
          </DropdownMenuItem>
          <hr />
          <DropdownMenuItem to="/">
            <p>Privacy Policy</p>
          </DropdownMenuItem>
        </DropdownArea>
      </DropDown>
    </NavbarArea>
  );
}
