import React from 'react';
import {
  SettingWrapper,
  TitleSecurityLog,
  Container,
  Paragraph,
  MarginStyle,
} from '../../../utils/common';
import Input from '../../../components/common/Input';
import Button from '../../../components/common/Button';

export default function SettingsGeneral() {
  return (
    <SettingWrapper >
      < TitleSecurityLog> General Settings </ TitleSecurityLog>
      <Container>
        <Paragraph>
          {' '}
          BASIC INFORMATION
        </Paragraph>
      </Container>
      <Container>
        <Paragraph style={{ marginLeft: '0%' }}>
          Only you can view and edit your information.
        </Paragraph>
      </Container>
      <MarginStyle style={{ marginTop: '0%' }}></MarginStyle>
      <form>
        <Input
          type="text"
          label="First Name"
          name="firstName"
        />
        <MarginStyle style={{ marginTop: '0%' }}></MarginStyle>{' '}
        <Input
          type="text"
          label="Last Name"
          name="lastName"
        />
        <MarginStyle style={{ marginTop: '0%' }}></MarginStyle>{' '}
        <Input
          type="date"
          label="Date of Birth"
          name="dateOfBirth"
        />
        <MarginStyle style={{ marginTop: '0%' }}></MarginStyle>{' '}
        <Input
          type="phonenumber"
          label="Phone Number"
          name="phoneNumber"
        />
        <MarginStyle style={{ marginTop: '0%' }}></MarginStyle>{' '}
        <Input
          type="email"
          label="Email"
          name="email"
        />
        <MarginStyle style={{ marginTop: '0%' }}></MarginStyle>{' '}
        <Button type="submit" children="Save"  />
      </form>
    </SettingWrapper>
  );
}
