import React, { useState } from 'react';
import styled from 'styled-components';
//import { apiGet } from '../../../utils/apiHelper';
import { Badge } from '../../../components/userHome/TransactionHistory';
// import MemberDetail from './MemberDetail';
//import { useAuth } from '../../../context/auth/AuthState';
//import Button from '../../../components/common/Button';
import { TrustGroupDiv } from '../../../layouts/UserTrustGroupLayout';
import {
  ContentArea,
  Table,
  TBody,
  THead,
  TR,
} from '../../admin/CreateUser/AllRegisteredMembers';
import Pagination from '../../../components/common/pagination';

export default function MonthlyDues() {
  const [monthlyDues, setMonthlyDues] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const ListPerPage = 10;
  const PageVisited = pageNumber * ListPerPage;
  // const [activeIndex, setActiveIndex] = useState('');
  // const [showDetails, setShowDetails] = useState(false);
  // const toggleShowDetails = () => setShowDetails(!showDetails);
  // const closeDetails = () => {
  //   setActiveIndex('');
  //   toggleShowDetails();
  // };

  //  const { user } = useAuth();

  //  useEffect(() => {
  //    apiGet(`/transaction/transaction-history/trustGroup?pageNo=${pageNumber}`, {}).then(
  //      (res) => {
  //        console.log({ res });
  //        setTransactions(res.data.data.content);
  //      },
  //      (err) => {
  //        console.log({ err });
  //      }
  //    );
  //  }, [pageNumber]);

  const TD = styled.td`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    background: rgba(246, 246, 246, 0.49);
    border-bottom: 2px solid #dadada;
    height: 3rem;
    padding: 0 10px;
    padding-left: 1.5rem;
  `;
  const TH = styled.th`
    border-bottom: 2px solid #dadada;
    font-style: normal;
    font-weight: bold;
    height: 1rem;
    background-color: #f7f7f7;
    font-size: 14px;
    margin: 0px;
    padding-left: 1.5rem;
  `;
  const Alert = styled.div`
    background-color: #de1e1e;
    color: #ffffff;
    width: 6.3rem;
    height: 2rem;
    display: flex;
    justify-content: row;
    align-items: center;
    border-radius: 4px;
    margin-left: 1rem;
    padding-left: 0.5rem;
  `;
  return (
    <div>
      <Table>
        <THead>
          <TR>
            <TH>Amount</TH>
            <TH>Date</TH>
            <TH>Time Recorded</TH>
            <TH>Status</TH>
          </TR>
        </THead>
        <TBody>
          {/* {transactions
              .slice(pageVisited, pageVisited + listPerPage).map((transaction, index) => ( */}
          <TR
          // key={index}
          >
            <TD>{/* {transaction.amount} */}</TD>
            <TD>
              {/* {new Date(transaction.createdAt).toLocaleDateString('en-NG')} */}
            </TD>
            <TD>
              {/* {new Date(transaction.createdAt.hour).toLocaleDateString('en-NG')} */}
            </TD>
            <TD>
              <Badge>
                {/* //  status={transaction.status === 'Pending' ? false : true}>
                  //   {transaction.status} */}
              </Badge>
            </TD>
          </TR>
          {/* ))} */}
          <TR>
            <TD>200,000</TD>
            <TD>04/04/2022</TD>
            <TD>04:45 PM</TD>
            <TD style={{ color: '#14A800' }}>Cleared</TD>
          </TR>
          <TR>
            <TD>200,000</TD>
            <TD>04/04/2022</TD>
            <TD>04:45 PM</TD>
            <TD style={{ color: '#14A800' }}>Cleared</TD>
          </TR>
          <TR>
            <TD>200,000</TD>
            <TD>04/04/2022</TD>
            <TD>04:45 PM</TD>
            <TD
              style={{
                color: '#DE1E1E',
                display: 'flex',
                justifyContent: 'row',
                alignItems: 'center',
              }}
            >
              <div>Not cleared </div>
              <Alert>1 defaulter</Alert>
            </TD>
          </TR>
          <TR>
            <TD>200,000</TD>
            <TD>04/04/2022</TD>
            <TD>04:45 PM</TD>
            <TD style={{ color: '#14A800' }}>Cleared</TD>
          </TR>
          <TR>
            <TD>200,000</TD>
            <TD>04/04/2022</TD>
            <TD>04:45 PM</TD>
            <TD style={{ color: '#14A800' }}>Cleared</TD>
          </TR>
        </TBody>
      </Table>
      {/* {showDetails && (
          <MemberDetail
            details={members[activeIndex]}
            closeDetails={closeDetails}
          />
        )} */}
      <Pagination
        fetcheddata={monthlyDues}
        itemsPerPage={10}
        setPageNumber={setPageNumber}
      />
    </div>
  );
}
