import React from 'react';
import { SpaceBetween } from '../../utils/common';
import { Box } from '../home/TotalMembers';
import styled from 'styled-components';

const CardTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
`;

const Filter = styled.p`
  font-size: 12px;
  color: #767a81;
`;

export default function RepaymentPlan() {
  return (
    <Box>
      <SpaceBetween>
        <CardTitle>Repayment Plan</CardTitle>
        <Filter>Last 30 days</Filter>
      </SpaceBetween>
    </Box>
  );
}
