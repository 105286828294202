import React from 'react';
import styled from 'styled-components';

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 10px;
  white-space: nowrap;
  color: ${({ labelColor }) => (labelColor ? labelColor : '#21334f')};
`;

const HelperText = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #14a800;
  margin-bottom: 0;
  margin-top: 10px;
`;

export const ErrorText = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #de1e1e;
  margin-bottom: 0;
  margin-top: 10px;
`;

const Input = styled.div`
  height: 48px;
  width: 100%;
  background: transparent;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 14px 14px;
  color: #979797;
  font-size: 16px;
  position: relative;
`;

const HideFile = styled.input`
  position: absolute;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  right: 0;
  top: 0;
  height: 100%;
  font-size: 24px;
  width: 100%;
`;

const ChooseFile = styled.div`
  width: 111px;
  height: 35px;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  position: absolute;
  top: 6px;
  left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '.4' : '.9')};
`;

const FileName = styled.div`
  position: absolute;
  left: 120px;
  opacity: ${({ disabled }) => (disabled ? '.2' : '.9')};
`;

export default function FileInput({
  label,
  name,
  labelColor,
  helperText,
  errorMessage,
  value,
  onChange,
  onBlur,
  isTrueOrFalse,
  accept,
  disabled
}) {
  return (
    <InputArea>
      <Label htmlFor={name} labelColor={labelColor}>
        {label}
      </Label>
      <Input>
        <ChooseFile disabled={disabled}>Choose File</ChooseFile>
        <FileName disabled={disabled}>{value ? value : 'No File Choosen'}</FileName>
        <HideFile
          accept={accept}
          type="file"
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          required={isTrueOrFalse}
          className="hide_file"
          disabled={disabled}
        />
      </Input>
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      <HelperText>{helperText}</HelperText>
    </InputArea>
  );
}
