import React from 'react';
import styled from 'styled-components';
// import { Center, Div } from '../utils/common';
import { Outlet, useLocation } from 'react-router-dom';
// import Logo from '../assets/Logo.svg';

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  height: ${({ location }) =>
    location === '/login/new/uniqueId' ? '' : '100vh'};
  padding-bottom: ${({ location }) =>
    location === '/login-uniqueId' ? '25px' : ''};
  overflow-y: auto;
`;

export default function AuthLayout() {
  const location = useLocation();
  return (
    <PageLayout location={location.pathname}>
     <Outlet />
      
    </PageLayout>
  );
}
