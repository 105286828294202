import React, { useState } from 'react';
import {
  Container,
  SettingWrapper,
  MarginStyle,
  Paragraph,
  TitleSecurityLog,
} from '../../../utils/common';
import Volume from '../../../assets/volume.svg';
import Key from '../../../assets/BlackKey.svg';
import Input from '../../../components/common/Input';
import Button from '../../../components/common/Button';
import ToggleButton from '../../../components/common/ToggleButton';
import { ErrorToast, SuccessToast } from '../../../utils/toast';
import { apiPatch } from '../../../utils/apiHelper';

export default function SecurityAndLogin() {
  const [selected, setSelected] = useState(false);
  const [userData, setUserData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const { currentPassword, newPassword, confirmPassword } = userData;

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const checkCurrentPassword = () => {
    if (currentPassword.length >= 8) {
      setErrors((prevState) => ({
        ...prevState,
        currentPassword: '',
      }));
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        currentPassword: 'Current password is incomplete',
      }));
      return false;
    }
  };
  const checkPasswordLength = () => {
    if (newPassword.length >= 8) {
      setErrors((prevState) => ({
        ...prevState,
        newPassword: '',
      }));
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        newPassword: 'Password is too short',
      }));
      return false;
    }
  };
  const checkPasswordsMatch = () => {
    if (newPassword === confirmPassword) {
      setErrors((prevState) => ({
        ...prevState,
        confirmPassword: '',
      }));
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        confirmPassword: 'Passwords do not match',
      }));
      return false;
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      checkCurrentPassword() &&
      checkPasswordLength() &&
      checkPasswordsMatch()
    ) {
      try {
         await apiPatch(
          '/auth/admin/change-password',
          { currentPassword, newPassword },
          {},
          false
        );
        SuccessToast('Successful', 5000);
        setUserData({
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      } catch (error) {
        ErrorToast('Invalid credentials');
      }
    }
  };

  return (
    <SettingWrapper>
      <TitleSecurityLog >Security and Login</TitleSecurityLog>
      <Paragraph
        style={{ color: '#000000', fontWeight: 'bold', fontSize: '16px' }}
      >
        RECOMMENDED
      </Paragraph>
      <Paragraph
        style={{ color: '#000000', fontWeight: 'bold', fontSize: '16px' }}
      >
        <img src={Volume} alt="" style={{ paddingRight: '5px' }} /> Security
        alert
      </Paragraph>
      <Container>
        <Paragraph style={{ marginLeft: '3%' }}>
          Improve the security of your account by getting alert when someone
          tries logging in to your account from an unknown device or broswer.
        </Paragraph>
        <Container style={{ marginLeft: '10%' }}>
          {' '}
          <ToggleButton
            selected={selected}
            toggleSelected={() => {
              setSelected(!selected);
            }}
            buttonColor="#000000"
          />{' '}
          <Paragraph
            style={{ color: '#000000', fontSize: '16px', marginLeft: '5%' }}
          >
            Email
          </Paragraph>
        </Container>
      </Container>
      <Paragraph
        style={{ color: '#000000', fontWeight: 'bold', fontSize: '16px' }}
      >
        LOGIN
      </Paragraph>
      <Paragraph
        style={{ color: '#000000', fontWeight: 'bold', fontSize: '16px' }}
      >
        <img src={Key} alt="" /> Change Password
      </Paragraph>
      <MarginStyle
        style={{ marginTop: '3%', position: 'relative' }}
      >
        <form>
          <Input
            type="password"
            label="Current Password"
            name="currentPassword"
            value={currentPassword}
            onChange={changeHandler}
            onBlur={checkCurrentPassword}
            error={errors.currentPassword && true}
            errorMessage={errors.currentPassword}
          />
          <MarginStyle style={{ marginTop: '2%' }}>
            {' '}
            <Input
              type="password"
              label="New Password"
              name="newPassword"
              value={newPassword}
              onChange={changeHandler}
              onBlur={checkPasswordLength}
              error={errors.newPassword && true}
              errorMessage={errors.newPassword}
            />
          </MarginStyle>

          <MarginStyle style={{ marginTop: '2%' }}>
            {' '}
            <Input
              type="password"
              label="Confirm Password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={changeHandler}
              onBlur={checkPasswordsMatch}
              error={errors.confirmPassword && true}
              errorMessage={errors.confirmPassword}
            />
          </MarginStyle>

          <MarginStyle style={{ marginTop: '2%' }}>
            {' '}
            <Button type="submit" children="Save" onClick={submitHandler} />
          </MarginStyle>
        </form>
      </MarginStyle>
    </SettingWrapper>
  );
}
