import React from 'react';
import styled from 'styled-components';
import Button from '../components/common/Button';
import { Title, AuthFormArea, Anchor, Center } from '../utils/common';
import Email from '../assets/email.svg';
import { BiArrowBack } from 'react-icons/bi';
import { useSearchParams } from 'react-router-dom';
import { BackButton } from '../components/auth/common';

const Paragraph = styled.p`
  font-size: 16px;
  text-align: center;
  padding-bottom: 40px;
`;

const AfterText = styled.p`
  font-size: 14px;
  text-align: center;
  padding-top: 26px;
`;

const AfterTextLink = styled.a`
  font-weight: bold;
  text-decoration: none;
  color: #14a800;
  cursor: pointer;
  &:hover {
    color: #14a800;
  }
`;

export default function EmailSent() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  return (
    <AuthFormArea>
      <Center>
        <img src={Email} alt="Email" />
      </Center>
      <Title>Check your email</Title>
      <Paragraph>We sent a password reset link to {email}</Paragraph>
      <Center>
        <Button
          style={{
            marginTop: '13px',
          }}
        >
          Open email app
        </Button>
      </Center>
      <AfterText>
        Didn’t receive the email?{' '}
        <AfterTextLink>Click to Resend link</AfterTextLink>
      </AfterText>
      <Center>
        <Anchor href="/login">
          <BackButton style={{ marginLeft: '2rem' }}>
            <BiArrowBack style={{ marginRight: '15px' }} /> Back to login
          </BackButton>
        </Anchor>
      </Center>
    </AuthFormArea>
  );
}
