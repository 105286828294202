import React, { useEffect } from 'react';
import { useAuth } from '../context/auth/AuthState';

export default function Logout() {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return <div></div>;
}
