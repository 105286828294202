const dummyGroup = [
  {
    id: '1',
    title: 'Ace',
    startDate: '01 Nov, 2021',
    totalAmountLoaned: 'N150,000,000',
    rentals: 'N10,000,000',
    unpaidBalance: 'N50,000,000',
    members: [
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
    ],
  },
  {
    id: '2',
    title: 'Jack',
    startDate: '01 Nov, 2021',
    totalAmountLoaned: 'N150,000,000',
    rentals: 'N10,000,000',
    unpaidBalance: 'N50,000,000',
    members: [
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
    ],
  },
  {
    id: '3',
    title: 'Queen',
    startDate: '01 Nov, 2021',
    totalAmountLoaned: 'N150,000,000',
    rentals: 'N10,000,000',
    unpaidBalance: 'N50,000,000',
    members: [
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
    ],
  },
  {
    id: '4',
    title: 'King',
    startDate: '01 Nov, 2021',
    totalAmountLoaned: 'N150,000,000',
    rentals: 'N10,000,000',
    unpaidBalance: 'N50,000,000',
    members: [
      'https://picsum.photos/70',
      'https://picsum.photos/80',
      'https://picsum.photos/90',
      'https://picsum.photos/100',
    ],
  },
  {
    id: '5',
    title: 'King',
    startDate: '01 Nov, 2021',
    totalAmountLoaned: 'N150,000,000',
    rentals: 'N10,000,000',
    unpaidBalance: 'N50,000,000',
    members: [
      'https://picsum.photos/70',
      'https://picsum.photos/80',
      'https://picsum.photos/90',
      'https://picsum.photos/100',
    ],
  },
  {
    id: '6',
    title: 'Queen',
    startDate: '01 Nov, 2021',
    totalAmountLoaned: 'N150,000,000',
    rentals: 'N10,000,000',
    unpaidBalance: 'N50,000,000',
    members: [
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
    ],
  },
  {
    id: '7',
    title: 'Jack',
    startDate: '01 Nov, 2021',
    totalAmountLoaned: 'N150,000,000',
    rentals: 'N10,000,000',
    unpaidBalance: 'N50,000,000',
    members: [
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
    ],
  },
  {
    id: '8',
    title: 'Ace',
    startDate: '01 Nov, 2021',
    totalAmountLoaned: 'N150,000,000',
    rentals: 'N10,000,000',
    unpaidBalance: 'N50,000,000',
    members: [
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
      {
        image: 'https://picsum.photos/70',
      },
    ],
  },
];

export default dummyGroup;
