import React, { useState } from 'react';
import styled from 'styled-components';
import SupportShell from '../../assets/SupportShell.svg';
import DoubleRight from '../../assets/DoubleRight.svg';
import DoubleLeft from '../../assets/DoubleLeft.svg';
import Signout from '../../assets/Sign-out.svg';
import Navlink from './Navlink';
import { useAuth } from '../../context/auth/AuthState';

const NavIconContainer = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const ToggleIcon = styled.div`
  position: absolute;
  top: 80px;
  float: right;
  right: -10px;
  z-index: 1;
  cursor: pointer;
`;

const SidebarArea = styled.div`
  width: ${({ collapsed }) => (collapsed ? '117px' : '288px')};
  height: 100vh;
  position: fixed;
  transition: width 0.2s;
  z-index: 999;
  background: #fff;
  @media (max-width: 768px) {
   display:none;
 }
`;

const MenuItemsArea = styled.div`
  padding: 0 ${({ collapsed }) => (collapsed ? '29px' : '26px')};
  padding-bottom: 10px;
  margin: 29px 0;
`;

const BottomNavs = styled.div`
  position: absolute;
  bottom: 29px;
  width: ${({ collapsed }) => (collapsed ? '65px' : '236px')};
`;

const NavbarBrand = styled.div`
  width: ${({ collapsed }) => (collapsed ? '117px' : '288px')};
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavLabel = styled.div`
  white-space: nowrap;
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  color: #21334f;
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')};
`;

export default function Sidebar({ menuItems, bottomMenuItems, collapsed, setCollapsed }) {
  // const [collapsed, setCollapsed] = useState(false);
  const { logout } = useAuth();
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  var user = JSON.parse(localStorage.getItem('user'));
  let menus = user?.superadmin  === true? menuItems: menuItems.filter(menu => menu.label !== 'Create Admin');

  return (
    <SidebarArea collapsed={collapsed}>
      <NavbarBrand collapsed={collapsed}>
        <NavIconContainer>
          <img src={SupportShell} alt="Support Shell" />
        </NavIconContainer>
        <NavLabel collapsed={collapsed}>Support Shell</NavLabel>
      </NavbarBrand>
      <ToggleIcon onClick={() => toggle()}>
        <img src={collapsed ? DoubleRight : DoubleLeft} alt="" />
      </ToggleIcon>
      <MenuItemsArea collapsed={collapsed}>
      {menus.map((menuItem, index) => (
          <Navlink
            key={index}
            label={menuItem.label}
            url={menuItem.multiple ? menuItem.url[0] : menuItem.url}
            path={menuItem.multiple ? menuItem.url[1] : menuItem.url}
            icon={menuItem.icon}
            activeIcon={menuItem.activeIcon}
            collapsed={collapsed}
          />
        ))}

        <BottomNavs collapsed={collapsed}>
          {bottomMenuItems.map((menuItem, index) => (
            <Navlink
              key={index}
              label={menuItem.label}
              activeIcon={menuItem.activeIcon}
              url={menuItem.url}
              path={menuItem.url}
              icon={menuItem.icon}
              collapsed={collapsed}
            />
          ))}
          <Navlink
            label="Logout"
            activeIcon={Signout}
            url="/logout"
            path="/logout"
            icon={Signout}
            collapsed={collapsed}
            onClick={(e) => logout(e)}
          />
        </BottomNavs>
      </MenuItemsArea>
    </SidebarArea>
  );
}
