import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import CSVFileIcon from '../../../assets/CSVFileIcon.svg';

const Input = styled.div`
  height: 20rem;
  background: rgba(0, 0, 0, 0.03);
  border: 1px dashed #bdbdbd;
  position: relative;
  padding-top: -6rem;
`;

const InputHeader = styled.div`
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #21334f;
  font-weight: 600;
  font-size: 16px;
`;

const InputBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
`;

const Icon = styled.img`
  width: 10rem;
  height: 5rem;
`;

const UploadText2 = styled.p`
  font-size: 12px;
  color: #21334f;
`;

const Label = styled.h3`
  font-weight: 600;
  font-size: 14px;
  color: #bdbdbd;
  border-bottom: 1px dashed #bdbdbd;
  padding: 0.5rem;
`;

const FileName = styled.div`
  font-size: 14px;
  color: #bdbdbd;
`;

export default function CSVUploadCard({
  fileNames,
  file,
  setFile,
  uploadText1,
  uploadText2,
}) {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles) => setFile(acceptedFiles),
  });

  return (
    <div>
      <Input>
        <InputBody>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <div className="text-center">
              <Icon src={CSVFileIcon} alt="" />
              <InputHeader>
                {!uploadText1
                  ? 'Select a CSV file to upload'
                  : `${uploadText1}`}
              </InputHeader>
              <UploadText2>
                {!uploadText2 ? 'or drag and drop it here' : `${uploadText2}`}
              </UploadText2>
            </div>
          </div>
        </InputBody>
        <Label>Uploads</Label>
        <FileName>{fileNames}</FileName>
      </Input>
    </div>
  );
}
export { InputBody };
