import { useState } from 'react';
import styled from 'styled-components';
import { BiArrowBack } from 'react-icons/bi';
import { AiOutlinePlus } from 'react-icons/ai';
import { BsPencilSquare } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { RiArrowDropDownLine, RiUploadLine } from 'react-icons/ri';
import CreatePaymentRecord from './CreatePaymentRecord';
import { TrustGroupDiv } from '../../../layouts/UserTrustGroupLayout';
import Modal from '../../../components/common/NewModal';
import dummydata from './dummydata';
import Dropdown from './Dropdown';
import pencil from '../../../assets/pencil.svg';
import { Badge } from '../../../components/userHome/TransactionHistory';
import Pagination from '../../../components/common/pagination';
import { Center } from '../../../utils/common';

const Wrapper = styled.div``;
const BackHeader = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #21334f;
  margin-left: 12px;
  margin-top: 3px;
`;
const BackHome = styled.div`
  display: flex;
  cursor: pointer;
`;
const PencilImage = styled.div`

`;
const ArsenalTrustGroupHeader = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  color: #1d2126;
  margin-left: 5px;
  @media (max-width: 420px) {
    font-size: 18px;
  }
`;
const ButtonDiv = styled.div`
  width: 11vw;
  height: 44px;
  margin-left: 360px;
  top: 164px;
  background: #14a800;
  display: flex;
  flex-direction: row;
  padding: 10px 24px;
  cursor: pointer;
`;

const TrustGroupAdminDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 39px;
  margin-left: 0px;
`;
// const AddIconDiv = styled.div`
//   background: #ffffff;
//   mix-blend-mode: normal;
//   width: 20px;
//   height: 20px;
//   border-radius: 50%;
//   justify-content: center;
//   align-items: center;
//   padding: 2px;
// `;
const ButtonText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-left: 12px;
  margin-top: 2px;
`;
const FireActionButton = styled.div`
  width: 150px;
  height: 44px;
  margin-left: 40px;
  background: rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: row;
  cursor: pointer;
  @media (max-width: 420px) {
    margin-left: 0px;
  }
`;

const FilterText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #21334f;
  margin-top: 13px;
  margin-left: 10px;
`;

const ContentArea = styled.div`
  background: #ffffff;
  border-radius: 4px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  @media screen and (max-width: 426px) {
    width: 36rem;
  }
`;

const TR = styled.tr``;

const THead = styled.thead`
  background: #ffffff;
  height: 48px;
  padding: 0 16px;
  ${TR} {
    background: #ffffff;
  }
`;

const TBody = styled.tbody`
  padding: 0 16px; 
  ${TR} {
    .active {
      background: rgba(144, 144, 144, 0.2);
    }
    cursor: pointer;
  }
`;

const TD = styled.td`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  background: #ffffff;
  border-bottom: 2px solid #dadada;
  height: 5rem;
  padding: 0 1.5rem;
  position: relative;
  white-space: nowrap;
`;
const TH = styled.th`
  border-bottom: 2px solid #dadada;
  font-style: normal;
  font-weight: bold;
  height: 5rem;
  background: #fafafa;
  font-size: 14px;
  margin: 0px;
  padding-left: 1.5rem;
  white-space: nowrap;
`;

const FilterActionModal = styled.div`
  position: absolute;
  width: 215px;
  height: 134px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 47px;
  margin-left: -24px;
  z-index: 3;

  @media screen and (max-width: 420px){ 
  position: flex;
  width: 215px;
  height: 134px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 47px;
  margin-left: -24px;
  z-index: 3; 
    
}
`;

const ApprovedDiv = styled.div`
  width: 194px;
  height: 34px;
  margin-left: 10px;
  margin-top: 8px;
  background: rgba(20, 168, 0, 0.05);
  padding: 8px 120px 8px 10px;
  display: flex;
  cursor: pointer;
  flex-direction: row;
`;

const ApprovedText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #14a800;
`;

const FundedDiv = styled.div`
  width: 194px;
  height: 34px;
  margin-left: 10px;
  margin-top: 10px;
  background: rgba(57, 115, 244, 0.05);
  cursor: pointer;
  padding: 8px 120px 8px 10px;
  display: flex;
  cursor: pointer;
`;
const FundedText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3973f4;
`;

const PendingDiv = styled.div`
  width: 194px;
  height: 34px;
  margin-left: 10px;
  margin-top: 10px;
  background: rgba(255, 204, 77, 0.05);
  cursor: pointer;
  padding: 8px 120px 8px 10px;
  display: flex;
  cursor: pointer;
`;

const Pendingtext = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffcc4d;
`;

const OverlayModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;
// export const Badge = styled.span`
//   background: ${({ status }) =>
//     status ? 'rgba(222, 30, 30, 0.1)' : 'rgba(20, 168, 0, 0.05)' : 'rgba(222, 30, 30, 0.1)'};
//   color: ${({ status }) => (status ? '#3973F4' : '#14A800' : '#DE1E1E')};
//   border-radius: 100px;
//   padding: 4px 6px;
//   width: auto;
// `;

const PaymentRecord = () => {
  //   const [isOpen, setIsOpen] = useState(false);
  const [actionButton, setActionButton] = useState(false);
  const [fetcheddata, setFetchedData] = useState(dummydata);
  const [showModal, setShowModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const ListPerPage = 8;
  const PageVisited = pageNumber * ListPerPage;

  // Handle Clicks
  //const toggling = () => setIsOpen(!isOpen);
  const toggleModal = () => setShowModal(!showModal);

  const onCreatePaymentRecord = () => {
    toggleModal();
  };

  const handleSetActionButton = () => {
    setActionButton(!actionButton);
  };

  const hideNotification = () => {
    setActionButton(false);
  };

  return (
    <Wrapper>
      <BackHome>
        <Link to="/admin/home" style={{ color: 'black' }}>
          <BiArrowBack />
        </Link>
        <Link to="/admin/finance-record" style={{ textDecoration: 'none' }}>
          <BackHeader>Go Back</BackHeader>
        </Link>
      </BackHome>
      <TrustGroupAdminDiv>
        <ArsenalTrustGroupHeader>
          Arsenal Trust Group
        </ArsenalTrustGroupHeader>
        {/* <ButtonDiv onClick={() => toggleModal()}>
          <AddIconDiv>
            <AiOutlinePlus style={{ marginTop: '-10px', color: '#14A800' }} />
          </AddIconDiv>
          <ButtonText>New Record</ButtonText>
        </ButtonDiv> */}

        <div style={{ position: 'relative' }}>
          <FireActionButton onClick={handleSetActionButton}>
            <FilterText  >Filter Actions </FilterText>
            <RiArrowDropDownLine
              style={{
                fontSize: '35px',
                marginLeft: '20px',
                marginTop: '4px',
                cursor: 'pointer',
              }}
            />
          </FireActionButton>
          {actionButton && (
            <>
              <FilterActionModal style={{marginLeft: 850}}>
                <ApprovedDiv>
                  <ApprovedText>Paid</ApprovedText>
                </ApprovedDiv>
                <FundedDiv>
                  <FundedText>Cleared</FundedText>
                </FundedDiv>
                <PendingDiv>
                  <Pendingtext>Defaulting</Pendingtext>
                </PendingDiv>
              </FilterActionModal>
              <OverlayModal onClick={hideNotification} />
            </>
          )}
        </div>
      </TrustGroupAdminDiv>
      <TrustGroupDiv
        style={{ width: '100%', padding: '0px', marginTop: '30px' }}
      >
        <ContentArea>
          <Table style={{ padding: '50px' }}>
            <THead>
              <TR>
                <TH>Name</TH>
                <TH>Payment Type</TH>
                <TH>Payment Date</TH>
                <TH>Amount</TH>
                <TH>Last Updated</TH>
                <TH>Status</TH>
                <TH></TH>
              </TR>
            </THead>
            <TBody>
              {fetcheddata
                .slice(PageVisited, PageVisited + ListPerPage)
                .map((list, index) => (
                  <TR key={index}>
                    <TD>{list.name}</TD>
                    <TD>{list.PaymentType}</TD>
                    <TD>{list.PaymentDate}</TD>
                    <TD>{list.Amount}</TD>
                    <TD>{list.LastUpdated}</TD>
                    <TD><Badge status={false}>Paid</Badge></TD>
                    {/* <TD><Badge status={list.status === 'Paid' ? false : true}>
                    {list.status}
                  </Badge></TD> */}
                    <TD>
                    <BsPencilSquare style={{ marginTop: '-10px', color: '#14A800' }} onClick={() => toggleModal()}>
          <ButtonText>New Record</ButtonText>
        </BsPencilSquare>
                    </TD>
                  
                  </TR>
                  
                ))}
              {fetcheddata
                .slice(PageVisited, PageVisited + ListPerPage)
                .map((list, index) => (
                  <TR key={index}>
                    <TD>{list.name}</TD>
                    <TD>{list.PaymentType}</TD>
                    <TD>{list.PaymentDate}</TD>
                    <TD>{list.Amount}</TD>
                    <TD>{list.LastUpdated}</TD>
                    <TD><Badge status={false}>Cleared</Badge></TD>
                    {/* <TD><Badge status={list.status === 'Paid' ? false : true}>
                    {list.status}
                  </Badge></TD> */}
                    <TD>
                    <BsPencilSquare style={{ marginTop: '-10px', color: '#14A800' }} onClick={() => toggleModal()}>
                      <ButtonText>New Record</ButtonText>
        </BsPencilSquare>
                    </TD>
                  
                  </TR>
                  
                ))}
                {fetcheddata
                .slice(PageVisited, PageVisited + ListPerPage)
                .map((list, index) => (
                  <TR key={index}>
                    <TD>{list.name}</TD>
                    <TD>{list.PaymentType}</TD>
                    <TD>{list.PaymentDate}</TD>
                    <TD>{list.Amount}</TD>
                    <TD>{list.LastUpdated}</TD>
                    <TD><Badge status={false}>Defaulting</Badge></TD>
                    {/* <TD><Badge status={list.status === 'Paid' ? false : true}>
                    {list.status}
                  </Badge></TD> */}
                    <TD>
                    <BsPencilSquare style={{ marginTop: '-10px', color: '#14A800' }} onClick={() => toggleModal()}>
          <ButtonText>New Record</ButtonText>
        </BsPencilSquare >
                    </TD>
                  
                  </TR>
                  
                ))}
            </TBody>
          </Table>
        </ContentArea>
      </TrustGroupDiv>
      <Center>
        <Pagination
          fetcheddata={fetcheddata}
          itemsPerPage={8}
          setPageNumber={setPageNumber}
        />
      </Center>
      <Modal
        title="Edit"
        isOpen={showModal}
        closeModal={toggleModal}
      >
        <CreatePaymentRecord closeModal={onCreatePaymentRecord} />
      </Modal>
    </Wrapper>
  );
};

export default PaymentRecord;
export {
  TrustGroupAdminDiv,
  ArsenalTrustGroupHeader,
  FireActionButton,
  FilterText,
  FilterActionModal,
  ApprovedDiv,
  ApprovedText,
  FundedDiv,
  FundedText,
  PendingDiv,
  Pendingtext,
  OverlayModal,
  Wrapper,
};