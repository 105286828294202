import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
//import { apiGet } from '../../../utils/apiHelper';
import { Badge } from '../../../components/userHome/TransactionHistory';
// import MemberDetail from './MemberDetail';
//import { useAuth } from '../../../context/auth/AuthState';
//import Button from '../../../components/common/Button';
import { TrustGroupDiv } from '../../../layouts/UserTrustGroupLayout';
import {
  ContentArea,
  Table,
  TBody,
  THead,
  TR,
} from '../../admin/CreateUser/AllRegisteredMembers';
import { apiGet } from '../../../utils/apiHelper';
import Pagination from '../../../components/common/pagination';

export default function TrustGroupTransactionHistory() {
  const [transactions, setTransactions] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const ListPerPage = 10;
  const PageVisited = pageNumber * ListPerPage;
  // const [activeIndex, setActiveIndex] = useState('');
  // const [showDetails, setShowDetails] = useState(false);
  // const toggleShowDetails = () => setShowDetails(!showDetails);
  // const closeDetails = () => {
  //   setActiveIndex('');
  //   toggleShowDetails();
  // };

  useEffect(() => {
    apiGet(
      `/loan/user/transaction-history?pageNo=${pageNumber}`,
      {}
    ).then(
      (res) => {
        console.log({ res });
        setTransactions(res.data.data.content);
      },
      (err) => {
        console.log({ err });
      }
    );
  }, [pageNumber]);

  const TD = styled.td`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    background: rgba(246, 246, 246, 0.49);
    border-bottom: 2px solid #dadada;
    height: 3rem;
    padding-left: 1.5rem;
  `;
  const TH = styled.th`
    border-bottom: 2px solid #dadada;
    font-style: normal;
    font-weight: bold;
    height: 1rem;
    background-color: #f7f7f7;
    font-size: 14px;
    margin: 0px;
    padding-left: 1.5rem;
  `;
  return (
    <div>
        <Table>
          <THead>
            <TR>
              <TH>SN</TH>
              <TH>Name</TH>
              <TH>Date</TH>
              <TH>Amount</TH>
              <TH>Status</TH>
            </TR>
          </THead>
          <TBody>
            {/* {transactions
              .slice(pageVisited, pageVisited + listPerPage).map((transaction, index) => ( */}
            <TR
            // key={index}
            >
              <TD>1</TD>
              <TD>{/* {transaction.user.fullName} */}</TD>
              <TD>
                {/* {new Date(transaction.createdAt).toLocaleDateString('en-NG')} */}
              </TD>
              <TD>{/* {transaction.amount} */}</TD>
              <TD>
                <Badge>
                  {/* //  status={transaction.status === 'Pending' ? false : true}>
                  //   {transaction.status} */}
                </Badge>
              </TD>
            </TR>
            <TR>
              <TD>2</TD>
              <TD>Chinedu Uche</TD>
              <TD>04/04/2022</TD>
              <TD>200,000</TD>
              <TD style={{ color: '#14A800' }}>Cleared</TD>
            </TR>

            <TR>
              <TD>3</TD>
              <TD>Chinedu Uche</TD>
              <TD>04/04/2022</TD>
              <TD>200,000</TD>
              <TD style={{ color: '#14A800' }}>Cleared</TD>
            </TR>
            <TR>
              <TD>4</TD>
              <TD>Chinedu Uche</TD>
              <TD>04/04/2022</TD>
              <TD>200,000</TD>
              <TD style={{ color: '#DE1E1E' }}>Defaulted</TD>
            </TR>
            <TR>
              <TD>5</TD>
              <TD>Chinedu Uche</TD>
              <TD>04/04/2022</TD>
              <TD>200,000</TD>
              <TD style={{ color: '#14A800' }}>Cleared</TD>
            </TR>

            <TR>
              <TD>6</TD>
              <TD>Chinedu Uche</TD>
              <TD>04/04/2022</TD>
              <TD>200,000</TD>
              <TD style={{ color: '#14A800' }}>Cleared</TD>
            </TR>
          </TBody>
        </Table>
        {/* {showDetails && (
          <MemberDetail
            details={members[activeIndex]}
            closeDetails={closeDetails}
          />
        )} */}
      <Pagination
        fetcheddata={transactions}
        itemsPerPage={10}
        setPageNumber={setPageNumber}
      />
    </div>
  );
}
