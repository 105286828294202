import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { SuccessToast, ErrorToast } from '../utils/toast';
import { useState, useEffect } from 'react';
import { useAuth } from '../context/auth/AuthState';
import styled from 'styled-components';
import Input from '../components/auth/newinput';
import Button from '../components/common/Button';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { apiPost } from '../utils/apiHelper';
import SupportShell from '../assets/SupportShell.svg';
import sideimage2 from '../assets/sideimage2.svg';

import { Anchor, Center } from '../utils/common';

import {
  Header,
  FormWrapper,
  ImageSection,
  LoginSection,
  LogoWrapper,
  NavLabel,
  Wrapper,
  BackButton,
} from '../components/auth/common';

const LoginLogo = styled.img.attrs({
  src: `${SupportShell}`,
})``;

const SideImage = styled.img.attrs({
  src: `${sideimage2}`,
})`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;

const LoginUsersPage = () => {
  const { loading, isAuthenticated } = useAuth();
  const [formDetails, setFormDetails] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [tryLogin, setTrylogin] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const { email, password, confirmPassword } = formDetails;

  const { uniqueId } = useParams();

  useEffect(() => {
    if (loading) {
      SuccessToast('Loading');
    }

    if (isAuthenticated && !loading) {
      SuccessToast('Login Successful');
    }

    if (!isAuthenticated && !loading && tryLogin) {
      ErrorToast('Invalid Email or Password');
      setTrylogin(false);
    }
  }, [isAuthenticated, loading, tryLogin]);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const checkEmail = () => {
    if (
      !new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(email)
    ) {
      setErrors((prevState) => ({
        ...prevState,
        email: 'Enter a valid email',
      }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        email: '',
      }));
      return true;
    }
  };

  const checkPasswordLength = () => {
    if (password.length >= 8) {
      setIsValid(!isValid);
      setErrors((prevState) => ({
        ...prevState,
        password: '',
      }));
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        password: 'Password is too short',
      }));
      return false;
    }
  };

  const checkPasswordsMatch = () => {
    if (password === confirmPassword) {
      setErrors((prevState) => ({
        ...prevState,
        confirmPassword: '',
      }));
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        confirmPassword: 'Passwords do not match',
      }));
      return false;
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (checkPasswordsMatch() && checkEmail()) {
      try {
        await apiPost(
          '/auth/user/verify-uniqueId',
          { uniqueId, email, password },
          {},
          false
        );
        SuccessToast('User verified!');
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      } catch (error) {
        ErrorToast(error.response.data.message);
      }
    }
  };

  return (
    <Wrapper>
      <LoginSection>
        <LogoWrapper>
          <LoginLogo />
          <NavLabel>Support Shell</NavLabel>
        </LogoWrapper>
        <FormWrapper>
          <Header style={{ marginBottom: '22px' }}>Login to you account</Header>
          <form onSubmit={submitHandler}>
            <Input
              label="Email"
              name="email"
              value={formDetails.email}
              onChange={handleInputChange}
              type="email"
              placeholder="Email"
              isTrueOrFalse={true}
            />
            <Input
              label="Password"
              value={formDetails.password}
              onBlur={checkPasswordLength}
              onChange={handleInputChange}
              errorMessage={errors.password}
              type="password"
              name="password"
              placeholder="Password"
              isTrueOrFalse={true}
            />
            <Input
              label="Confirm Password"
              value={formDetails.confirmPassword}
              onBlur={checkPasswordsMatch}
              errorMessage={errors.confirmPassword}
              onChange={handleInputChange}
              type="password"
              name="confirmPassword"
              placeholder="ConfirmPassword"
              isTrueOrFalse={true}
            />

            <Anchor>
              <Button type="submit">Login</Button>
            </Anchor>
            <Center>
              <Link to="/login" style={{ textDecoration: 'none' }}>
                <BackButton>
                  <BiArrowBack style={{ marginRight: '15px' }} /> Back to Login
                </BackButton>
              </Link>
            </Center>
          </form>
        </FormWrapper>
      </LoginSection>
      <ImageSection>
        <SideImage />
      </ImageSection>
    </Wrapper>
  );
};

export default LoginUsersPage;
