import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Center, SpaceBetween } from '../../../utils/common';
import Avatar from '../../../assets/User.svg';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { AiTwotonePhone } from 'react-icons/ai';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import Button from '../../../components/common/Button';
import { AiFillStar } from 'react-icons/ai';
import GenderIcon from '../../../assets/GenderIcon.svg';

const MemberDetails = styled.div`
  width: 325px;
  height: 100%;
  border: 1px solid #dadada;
`;

const DetailsHeader = styled.div`
  height: 48px;
  padding: 15px 15px;
  border-bottom: 1px solid #dadada;
`;

const DetailsTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #000000;
`;

const DetailsEdit = styled.div`
  font-size: 14px;
  color: #14a800;
`;

const DetailsContent = styled.div`
  padding: 18px 24px;
`;

const IconValue = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 12px 0;
`;

const Icon = styled.div`
  height: 26px;
  width: 26px;
  background: rgba(20, 168, 0, 0.05);
  margin-right: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #14a800;
`;

const Value = styled.div`
  font-size: 14px;
  color: #000000;
`;

const ProfileImage = styled.img`
  width: 94px;
  height: 94px;
  border-radius: 100%;
`;

export default function MemberDetail({ details, closeDetails }) {
  useEffect(() => {
    console.log(details);
  }, [details]);

  return (
    <div>
      <MemberDetails>
        <DetailsHeader>
          <SpaceBetween>
            <DetailsTitle onClick={() => closeDetails()}>
              <AiOutlineArrowLeft />
            </DetailsTitle>
            <DetailsTitle>Profile Details</DetailsTitle>
            <DetailsEdit>Edit</DetailsEdit>
          </SpaceBetween>
        </DetailsHeader>
        <DetailsContent>
          <Center style={{ marginBottom: '30px' }}>
            <ProfileImage src={Avatar} alt={details.fullName} />
          </Center>
          <IconValue>
            <Icon>
              <HiOutlineClipboardList />
            </Icon>
            <Value>{details.fullName}</Value>
          </IconValue>
          <IconValue>
            <Icon>
              <FaMapMarkerAlt />
            </Icon>
            <Value>{details.address}</Value>
          </IconValue>
          <IconValue>
            <Icon>
              <AiTwotonePhone />
            </Icon>
            <Value>{details.phoneNumber}</Value>
          </IconValue>
          <IconValue>
            <Icon>
              <img src={GenderIcon} alt="" />
            </Icon>
            <Value>{details.gender}</Value>
          </IconValue>
          <IconValue>
            <Icon>
              <FaRegCalendarAlt />
            </Icon>
            <Value>
              {new Date(details.dateOfBirth).toLocaleDateString('en-NG')}
            </Value>
          </IconValue>
          <IconValue>
            <Icon>
              <MdEmail />
            </Icon>
            <Value>{details.email}</Value>
          </IconValue>
          <IconValue>
            <Icon>
              <AiFillStar />
            </Icon>
            <Value>{details.status}</Value>
          </IconValue>
          <Button type="button" style={{ width: '100px', fontSize: '14px' }}>
            Resend Link
          </Button>
        </DetailsContent>
      </MemberDetails>
    </div>
  );
}
