import React, {  useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {  PageTitle, SpaceBetween } from '../../../utils/common';
import PropTypes from 'prop-types';
import { GoKebabVertical } from 'react-icons/go';
import { AiFillEdit } from 'react-icons/ai';
import {MdArrowForwardIos }  from 'react-icons/md'
import { MdDelete } from 'react-icons/md';

const CardArea = styled.div`
  width: 100%;
  height: 292px;
  border-radius: 4px;
  background-color: #ffffff;
`;
const CardHeader = styled.div`
  padding: 10px 20px 0;
  color: #1d2126;
  border-bottom: 1px solid #e5e5e5;
`;

const HeaderText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #c4c4c4;
`;

// const ActiveButton = styled.div`
//   width: 68px;
//   height: 40px;
//   background: rgba(20, 168, 0, 0.05);
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 10px;
//   margin-top: 5px;
// `;

const CardBody = styled.div`
  height: 274px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

`;

const Discussing = styled.div`
  display: flex;
  align-items: center;
`;

const AllMember = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #21334f;
`;

const Stack = styled.div`
  overflow: hidden;
  margin-right: 6px;
  img {
    background: #c4c4c4;
    border: 2px solid #ffffff;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
  }
  .stack-image {
    margin-left: -18px;
    postion: absolute;
  }
`;

const DropDown = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-block;
  float: right;
  z-index: 50;
`;

const DropdownMenuItem = styled.a`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top:20px;
  color: #000000;
  margin-bottom: 10px;
  &:hover {
    color: #000000;
  }
`;
// const ActiveText = styled.div`
//   font-family: 'Mulish';
//   font-style: normal;
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 20px;
//   color: #14a800;
// `;
const MemeberOfTrustGroup = styled.div`
  width: 50px;
  height: 50px;
  margin-left: 130px;
  background: #14a800;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #ffffff;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-left: auto;
`;

const TotalMembers = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DropdownMenuItemLabel = styled.div`
  margin-left: 10px;
`;

const OverlayModal= styled.div`
position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0;
//  background-color: rgba(0, 0, 0, 0.4);
display: flex;
justify-content: center;
align-items: center;
z-index: 2;
`

export default function TrustGroupCard({
  title,
  date,
  time,
  createdBy,
  id,
  members,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };
   

  const DropdownArea = styled.div`
    display: ${showOptions ? 'block' : 'none'};
    position: absolute;
    background-color: #ffffff;
    min-width: 215px;
    height: 118px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    z-index: 1;
    border-radius: 8px;
    padding: 13px 24px;
    right: 0;
  `;

  return (
    <CardArea>
      <CardHeader>
        <SpaceBetween>
          <PageTitle>{title.slice(0, 17)}</PageTitle>
          <DropDown>
            <GoKebabVertical onClick={() => toggleOptions()} />
            <DropdownArea>
              <DropdownMenuItem href={`trust-group/${id}/edit`}>
                <AiFillEdit />
                <DropdownMenuItemLabel>Edit Trust Group</DropdownMenuItemLabel>
              </DropdownMenuItem>
              <DropdownMenuItem href={`trust-group/${id}/delete`}>
                <MdDelete />
                <DropdownMenuItemLabel>Delete</DropdownMenuItemLabel>
              </DropdownMenuItem>
            </DropdownArea>
          </DropDown>
        </SpaceBetween>
       { showOptions && <OverlayModal onClick={() => toggleOptions()}/> }
      </CardHeader>
      <CardBody>
        <div>
          <TotalMembers>
            <div className="members-avatar">
              <HeaderText>TOTAL MEMBERS</HeaderText>
              <Stack>
                {members.map((member, index) => (
                  <img
                    src={member.avatarUrl || 'https://picsum.photos/70'}
                    alt='img'
                    key={index}
                    className={`${index !== 0 ? 'stack-image' : ''}`}
                  />
                ))}
              </Stack>
            </div>
            <div style={{ display: 'flex', marginTop: '4px' }}>
              <MemeberOfTrustGroup>{members.length}</MemeberOfTrustGroup>
            </div>
          </TotalMembers>
          {/* <HeaderText style={{ marginTop: '20px' }}>STATUS</HeaderText> */}
          {/* <ActiveButton>
            <ActiveText>Active</ActiveText>
          </ActiveButton> */}
          <div>
            <SpaceBetween style={{ marginTop: '105px', }}>
              <Discussing>
                <AllMember>View all Members</AllMember>
              </Discussing>
              {/* href={`trust-group/${id} */}
              <Link to="/admin/registered-members" style={{ color: 'black', marginLeft:'163px' }}>
                <MdArrowForwardIos/>
              </Link>
            </SpaceBetween>
          </div>
        </div>
      </CardBody>
    </CardArea>
  );
}

TrustGroupCard.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
};
