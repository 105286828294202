import React, { useState } from 'react';
import styled from 'styled-components';
import { uploadFile } from '../../utils/apiHelper';
import Button from '../common/Button';
import UploadCard from './UploadCard';

const Label = styled.h3`
  font-weight: 600;
  font-size: 12px;
  color: #bdbdbd;
  margin-top: 30px;
`;

export default function FileUploadForm({ setUserData, closeModal }) {
  const [termsAndCondition, setTermsAndCondition] = useState('');
  const [approvalLetter, setApprovalLetter] = useState('');
  const [loading, setLoading] = useState(false);

  const handleUpload = async (e) => {
    setLoading(true);
    if (termsAndCondition) {
      const termsAndConditionUrl = await uploadFile(termsAndCondition[0]);
      setUserData((prev) => {
        return {
          ...prev,
          termsAndConditionUrl,
        };
      });
    }
    if (approvalLetter) {
      const approvalLetterUrl = await uploadFile(termsAndCondition[0]);
      setUserData((prev) => {
        return {
          ...prev,
          approvalLetterUrl,
        };
      });
    }
    setLoading(false);
    closeModal(e);
  };

  return (
    <div>
      <div className="row">
        <div className="col-6">
          <UploadCard
            title="Upload Letter of Approval"
            name="approvalLetter"
            file={approvalLetter}
            setFile={(e) => setApprovalLetter(e)}
          />
        </div>
        <div className="col-6">
          <UploadCard
            title="Signed terms and conditions documents"
            name="termsAndCondition"
            file={termsAndCondition}
            setFile={(e) => setTermsAndCondition(e)}
          />
        </div>
      </div>
      <Label>Uploads</Label>
      {approvalLetter && <p>{approvalLetter[0].name}</p>}
      {termsAndCondition && <p>{termsAndCondition[0].name}</p>}
      <Button
        type="button"
        onClick={(e) => handleUpload(e)}
        disabled={!approvalLetter && !termsAndCondition}
        loading={loading}
      >
        {!loading && 'Upload'}
      </Button>
    </div>
  );
}
