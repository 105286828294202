import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SupportShell from '../assets/SupportShell.svg';
import styled from 'styled-components';
import uniqueid from '../assets/uniqueid.svg';
import { MarginStyle, Center } from '../utils/common';

import {
  Header,
  FormWrapper,
  ImageSection,
  LoginSection,
  LogoWrapper,
  NavLabel,
  Wrapper,
  BackButton,
} from '../components/auth/common';
import Input from '../components/auth/newinput';
import Button from '../components/common/Button';
import { BiArrowBack } from 'react-icons/bi';
import { apiPost } from '../utils/apiHelper';
import { ErrorToast, SuccessToast } from '../utils/toast';

const LoginLogo = styled.img.attrs({
  src: `${SupportShell}`,
})``;

const SideImage = styled.img.attrs({
  src: `${uniqueid}`,
})`
  width: 50%;
  height: 100vh;
  margin-top: -100px;
  object-fit: cover;
`;

const SecondHeader = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #1d2125;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 426px) {
    font-size: 14px;
  }
`;

function LoginUniqueIdPage() {
  const [userData, setUserData] = useState({
    uniqueId: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({
    password: '',
    confirmPassword: '',
    uniqueId: '',
    email: '',
  });
  const { password, confirmPassword, email, uniqueId } = userData;
  const navigate = useNavigate();
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkEmail = () => {
    if (
      !new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(email)
    ) {
      setErrors((prevState) => ({
        ...prevState,
        email: 'Enter a valid email',
      }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        email: '',
      }));
      return true;
    }
  };
  const checkPasswordLength = () => {
    if (password.length >= 7) {
      setErrors((prevState) => ({
        ...prevState,
        password: '',
      }));
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        password: 'Password is too short',
      }));
      return false;
    }
  };
  const checkPasswordsMatch = () => {
    if (password === confirmPassword) {
      setErrors((prevState) => ({
        ...prevState,
        confirmPassword: '',
      }));
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        confirmPassword: 'Passwords do not match',
      }));
      return false;
    }
  };
  const checkuniqueId = () => {
    if (uniqueId.length > 5) {
      setErrors((prevState) => ({
        ...prevState,
        uniqueId: '',
      }));
      return true;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        uniqueId: 'Enter valid unique Id',
      }));
      return false;
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (checkPasswordsMatch() && checkEmail() && checkuniqueId()) {
      try {
        await apiPost(
          '/auth/user/verify-uniqueId',
          { uniqueId, email, password },
          {},
          false
        );
        SuccessToast('User verified!');
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      } catch (error) {
        ErrorToast(error.response.data.message);
      }
    }
  };

  return (
    <Wrapper>
      <LoginSection style={{ height: '1000px', overflowY: 'auto' }}>
        <LogoWrapper>
          <LoginLogo />
          <NavLabel>Support Shell</NavLabel>
        </LogoWrapper>
        <FormWrapper>
          <SecondHeader>Hey! There seems to be an error</SecondHeader>
          <Header>Please use your unique ID</Header>
          <form onSubmit={submitHandler}>
            <Input
              type="text"
              label="Enter unique ID"
              value={uniqueId}
              name="uniqueId"
              onChange={changeHandler}
              onBlur={checkuniqueId}
              error={errors.uniqueId && true}
              errorMessage={errors.uniqueId}
              helperText="Enter the unique ID sent to your Email"
            />

            <MarginStyle>
              {' '}
              <Input
                type="email"
                label="Enter your Email"
                name="email"
                value={email}
                onChange={changeHandler}
                onBlur={checkEmail}
                error={errors.email && true}
                errorMessage={errors.email}
              />
            </MarginStyle>
            <MarginStyle>
              <Input
                type="password"
                label="Password"
                name="password"
                value={password}
                onChange={changeHandler}
                onBlur={checkPasswordLength}
                helperText="Must be atleast 8 characters"
                error={errors.password && true}
                errorMessage={errors.password}
              />
            </MarginStyle>
            <MarginStyle>
              <Input
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={changeHandler}
                onBlur={checkPasswordsMatch}
                error={errors.confirmPassword && true}
                errorMessage={errors.confirmPassword}
              />
            </MarginStyle>

            <MarginStyle>
              <Button type="submit" children="Submit" />
            </MarginStyle>

            <Center>
              <Link to="/login" style={{ textDecoration: 'none' }}>
                <BackButton>
                  <BiArrowBack style={{ marginRight: '15px' }} /> Back to Login
                </BackButton>
              </Link>
            </Center>
          </form>
        </FormWrapper>
      </LoginSection>
      <ImageSection>
        <SideImage style={{ height: '1000px', position: 'fixed' }} />
      </ImageSection>
    </Wrapper>
  );
}
export default LoginUniqueIdPage;
