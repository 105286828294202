import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import profilebackground from '../../assets/profilebackground.svg';
import addavatar from '../../assets/addavatar.png';
import Input from '../../components/common/Input';
import Button from '../../components/common/Button';
import { useAuth } from '../../context/auth/AuthState';
import FileInput from '../../components/common/FileInput';
import { ErrorToast } from '../../utils/toast';
import UserUpdated from '../../components/modal/UserUpdated';
import Modal from '../../components/common/Modal';
import { Center, Paragraph, AuthFormArea, Container, InputWidth, MarginStyle } from '../../utils/common';
import { apiPatch, apiGet } from '../../utils/apiHelper';

const ProfileDiv = styled.div`
  width: 1084px;
  height: 100vh;
  left: 318px;
  top: 110px;
  background: #ffffff;
  border-radius: 4px;
`;

const ProfileCoverImg = styled.img`
  width: 1084px;
  height: 165px;
  left: 318px;
  top: 110px;
  object-fit: cover;
  border: none;
  background: url(${profilebackground}), rgba(0, 0, 0, 0.05);
  cursor: pointer;
`;

const ProfileUserImg = styled.img`
  top: 45px;
  width: 210px;
  height: 210px;
  border-radius: 50%;
  cursor: pointer;
   background:url(${addavatar}),  #c4c4c4;
  //background: #c4c4c4;
  border: 2px solid green;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  margin: 80px;
  margin-left: 110px;
  margin-bottom: 30px;
`;

const ProfileInfo = styled.div`
  margin-left: -25px;
  margin-top: -200px;
  position: absolute;
`;

const Heading4div = styled.div`
  width: 202px;
  height: 40px;
  left: 604px;
  top: 287px;
`;

const Heading6div = styled.div`
  width: 106px;
  height: 18px;
  left: 604px;
  top: 333px;
`;

const Heading4 = styled.h5`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
`;

const Heading6 = styled.h6`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
`;

const PersonalInfo = () => {
  const [userData, setUserData] = useState({
    fullName: '',
    dateOfBirth: '',
    address: '',
    email: '',
    phone: '',
    avatarUrl: '',
    title: '',
  });

  const [errors, setErrors] = useState({
    fullName: '',
    dateOfBirth: '',
    address: '',
    email: '',
    phone: '',
    });

  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');

  const toggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    apiGet(`/user/member/${user.id}`)
      .then((res) => {
        const userData = res.data.data;
        const prefillData = {
          email: userData.email,
          fullName: userData.fullName,
          phone: userData.phoneNumber,
          address: userData.address,
          dateOfBirth: userData.dateOfBirth,
          };
        setUserData(prefillData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.id]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateaddress = () => {
    if (userData.address.length <= 4) {
      setErrors((prevState) => ({
        ...prevState,
        address: 'Enter a valid address',
      }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        address: '',
      }));
      return true;
    }
  };

    

  const validateInput = () => {
    const valid =
      validateaddress()
      
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateInput()) {
      await apiPatch('/user/update-profile', userData, {}, true).then(
        (res) => {
          setUserId(res.data.data);
          setShowSuccess(true);
          setLoading(false);
        },
        (err) => {
          if (!err.response) {
            ErrorToast('Something went wrong');
          } else {
            ErrorToast(err.response.data.message);
          }
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  };

  if (showSuccess) {
    return <UserUpdated userId={userId && userId} />;
  }
  return (
    <Center style={{ height: '100%' }}>
      <ProfileDiv>
        <ProfileCoverImg>
        </ProfileCoverImg>
        
        <ProfileInfo>
          <ProfileUserImg onClick={() => toggleModal()} />

          <div style={{ marginLeft: '350px', marginTop: '210px' }}>
            <Heading4div>
              <Heading4>{user.fullname.split(' ')[0]}</Heading4>
              <Paragraph>
            Admin/Support Team
          </Paragraph>
            </Heading4div>

            <Heading6div>
              <Heading6>{userData.title}</Heading6>
            </Heading6div>
          </div>
        </ProfileInfo>

        <AuthFormArea style={{ width: '100%', marginTop: '120px' }}>
          <form onSubmit={handleSubmit}>
            <Container>
              <InputWidth>
                <Input
                  autoComplete="off"
                  type="text"
                  label="Full Name"
                  name="fullName"
                  value={userData.fullName}
                  placeholder="Sochima Ikeji"
                  onChange={changeHandler}
                  // onBlur={validateFullName}
                  errorMessage={errors.fullName}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                  isTrueOrFalse={true}
                  
                />
              </InputWidth>
              <InputWidth>
                <Input
                  autoComplete="off"
                  type="date"
                  label="Date of Birth"
                  name="dateOfBirth"
                  value={userData.dateOfBirth}
                  onChange={changeHandler}
                  // onBlur={validateDateOfBirth}
                  errorMessage={errors.dateOfBirth}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                  
                />
              </InputWidth>
            </Container>
            <Container>
              <InputWidth>
                <Input
                  autoComplete="off"
                  type="text"
                  label="Address"
                  name="address"
                  placeholder="12B, Owoniyi Street, Iju, Lagos"
                  value={userData.address}
                  onChange={changeHandler}
                  onBlur={validateaddress}
                  errorMessage={errors.address}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                />
              </InputWidth>
              <InputWidth>
                <Input
                  autoComplete="off"
                  type="email"
                  label="Email"
                  name="email"
                  placeholder="ayooluwaa@decagonhq.com"
                  value={userData.email}
                  onChange={changeHandler}
                  // onBlur={validateEmail}
                  errorMessage={errors.email}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                  
                />
              </InputWidth>
            </Container>

            <Container>
              <InputWidth>
                <Input
                  type="tel"
                  label="Phone Number" 
                  name="phone"
                  placeholder="08098778657"
                  value={userData.phone}
                  onChange={changeHandler}
                  // onBlur={validatephone}
                  errorMessage={errors.phone}
                  style={{ background: 'rgba(246, 246, 246, 0.49)' }}
                />
              </InputWidth>
            </Container>

            <MarginStyle>
              <Button
              loading={loading}
                style={{ marginTop: '42px', width: '100%' }}
                type="submit"
              >
                {!loading && "Save"}
              </Button>
            </MarginStyle>
          </form>
        </AuthFormArea>
        <Modal
          title="Upload Avatar"
          isOpen={showModal}
          closeModal={toggleModal}
        >
          <InputWidth style={{ width: '80%' }}>
            <FileInput
              name="avatar"
              value={userData.avatarUrl}
              onChange={(e) => changeHandler(e)}
              style={{ background: 'rgba(246, 246, 246, 0.49)', width: '80%' }}
              disabled={true}
            />
          </InputWidth>

          <Button style={{ marginTop: '42px', width: '40%' }} type="submit">
            Upload
          </Button>
        </Modal>
      </ProfileDiv>
    </Center>
  );
};

export default PersonalInfo;
;