import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
// import {AiOutlineClose} from 'react-icons/ai'
// import {GiHamburgerMenu} from 'react-icons/gi'

export const Nav = styled.div`
  display: flex;
  width: 100%;
  height: 120px;
  background: #ffffff;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

export const NavLogo = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  margin-left: 220px;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
`;

export const NavLink = styled(Link)`
  color: #031839;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-family: Mulish;
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  &:hover {
    color: #14a800;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #14a800;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 120px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MenuBtn = styled.nav`
  color: #031839;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  //height: 52px;
  cursor: pointer;
  font-family: Mulish;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;

export const MenuBtnLink = styled(Link)`
  border-radius: 4px;
  padding: 17px 22px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-size: 20px;
  left: 0px;
  top: 0px;
  background: #14a800;
  border-radius: 4px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }

  @media (max-width:900px){
    border-radius: 4px;
    padding: 17px 22px;
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-size: 20px;
    width: 25rem;
    left: 0px;
    top: 0px;
    background: #14a800;
    border-radius: 4px;
  }

  @media (max-width:400px){
    border-radius: 4px;
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-size: 15px;
    width: 20rem;
    background: #14a800;
    border-radius: 4px;
  }
`;

export const NavBtn = styled.nav`
  color: #031839;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  //height: 52px;
  cursor: pointer;
  font-family: Mulish;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  padding: 17px 22px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 24px;
  font-size: 20px;
  left: 0px;
  top: 0px;
  background: #14a800;
  border-radius: 4px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #14a800;
    border: 1px solid #14a800;
  }

  @media (max-width:900px){
    margin-left: 0px;
    width: 25rem;
  }

  @media (max-width:400px){
    font-size: 15px;
    width: 20rem;
  }
`;
