import Button from '../../../components/common/Button';
import { InputWidth } from '../../../utils/common';
import Input from '../../../components/common/Input';
import SelectInput from '../../../components/common/SelectInput';
import PropTypes from 'prop-types';

export default function CreatePaymentRecord({ closeModal }) {
  return (
    <div>
      <form>
        {/* <Input
          type="text"
          label="Name"
          name="name"
          placeholder="Name"
          value=""
        /> */}

        <Input
          type="text"
          label="Payment Type"
          name="name"
          placeholder="Payment Type"
          value=""
        />

        <Input type="Date" label="Payment Date" name="name" value="" />
        <Input
          type="text"
          label="Amount"
          name="name"
          placeholder="N0.00"
          value=""
        />
        {/* <InputWidth style={{ width:'100%' }}>
          <SelectInput
            label="Loan status"
            name="loan"
            value=''
            placeholder=""
            options={[
              { name: 'Cleared', value: 'Cleared' },
              { name: 'Paid', value: 'Paid' },
              { name: 'Defaulting', value: 'Defaulting' },
            ]}
          />
        </InputWidth> */}
        <Button type="submit" style={{ marginTop: '46px' }}>
          Add Record
        </Button>
      </form>
    </div>
  );
}

CreatePaymentRecord.propTypes = {
  closeModal: PropTypes.func,
};
