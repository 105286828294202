import React, { useEffect } from 'react';
import styled from 'styled-components';
import Map from '../../../assets/Map.svg';
import GenderIcon from '../../../assets/GenderIcon.svg';
import greenEmail from '../../../assets/greenEmail.svg';
import call from '../../../assets/Call.svg';

const Input = styled.div`
  height: 100%;
  background: rgba(0, 0, 0, 0.03);
  border: 1px dashed #bdbdbd;
  position: relative;
  padding-top: -6rem;
`;

const InputHeader = styled.div`
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #21334f;
  font-weight: 600;
  font-size: 16px;
`;

const InputBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  height: 11rem;
  margin: 0.3rem;
`;

const Img = styled.img`
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  cursor: pointer;
  //background: #c4c4c4;
  object-fit: cover;
`;
const OtherDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0.25rem 0.25rem 1rem;
  font-size: 16px;
  height: 3rem;
  color: #21334f;
  margin: 0px;
  background: rgba(0, 0, 0, 0.04);
`;

const Icon = styled.img`
  margin-right: 1rem;
  height: 2.5rem;
  width: 2rem;
`;

const Text = styled.p`
  font-size: 16px;
  color: #21334f;
  padding-top: 0.5rem;
`;

export default function UserDetailCard({ details }) {
  useEffect(() => {
    console.log(details);
  }, [details]);
  return (
    <Section
      style={{
        flexDirection: 'column',
        height: '29rem',
        borderTop: '1px solid rgba(0,0,0,0.2)',
        marginTop: '0px',
        paddingTop: '1rem',
      }}
    >
      <Section>
        <Img
          style={{ background: `url(${details.image}), rgba(0, 0, 0, 0.05)` }}
        />
        <Section
          style={{
            paddingTop: '3rem',
            flexDirection: 'column',
            marginLeft: '2rem',
          }}
        >
          <InputHeader>{details.fullName}</InputHeader>
          <Text>{details.designation}</Text>
        </Section>
      </Section>
      <OtherDetails>
        <Text>OTHER DETAILS</Text>
      </OtherDetails>
      <Section style={{ flexDirection: 'column' }}>
        <Section>
          <Icon src={Map} />
          <Text>{details.address}</Text>
        </Section>
        <Section>
          <Icon src={call} />
          <Text>{details.phoneNumber}</Text>
        </Section>
        <Section>
          <Icon src={GenderIcon} />
          <Text>{details.gender}</Text>
        </Section>
        <Section>
          <Icon src={greenEmail} />
          <Text>{details.email}</Text>
        </Section>
      </Section>
    </Section>
  );
}
export { InputBody };
