import React from 'react';
import 'react-calendar/dist/Calendar.css';

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);
var currentDate = new Date("2022-03-16");
const myEventsList = [
  { start: new Date(), end: new Date(), title: "special event" },
  { start: currentDate, end: currentDate, title: "loan Payment" }
];

export default function CalendarSection() {
  return (
    <div>
      <Calendar
      localizer={localizer}
      events={myEventsList}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 430, width:390, fontSize:12 }}
      views={{ month: true }}
    />  
    </div>
  );
}
