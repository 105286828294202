import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Logo from '../../assets/Logo.svg';
import footerLogo from '../../assets/FooterLogo.svg';
import ManageGroups from '../../assets/ManageGroups.svg';
import ImageChart from '../../assets/imageonbusinesspart.svg';
import StatisticsAnalysis from '../../assets/StatisticsAnalysis.svg';
import ManageMultiAccts from '../../assets/ManageMultiAccts.svg';
import AboutSupportShell from '../../assets/AboutSupportShell.svg';
import AboutBackground from '../../assets/AboutBackground.svg';
import Testimony1 from '../../assets/Testimony1.svg';
import Testimony2 from '../../assets/Testimony2.svg';
import { NavLink as Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HomePagePersonal.css';
import { NavBtn, NavBtnLink } from '../../components/navs/NavbarElement';
//import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { GiHamburgerMenu } from 'react-icons/gi';
import Indeedlogo from '../../assets/Indeedlogo.svg';
import sterlingimglogo from '../../assets/sterlingimglogo.svg';
import PagalogoOnly from '../../assets/PagalogoOnly.svg';
import terragon from '../../assets/terragon.svg';
import clientlogomerck1 from '../../assets/clientlogomerck1.svg';
import interswitch_logo from '../../assets/interswitch_logo.png';
import './HomePagePersonal.css';
import {
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineLinkedin,
} from 'react-icons/ai';
import { ParagraphCopy } from './HomePagePersonal';
import { IoClose } from 'react-icons/io5';

const Container = styled.div`
  width: 1280px;
  margin: 0 auto;
  height: auto;

  @media (max-width: 1280px) {
    width: 95%;
    padding: 0 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 0 16px;
  }
`;

const NewContainer = styled.div`
  width: 1280px;
  margin: auto;

  @media (max-width: 1280px) {
    width: 95%;
    padding: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 16px;
    display: none;
  }

  @media (max-width: 420px) {
    padding: 10px;
    display: none;
  }
`;

const LogoImg = styled.img`
  width: 100%;
`;

const LogoNew = styled.img`
  @media (max-width: 420px) {
    width: 150px;
  }
`;

const LogoImg2 = styled.img`
  @media (max-width: 420px) {
    width: 80%;
    margin-left: 25px;
  }
`;
const NavButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  //height: 3rem;
  width: 240px;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 1rem 0;
  border-radius: 4px;
  color: #ffffff;
  background: #14a800;
  justify-contents: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #ffffff;
    color: #14a800;
    border: 1px solid #14a800;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: 420px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;
const Layout = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
`;

const HomePageNavbar = styled.div`
  position: fixed;
  width: 100%;
  height: 6rem; 
  border-bottom: 0.2rem solid #f1f1f1;
  background: #ffffff;
  z-index: 999;
  overflow: hidden;
  top: 0;
  display: flex;
  align-items: center;

  .logo{
    z-index: 99;
  }
  
  @media (max-width:900px){
    padding: 0.5rem 0.5rem; 
   
  }
`;

const HomePageNavbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const HeroImgWrapper = styled.div`
  width: 48%;
  img {
    width: 100%;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;
const HeroContent = styled.div`
  width: 48%;
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const DivContainer = styled.div`
  padding-bottom: 0px;
  height: inherit;
  display: flex;
  gap: 1;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

const WrapperDiv = styled.div`
  width: 40%;
  padding: 30px;

  @media (max-width: 900px) {
    width: 100%;
    padding-left: 50px;
  }
  @media (max-width: 420px) {
    padding-left: 15px;
  }
`;

const Div = styled.div`
  background: #ffffff;
  width: 100%;
  padding-top: 5rem;

  @media (max-width: 420px) {
    width: 100%;
    font-size: 24px;
    margin-left: 0rem;
    padding-left: 0rem;
    margin-left: 0px;
    display: inline-block;
    padding-top: 2rem;
  }
`;

const Services = styled.h1`
  font-family: Mulish;
  font-style: normal;
  font-size: 40px;
  font-weight: bold;
  color: #21334f;
  overflow: hidden;

  @media (max-width: 420px) {
    font-size: 24px;
  }
`;
const Heading = styled.h1`
  font-family: Mulish;
  font-style: normal;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  overflow: hidden;
  margin-left: 2rem;

  @media (max-width: 420px) {
    font-size: 16px;
    margin-left: 2rem;
  }
`;

const TesHeader = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #031839;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow: hidden;
  padding-top: 7rem;

  @media (max-width: 420px) {
    font-size: 24px;
  }
`;
const Paragraph = styled.p`
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: Mulish;
  font-style: normal;
  width: 80%;
  font-weight: normal;
  font-size: 18px;
  color: #21334f;
  overflow: hidden;

  @media (max-width: 420px) {
    margin-left: 2rem;
    font-size: 12px;
  }
`;

const TesParagraph = styled.p`
  margin-left: auto;
  margin-right: auto;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 420;
  font-size: 24px;
  line-height: 48px;
  text-align: center;
  color: #031839;

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;
const FooterDiv = styled.div`
background: #2d2f48;
width: 100%;
height: 270px;
overflow:hidden;

@media (max-width:900px){
  width:100%
  justify-content: center;
  align-items:center;
}
`;
const FooterHr = styled.hr`
  background: #ffffff;
  opacity: 0.2;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-top: 70px;

  @media (max-width: 900px) {
    width: 80%;
  }

  @media (max-width: 420px) {
    width: 90%;
    margin-top: 70px;
  }
`;
const FooterFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Span = styled.a`
  color: #ffffff;
  margin-left: 0.8rem;
  font-size: 1.2rem;
  border: none;
`;

const PartnerSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1280px;
  margin: auto;
  margin-top: 70px;

  @media (max-width: 1280px) {
    width: 95%;
    padding: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
    justify-content: space-between;
  }
`;
const PartnerTile = styled.div`
  display: flex;
  flex: 1;
`;
const Title = styled.h6`
  width: 202px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #031839;
  @media (max-width: 420px) {
    font-size: 14px;
    width: 158px;
  }
`;
const TileLine = styled.hr`
  height: 0px;
  border: 1px solid #dadada;
  flex: 1;
  margin-right: 70px;
  @media (max-width: 420px) {
    margin-right: 0px;
    width: 181px;
  }
`;

const IconLogo = styled.div`
  display: flex;
  margin-right: 70px;
  @media (max-width: 420px) {
    display: none;
  }
`;
const FooterLogoDiv = styled.div`
  width: 100%;

  @media (max-width: 420px) {
    width: 100%;
  }
  .footerLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
    margin-top: 5rem;

    @media (max-width: 420px) {
      width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

// const SpanButton = styled.span`
//   margin-left: 25px;

//   @media (max-width: 420px) {
//     margin-left: 0px;
//     justify-content: center;
//     align-items: center;
//   }

//   @media (max-width: 900px) {
//     margin-left: 0px;
//   }
// `;

const NewDiv = styled.div`
  background: #ffffff;
  width: 100%;
  @media (max-width: 420px) {
    width: 100%;
    padding-left: 0rem;
  }
`;

const ContentSectionH3 = styled.h3`
  font-family: Mulish;
  font-style: normal;
  font-size: 32px;
  font-weight: bold;
  color: #21334f;
  margin-left: 80px;
  overflow: hidden;

  @media (max-width: 900px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 5px;
  }

  @media (max-width: 420px) {
    height: 60px;
    font-size: 24px;
    line-height: 30px;
    width: 342px;
    margin-top: 0px;
  }
`;

const ContentSectionP = styled.p`
  margin-left: 80px;
  width: 519px;
  margin-top: 50px;
  color: #21334f;
  font-size: 16px;
  height: 112px;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 30px;
  display: flex;
  overflow: hidden;

  @media (max-width: 900px) {
    width: 100%;
    margin-left: 0px;
  }

  @media (max-width: 420px) {
    margin-top: 0px;
    font-size: 14px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
`;

const IconsDiv = styled.div`
  @media (max-width: 420px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const CopyRight = styled.div`
  @media (max-width: 420px) {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

const TestimonialSection = styled.div`
  width: 100%;
  background: rgba(246, 246, 246, 0.49);
  @media (max-width: 900px) {
    display: none;
  }
`;

const ScreenWrapper = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 60px;
  align-items: center;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }

  @media (max-width: 420px) {
    padding-bottom: 0px;
    flex-direction: column-reverse;
  }
`;

const HeroWrapper = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 60px;
  align-items: center;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }

  @media (max-width: 420px) {
    padding-bottom: 0px;
  }
`;

const DisplayScreen = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  padding-top: 6rem;

  .firstDiv {
    margin-top: 100px;
    @media (max-width: 900px) {
      width: 100%;
      margin-top: 40px;
      display: inline-block;
    }

    @media (max-width: 420px) {
      width: 100%;
      display: inline-block;
    }
  }
  .secondDiv {
    margin-top: 100px;
    margin-bottom: 50px;

    @media (max-width: 900px) {
      width: 100%;
      display: inline-block;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    @media (max-width: 420px) {
      margin-top: 0px;
      width: 100%;
      margin-left: 0px;
      display: inline-block;
    }
  }

  .ellipses {
    @media (max-width: 420px) {
      width: 100%;
    }
  }
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    height: auto;
    flex-direction: column-reverse;
  }
`;

const GuarantorImgWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    width: 100%;
    padding: 32px;
  }
`;

const InfoContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  @media (max-width: 900px) {
    width: 100%;
    padding: 32px;
  }
`;

const NewParagraph = styled.p`
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: Mulish;
  font-style: normal;
  width: 80%;
  font-weight: normal;
  font-size: 18px;
  color: #21334f;
  overflow: hidden;

  @media (max-width: 420px) {
    font-size: 12px;
  }
`;
const NewHeading = styled.h1`
  font-family: Mulish;
  font-style: normal;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  overflow: hidden;

  @media (max-width: 420px) {
    font-size: 24px;
  }
`;

const CarouseWrapper = styled.div`
  position: relative;
  width: 1280px;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 70px;
  display: grid;
  perspective: 100px;
  overflow: hidden;
`;
const CarouselSlide = styled.div`
display:flex;
width:250px;
align-items:center;
padding-top:20px;
padding-bottom:20px
overflow:hidden;
cursor:pointer;
img{
  transition: transform 1s;
  @media (max-width: 420px) {
    width: 150px;
  }
}
img:hover {
  transform: scale(1.5);
}
`;

const slidescroll = keyframes`
 0% { transform:translateX(0) }
 100% { transform:translateX(calc(-250px * 6)) }
`;

const CarouselTrack = styled.div`
  display: flex;
  width: calc(250 * 6);
  animation-name: ${slidescroll};
  animation-duration: 20s;
  animation-iteration-count: infinite;

  &:hover {
    animation-play-state: paused;
  }
`;

const PreviousBtn = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ ...style, display: 'block', background: 'black' }}
    />
  );
};

const NextBtn = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ ...style, display: 'block', background: 'black' }}
    ></div>
  );
};

export default function LandingBusiness() {
  const [active, setActive] = useState(false);

  const showMenu = () => {
    setActive(!active);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <NextBtn />,
    prevArrow: <PreviousBtn />,
  };

  const newsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextBtn />,
    prevArrow: <PreviousBtn />,
  };

  const myRef = useRef(null);
  useEffect(() => myRef.current.scrollIntoView(), []);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // Responsiveness
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', changeWidth);

    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, []);

  return (
    <Layout ref={myRef}>
      <HomePageNavbar>
        <Container>
          <HomePageNavbarWrapper>
            <Link className="logo" to="/">
              <LogoNew src={Logo} alt="Support-Shell Logo" />{' '}
            </Link>
            <div className="menu-icon" onClick={showMenu}>
              {active ? <IoClose /> : <GiHamburgerMenu />}
            </div>
            <nav className={active ? 'slider active' : 'slider'}>
              <ul>
                <li>
                  <Link to="/" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                    Personal
                  </Link>
                </li>
                <li>
                  <Link
                    to="/business"
                    style={{
                      fontSize: '1.1rem',
                      fontWeight: 'bold',
                      color: '#14a800',
                    }}
                  >
                    Business
                  </Link>
                </li>
                <li>
                  <Link
                    to="/login"
                    style={{ fontSize: '1.1rem', fontWeight: 'bold' }}
                  >
                    Login
                  </Link>
                </li>
                <li>
                  <NavBtn>
                    <NavBtnLink to="/join">
                      <span className="signstyle">Apply as a business</span>
                    </NavBtnLink>
                  </NavBtn>
                </li>
              </ul>
            </nav>
          </HomePageNavbarWrapper>
        </Container>
      </HomePageNavbar>

      <DisplayScreen ref={myRef}>
        <HeroWrapper>
          <HeroContent className="firstDiv">
            <Services>Track Disbursed Loans.</Services>
            <Services>Automate Loan Repayments.</Services>
            <Services>Monitor Loan Profiles.</Services>
            <Services>All in One Place.</Services>
            <Paragraph style={{ marginLeft: '0rem' }}>
              The Support Shell helps you manage and monitor loans that have
              been obtained through a trust group guarantor system.
            </Paragraph>
            <NavButton to="/join">
              <div>Apply as a business</div>
            </NavButton>
          </HeroContent>
          <HeroImgWrapper className="secondDiv">
            <img src={ImageChart} alt="img" className="ellipses"></img>
          </HeroImgWrapper>
        </HeroWrapper>
      </DisplayScreen>

      <PartnerSection>
        <PartnerTile>
          <Title>Companies that trust us</Title>
        </PartnerTile>
        <TileLine />
      </PartnerSection>

      <CarouseWrapper>
        <CarouselTrack>
          <CarouselSlide>
            <img src={Indeedlogo} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={sterlingimglogo} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={PagalogoOnly} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={terragon} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={clientlogomerck1} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
          <img src={interswitch_logo} alt="img"></img>
          </CarouselSlide>

          <CarouselSlide>
            <img src={Indeedlogo} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={sterlingimglogo} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={PagalogoOnly} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={terragon} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
            <img src={clientlogomerck1} alt="img"></img>
          </CarouselSlide>
          <CarouselSlide>
          <img src={interswitch_logo} alt="img"></img>
          </CarouselSlide>
        </CarouselTrack>
      </CarouseWrapper>

      <Container>
        <ScreenWrapper>
          <NewDiv>
            <LogoImg src={ManageGroups} alt="AboutSupportShell" />
          </NewDiv>

          <Div>
            <ContentSectionH3>Manage Groups</ContentSectionH3>
            <ContentSectionP>
              With the Support Shell Tool you can track and monitor individuals
              who have stood as a guarantor for one another in a trust group.
            </ContentSectionP>
          </Div>
        </ScreenWrapper>
      </Container>

      {/* ALternative section */}
      {(false || screenWidth < 900) && (
        <Container>
          <ScreenWrapper>
            <NewDiv>
              <LogoImg src={StatisticsAnalysis} alt="AboutSupportShell" />
            </NewDiv>

            <Div>
              <ContentSectionH3>Statistics Analysis</ContentSectionH3>
              <ContentSectionP>
                Get useful insights and analysis that will guide the entire loan
                process from start to finish.
              </ContentSectionP>
            </Div>
          </ScreenWrapper>
        </Container>
      )}

      {/* End */}

      <NewContainer>
        <ScreenWrapper>
          <Div>
            <ContentSectionH3>Statistics Analysis</ContentSectionH3>
            <ContentSectionP>
              Get useful insights and analysis that will guide the entire loan
              process from start to finish.
            </ContentSectionP>
          </Div>

          <NewDiv>
            <LogoImg src={StatisticsAnalysis} alt="AboutSupportShell" />
          </NewDiv>
        </ScreenWrapper>
      </NewContainer>

      <Container>
        <ScreenWrapper>
          <NewDiv>
            <LogoImg src={ManageMultiAccts} alt="AboutSupportShell" />
          </NewDiv>

          <Div>
            <ContentSectionH3>Manage Multiple Account</ContentSectionH3>
            <ContentSectionP>
              Whether its a group of 10 or a group of 50, you have the ability
              to track loan repayment for multiple groups.
            </ContentSectionP>
          </Div>
        </ScreenWrapper>
      </Container>

      {/* Alternative Testimonial slider */}
      {(false || screenWidth < 900) && (
        <div
          style={{
            width: '100%',
            height: '100vh',
            overflow: 'hidden',
            marginTop: '0px',
            paddingTop: '0px',
            background: 'rgba(246, 246, 246, 0.49)',
          }}
        >
          <TesHeader>Testimonials</TesHeader>
          <TesParagraph>
            Hear What Beneficiaries of Support Shell are Saying{' '}
          </TesParagraph>
          <DivContainer>
            <Slider
              {...newsettings}
              style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
            >
              <WrapperDiv
                style={{
                  background: 'rgba(246, 246, 246, 0.49)',
                }}
              >
                <LogoImg2 src={Testimony1} alt="Testimony1" />
                <Heading
                  style={{
                    padding: '0.2rem 0px',
                    fontSize: '16px',
                    color: '#031839',
                  }}
                >
                  Ikechukwu Stonecold
                </Heading>
                <Paragraph
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    padding: '0px',
                  }}
                >
                  CEO Innovativ8 Technology
                </Paragraph>
                <Paragraph
                  style={{
                    padding: '0.1rem 0px',
                    fontSize: '14px',
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  viverra dis tempor, lacus orci at. Massa velit mi pharetra
                  faucibus bibendum. Leo sit diam aliquam arcu
                </Paragraph>
              </WrapperDiv>
              <WrapperDiv
                style={{
                  background: 'rgba(246, 246, 246, 0.49)',
                }}
              >
                <LogoImg2 src={Testimony2} alt="Testimony2" />
                <Heading
                  style={{
                    padding: '0.2rem 0px',
                    fontSize: '16px',
                    color: '#031839',
                  }}
                >
                  Mark Collins
                </Heading>
                <Paragraph
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    padding: '0px',
                  }}
                >
                  CEO Arab Money
                </Paragraph>
                <Paragraph
                  style={{
                    padding: '0.1rem 0px',
                    fontSize: '14px',
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  viverra dis tempor, lacus orci at. Massa velit mi pharetra
                  faucibus bibendum. Leo sit diam aliquam arcu, gravida
                  habitasse.
                </Paragraph>
              </WrapperDiv>

              <WrapperDiv
                style={{
                  background: 'rgba(246, 246, 246, 0.49)',
                }}
              >
                <LogoImg2 src={Testimony1} alt="Testimony1" />
                <Heading
                  style={{
                    padding: '0.2rem 0px',
                    fontSize: '16px',
                    color: '#031839',
                  }}
                >
                  Ikechukwu Stonecold
                </Heading>
                <Paragraph
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    padding: '0px',
                  }}
                >
                  CEO Innovativ8 Technology
                </Paragraph>
                <Paragraph
                  style={{
                    padding: '0.1rem 0px',
                    fontSize: '14px',
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  viverra dis tempor, lacus orci at. Massa velit mi pharetra
                  faucibus bibendum. Leo sit diam aliquam arcu, gravida
                  habitasse.
                </Paragraph>
              </WrapperDiv>
              <WrapperDiv
                style={{
                  background: 'rgba(246, 246, 246, 0.49)',
                }}
              >
                <LogoImg2 src={Testimony2} alt="Testimony2" />
                <Heading
                  style={{
                    padding: '0.2rem 0px',
                    fontSize: '16px',
                    color: '#031839',
                  }}
                >
                  Mark Collins
                </Heading>
                <Paragraph
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    padding: '0px',
                  }}
                >
                  CEO Arab Money
                </Paragraph>
                <Paragraph
                  style={{
                    padding: '0.1rem 0px',
                    fontSize: '14px',
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  viverra dis tempor, lacus orci at. Massa velit mi pharetra
                  faucibus bibendum. Leo sit diam aliquam arcu, gravida
                  habitasse.
                </Paragraph>
              </WrapperDiv>
            </Slider>
          </DivContainer>
        </div>
      )}
      {/* End of testimonial slider */}

      <TestimonialSection>
        <TesHeader>Testimonials</TesHeader>
        <TesParagraph>
          Hear What Beneficiaries of Support Shell are Saying{' '}
        </TesParagraph>
        <DivContainer>
          <Slider
            {...settings}
            style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
          >
            <WrapperDiv
              style={{
                background: 'rgba(246, 246, 246, 0.49)',
              }}
            >
              <LogoImg src={Testimony1} alt="Testimony1" />
              <Heading
                style={{
                  padding: '0.2rem 0px',
                  margin: '0px',
                  fontSize: '30px',
                  color: '#031839',
                }}
              >
                Ikechukwu Stonecold
              </Heading>
              <Paragraph
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '0px',
                }}
              >
                CEO Innovativ8 Technology
              </Paragraph>
              <Paragraph
                style={{
                  padding: '0.1rem 0px',
                  fontSize: '14px',
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                viverra dis tempor, lacus orci at. Massa velit mi pharetra
                faucibus bibendum. Leo sit diam aliquam arcu, gravida habitasse.
              </Paragraph>
            </WrapperDiv>
            <WrapperDiv
              style={{
                background: 'rgba(246, 246, 246, 0.49)',
              }}
            >
              <LogoImg src={Testimony2} alt="Testimony2" />
              <Heading
                style={{
                  padding: '0.2rem 0px',
                  margin: '0px',
                  fontSize: '30px',
                  color: '#031839',
                }}
              >
                Mark Collins
              </Heading>
              <Paragraph
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '0px',
                }}
              >
                CEO Arab Money
              </Paragraph>
              <Paragraph
                style={{
                  padding: '0.1rem 0px',
                  fontSize: '14px',
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                viverra dis tempor, lacus orci at. Massa velit mi pharetra
                faucibus bibendum. Leo sit diam aliquam arcu, gravida habitasse.
              </Paragraph>
            </WrapperDiv>

            <WrapperDiv
              style={{
                background: 'rgba(246, 246, 246, 0.49)',
              }}
            >
              <LogoImg src={Testimony1} alt="Testimony1" />
              <Heading
                style={{
                  padding: '0.2rem 0px',
                  margin: '0px',
                  fontSize: '30px',
                  color: '#031839',
                }}
              >
                Ikechukwu Stonecold
              </Heading>
              <Paragraph
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '0px',
                }}
              >
                CEO Innovativ8 Technology
              </Paragraph>
              <Paragraph
                style={{
                  padding: '0.1rem 0px',
                  fontSize: '14px',
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                viverra dis tempor, lacus orci at. Massa velit mi pharetra
                faucibus bibendum. Leo sit diam aliquam arcu, gravida habitasse.
              </Paragraph>
            </WrapperDiv>
            <WrapperDiv
              style={{
                background: 'rgba(246, 246, 246, 0.49)',
              }}
            >
              <LogoImg src={Testimony2} alt="Testimony2" />
              <Heading
                style={{
                  padding: '0.2rem 0px',
                  margin: '0px',
                  fontSize: '30px',
                  color: '#031839',
                }}
              >
                Mark Collins
              </Heading>
              <Paragraph
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '0px',
                }}
              >
                CEO Arab Money
              </Paragraph>
              <Paragraph
                style={{
                  padding: '0.1rem 0px',
                  fontSize: '14px',
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                viverra dis tempor, lacus orci at. Massa velit mi pharetra
                faucibus bibendum. Leo sit diam aliquam arcu, gravida habitasse.
              </Paragraph>
            </WrapperDiv>
          </Slider>
        </DivContainer>
      </TestimonialSection>

      <InfoWrapper>
        <InfoContent
          style={{
            backgroundImage: `url(${AboutBackground})`,
          }}
          className="guarantordiv"
        >
          <NewHeading className="headerstyle">
            What is the Decagon <br></br> Support Shell?
          </NewHeading>
          <NewParagraph
            style={{
              color: '#ffffff',
              fontSize: '16px',
              width: '90%',
            }}
          >
            The Decagon Support Shell is a support system powered by Decagon
            that Matches individuals who do not qualify for a bank loan mainly
            because they lack guarantors to a guarantor within a verified Trust
            Group to fund their ambitious goals
          </NewParagraph>
          <NavButton
            style={{
              background: '#ffffff',
              color: '#14a800',
              // paddingLeft: '4.8rem',
            }}
            to="/join"
          >
            <span className="joinspan">Join now</span>
          </NavButton>
        </InfoContent>

        <GuarantorImgWrapper>
          <LogoImg
            src={AboutSupportShell}
            alt="AboutSupportShell"
            style={{ width: '100%' }}
          />
        </GuarantorImgWrapper>
      </InfoWrapper>

      <FooterDiv style={{ width: '100%' }}>
        {' '}
        <FooterLogoDiv>
          <img src={footerLogo} alt="Support Shell" className="footerLogo" />
        </FooterLogoDiv>
        <FooterHr />
        {(false || screenWidth < 420) && (
          <>
            <IconsDiv>
              <Span href="https://www.instagram.com/decagon_institute/">
                <AiOutlineInstagram />
              </Span>
              <Span href="https://twitter.com/DecagonIns">
                <AiOutlineTwitter />
              </Span>
              <Span href="https://www.linkedin.com/school/decagon/">
                <AiOutlineLinkedin />
              </Span>
            </IconsDiv>
            <CopyRight>
              &copy; 2022 Support Shell. All rights reserved
            </CopyRight>
          </>
        )}
        <FooterFlex>
          <ParagraphCopy>
            &copy; 2022 Support Shell. All rights reserved
          </ParagraphCopy>
          <IconLogo>
            <Span href="https://www.instagram.com/decagon_institute/">
              <AiOutlineInstagram />
            </Span>
            <Span href="https://twitter.com/DecagonIns">
              <AiOutlineTwitter />
            </Span>
            <Span href="https://www.linkedin.com/school/decagon/">
              <AiOutlineLinkedin />
            </Span>
          </IconLogo>
        </FooterFlex>
      </FooterDiv>
    </Layout>
  );
}
