const dummy = [
  {
    name: 'Daniel michael',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'Daniel michael',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'Daniel michael',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'Daniel michael',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'Daniel michael',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'Daniel michael',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'Daniel',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'Daniel michael',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'Daniel michael',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'Daniel michael',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page2 james',
    PaymentType: 'Defaulter',
    PaymentDate: '04/03/2022',
    Amount: 'N2,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page2 james',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page2 james',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page2 james',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page2 james',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page2 james',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page2 james',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page2 james',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page2 james',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page2 james',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page3 Ransome',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page3 Ransome',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page3 Ransome',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page3 Ransome',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page3 Ransome',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page3 Ransome',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page3 Ransome',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page3 Ransome',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page3 Ransome',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
  {
    name: 'page3 Ransome',
    PaymentType: 'Rental',
    PaymentDate: '04/03/2022',
    Amount: 'N3,000,000',
    LastUpdated: '20/01/2022 03:56PM',
  },
];

export default dummy;