import React from 'react';
import TrustWallet from '../assets/TrustWallet.svg';
import styled from 'styled-components';
import { Heading, Paragraph } from '../utils/common';
import { NavLink, Outlet } from 'react-router-dom';

const TrustGroupDiv = styled.div`
  background-color: #f2f2f2;
  height: 100%;
`;

const LoanAmountArea = styled.div`
  width: 21rem;
  height: 7rem;
  display: flex;
  padding: 1.2rem;
  background: #ffffff;
  justify-content: space-between;
  border-radius: 4px;
`;

const ContentArea = styled.div`
  width: 100%;
  border-radius: 4px;
  white-space: nowrap;
  overflow-x: auto;
`;

const TableArea = styled.div`
  width: 100%;
  height: 100vh;
  border-radius: 4px;
`;

const NavArea = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 7rem;
  display: flex;
  border-radius: 4px;
`;

const Navigation = styled(NavLink)`
  text-decoration: none;
  height: 3rem;
  width: 100%;
  cursor: pointer;
  font-size: 1rem;
  padding: 2rem 1.7rem;
  padding-bottom: 3rem;
  white-space: nowrap;
  color: ${({ clicked }) => (clicked ? '#14a800' : '#21334f')};
  border-bottom: ${({ clicked }) =>
    clicked ? '1px solid #14a800' : '1px solid #c5c5c5'};
  background: #f6f6f6;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &.active {
    transition: all 0.2s ease-in-out;
    color: #14a800;
    border-bottom: 1px solid #14a800;
    font-weight: bold;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    color: #14a800;
    border-bottom: 1px solid #14a800;
  }
`;

export default function UserTrustGroupLayout() {
  return (
    <TrustGroupDiv>
      <LoanAmountArea>
        <img
          src={TrustWallet}
          alt="wallet"
          style={{
            marginLeft: '0px',
            margin: '0.4rem',
          }}
        ></img>
        <div>
          <Paragraph
            style={{ color: '#21334F', padding: '0px', margin: '0px' }}
          >
            Total amount borrowed
          </Paragraph>
          <Heading
            style={{
              color: '#21334F',
              padding: '0px',
              margin: '0px',
            }}
          >
            N60,000,000
          </Heading>
        </div>
      </LoanAmountArea>
      <ContentArea>
        <NavArea>
          <Navigation to="transaction-history">Transaction History</Navigation>
          <Navigation to="monthly-dues">Monthly Dues</Navigation>
          <Navigation to="members">Trust Group members</Navigation>
        </NavArea>
        <TableArea>
          <Outlet />
        </TableArea>
      </ContentArea>
    </TrustGroupDiv>
  );
}
export { TrustGroupDiv };
