import { useState } from 'react';
import styled from 'styled-components';
import { BsThreeDots } from 'react-icons/bs';

const DropDownContainer = styled('div')`
  position: relative;
`;

const ActionModalUpdate = styled.div`
  width: 215px;
  height: 134px;
  background: #ffffff;
  margin-left: -100px;
  border-radius: 8px;
  position: absolute;
  border: 2px solid #dadada;
  z-index: 900;
`;

const OverlayModal= styled.div`
position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0;
// background-color: rgba(0, 0, 0, 0.4);
display: flex;
justify-content: center;
align-items: center;
z-index: 2;
`

const DisplayOption = ({ selectedOption }) => {
  const { style, label } = selectedOption;
  return <p style={{ color: style.color }}>{label}</p>;
};

const Dropdown = () => {
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  //Handle clicks
  const hideNotification = ()=>{
    setShow(false)
  }

  const options = [
    {
      label: 'Cleared',
      style: {
        color: '#14A800',
        cursor: 'pointer',
        textDecoration: 'none',
        listStyle: 'none',
        marginTop: '15px',
        zIndex: 900,
      },
    },
    {
      label: 'Paid',
      style: {
        color: '#FFCC4D',
        cursor: 'pointer',
        textDecoration: 'none',
        listStyle: 'none',
        marginTop: '10px',
        zIndex: 900,
      },
    },
    {
      label: 'Defaulting',
      style: {
        color: '#DE1E1E',
        cursor: 'pointer',
        textDecoration: 'none',
        listStyle: 'none',
        marginTop: '10px',
        zIndex: 900,
      },
    },
  ];

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setShow(false);
    console.log(selectedOption);
  };

  return (
    <DropDownContainer onClick={() => setShow(!show)}>
      {(selectedOption && (
        <DisplayOption selectedOption={selectedOption} />
      )) || <BsThreeDots style={{ marginLeft:'30px' }} />}

      {show && (
          <>
        <ActionModalUpdate>
          <ul>
            {options.map((option, index) => (
              <li
                onClick={onOptionClicked(option)}
                style={option.style}
                key={index}
              >
                {option.label}
              </li>
             
            ))}
          </ul>
        </ActionModalUpdate>
        <OverlayModal onClick={hideNotification}/>
        </>
      )}
    </DropDownContainer>
  );
};

export default Dropdown;
