import { PageTitle, Paragraph } from '../utils/common';
import NavIcon from '../assets/NavIcon.svg';
import styled from 'styled-components';
import { Link, Outlet } from 'react-router-dom';
const PageContainer = styled.div`
  height: 100vh;
  display: flex;

  @media screen and (max-width: 768px) {
    display: block;
    width: 100%;
    height: auto;
  }
   @media screen and (max-width: 420px) {
     display: block;
     width: 100%;
  }
`;

const ContentArea = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: inherit;
  padding: 30px 60px;
  background: #ffffff;

  @media screen and (max-width: 420px) {
    padding: 16px;  
  }  
`;

const Sidebar = styled.div`
  width: 259px;
  background: #21334f;
  padding: 20px;
  min-height: 100%;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem; 
    padding: 10px;
  }
   @media screen and (max-width: 420px) {
     width: 100%;
     display: flex;
     flex-wrap: wrap;
     grid-gap: 1rem;
  }
`;

const SidebarMenu = styled.div`
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 4px;

  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;

const MenuTitle = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;

  @media screen and (max-width: 768px){ 
    margin-bottom: 0px;
    margin-right: 7px;
    font-size: 14px;
}

@media screen and (max-width: 420px){ 
  margin-bottom: 0px;
  margin-right: 7px;
  font-size: 13px;
}
`;


export default function UserSettingsLayout() {
  return (
    <div style={{ height: '100%' }}>
      <>
        <PageTitle>Settings</PageTitle>
        <Paragraph>Manage your teams and preferences here</Paragraph>
      </>
      <PageContainer>
        <Sidebar>
          {/* <Link to="/user/settings/home" style={{ textDecoration: 'none' }}>
            <SidebarMenu>
              <MenuTitle>General Settings</MenuTitle>{' '}
              <MenuTitle>
                <img src={NavIcon} alt="" />
              </MenuTitle>
            </SidebarMenu>
          </Link> */}
          <Link to="/user/settings/home" style={{ textDecoration: 'none' }}>
            <SidebarMenu>
              {' '}
              <MenuTitle>Security and Login</MenuTitle>{' '}
              <MenuTitle>
                <img src={NavIcon} alt="" />
              </MenuTitle>
            </SidebarMenu>
          </Link>
          <Link
            to="/user/settings/notifications"
            style={{ textDecoration: 'none' }}
          >
            <SidebarMenu>
              {' '}
              <MenuTitle>Notifications</MenuTitle>{' '}
              <MenuTitle>
                <img src={NavIcon} alt="" />
              </MenuTitle>
            </SidebarMenu>
          </Link>
        </Sidebar>
        <ContentArea>
          <Outlet />
        </ContentArea>
      </PageContainer>
    </div>
  );
}
