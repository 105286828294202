import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Input from '../components/auth/newinput';
import Button from '../components/common/Button';
import styled from 'styled-components';
import { useAuth } from '../context/auth/AuthState';
import 'react-toastify/dist/ReactToastify.css';
import { BiArrowBack } from 'react-icons/bi';
import { Center } from '../utils/common';
import SupportShell from '../assets/SupportShell.svg';
import sideimage2 from '../assets/sideimage2.svg';
import {
  Header,
  FormWrapper,
  ImageSection,
  LoginSection,
  LogoWrapper,
  NavLabel,
  Wrapper,
  BackButton,
} from '../components/auth/common';

const LoginLogo = styled.img.attrs({
  src: `${SupportShell}`,
})``;

const SideImage = styled.img.attrs({
  src: `${sideimage2}`,
})`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;

const AdminFirstLogin = ({ newLogin }) => {
  const { login, loading } = useAuth();
  const [formDetails, setFormDetails] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    login({ ...formDetails, email: formDetails.email.toLowerCase() });
  };
  return (
    <Wrapper>
      <LoginSection>
        <LogoWrapper>
          <LoginLogo />
          <NavLabel>Support Shell</NavLabel>
        </LogoWrapper>
        <FormWrapper>
          <Header>Login to your account</Header>
          <form onSubmit={handleSubmit}>
            <Input
              label="Email"
              name="email"
              value={formDetails.email}
              onChange={handleInputChange}
              type="email"
              placeholder="Email"
              isTrueOrFalse={true}
            />
            <Input
              label="Password"
              value={formDetails.password}
              onChange={handleInputChange}
              type="password"
              helperText="Please input the password that was sent to you"
              name="password"
              placeholder="Password"
              isTrueOrFalse={true}
            />
            {!newLogin && <Link to="/forgot-password">Forgot Password?</Link>}
            <Button
              loading={loading}
              type="submit"
            >
              {!loading && 'Login'}
            </Button>

            <Center>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <BackButton>
                  <BiArrowBack style={{ marginRight: '15px' }} /> Back to Home
                </BackButton>
              </Link>
            </Center>
          </form>
        </FormWrapper>
      </LoginSection>
      <ImageSection>
        <SideImage />
      </ImageSection>
    </Wrapper>
  );
};
export default AdminFirstLogin;
