import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/common/Button';
import Input from '../../components/common/Input';
import UserCreated from '../../components/modal/UserCreated';
import { apiPost, apiGet } from '../../utils/apiHelper';
import validator from 'validator';
import {
  MarginStyle,
  ErrorText,
} from '../../utils/common';
import { ErrorToast } from '../../utils/toast';
import SelectInput from '../../components/common/SelectInput';
import styled from 'styled-components';
import Upload from '../../assets/Upload.svg';
import Modal from '../../components/common/Modal';
import FileUploadForm from '../../components/createUser/FileUploadForm';


const UploadButton = styled.button`
  color: #14a800;
  font-size: 16px;
  background: transparent;
  border: none;
`;

const Div = styled.div`

`;

const ViewMembers = styled.div`
  width: 163px;
  height: 44px;
  background: rgba(20, 168, 0, 0.05);
  border-radius: 4px;
  font-size: 16px;
  color: #14a800;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 420px) {
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    width: 105px;
    height: 24px;
    margin-top: 0px;
    justify-content: block;
    text-align: block;
}
`;

const HeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;

  // @media (max-width: 786px) {
  //   display: flex;
  // }
  @media (max-width: 420px) {
    display: block;
  }
`;

const AuthFormArea = styled.div`
  padding: 46px 57px;
  background-color: #ffffff;
  max-width: 100%;
  width: 700px;

  @media (max-width: 768px) {
    padding: 26px 40px;
  }
  @media (max-width: 420px) {
    padding: 18px 30px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-bottom: 14px;

  // @media (max-width: 786px) {
  //   display: flex;
  // }
  @media (max-width: 420px) {
    display: block;
  }
`;

const Heading = styled.div`
  text-align: center;
  font-family: Mulish;
  font-weight: bold;
  font-size: 32px;
  color: #21334f;

  @media (max-width: 786px) {
  }

  @media (max-width: 420px) {
  text-align: right;
  font-size: 15px;
  }
`;

const Paragraph = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #979797;
  margin-bottom: 0;

  @media screen and (max-width: 786px) {
    font-size: 14px;
   
  }

  @media screen and (max-width: 420px) {
    font-size: 10px;
   
  }
`;

const InputWidth = styled.div`
  width: 100%;

  @media screen and (max-width: 786px) {
    width: 100%;

  @media screen and (max-width: 420px) {
    width: 100%;
  }
   
`;

const UploadIcon = styled.img`
  margin-right: 6px;
`;

function CreateUser() {
  const [userData, setUserData] = useState({
    fullName: '',
    dateOfBirth: '',
    addressLine: '',
    email: '',
    phone: '',
    approvalLetterUrl: '',
    termsAndConditionUrl: '',
    loanRestructureUrl: '',
    trustGroupName: '',
    gender: '',
    designation: '',
    amountLoaned: '',
  });

  const [errors, setErrors] = useState({
    fullName: '',
    dateOfBirth: '',
    addressLine: '',
    email: '',
    phone: '',
    approvalLetterUrl: '',
    termsAndConditionUrl: '',
    loanRestructureUrl: '',
    trustGroupName: '',
    gender: '',
    designation: '',
    amountLoaned: '',
  });

  const [isValid, setIsValid] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [trustGroups, setTrustGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // const [genderList, setGenderList] = useState([]);

  const genderList = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' },
  ];

  const toggleModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  const {
    fullName,
    dateOfBirth,
    addressLine,
    email,
    phone,
    loanRestructureUrl,
    trustGroupName,
    gender,
    designation,
    amountLoaned,
  } = userData;

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateFullName = () => {
    if (
      !new RegExp(
        /^([A-Za-z]{3,16})([ ]{1})([A-Za-z]{3,16})?([ ]{1})?([A-Za-z]{3,16})$/
      ).test(fullName)
    ) {
      setErrors((prevState) => ({
        ...prevState,
        fullName: 'Enter a valid fullname',
      }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        fullName: '',
      }));
      return true;
    }
  };

  const validateDateOfBirth = () => {
    const age = new Date().getFullYear() - new Date(dateOfBirth).getFullYear();
    if (!validator.isDate(dateOfBirth) || age < 18) {
      setErrors((prevState) => ({ ...prevState, dateOfBirth: 'Invalid Date' }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        dateOfBirth: '',
      }));
      return true;
    }
  };

  const validateEmail = () => {
    if (!validator.isEmail(email)) {
      setErrors((prevState) => ({
        ...prevState,
        email: 'Enter a valid email',
      }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        email: '',
      }));
      return true;
    }
  };

  const validateAddressLine = () => {
    if (!addressLine) {
      setErrors((prevState) => ({
        ...prevState,
        addressLine: 'Enter a valid addressLine',
      }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        addressLine: '',
      }));
      return true;
    }
  };

  const validateDesignation = () => {
    if (!designation) {
      setErrors((prevState) => ({
        ...prevState,
        designation: 'Enter a valid designation',
      }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        designation: '',
      }));
      return true;
    }
  };

  const validatePhone = () => {
    if (phone.length < 8) {
      setErrors((prevState) => ({
        ...prevState,
        phone: 'Enter a valid phone',
      }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        phone: '',
      }));
      return true;
    }
  };

  const validateLoanRestructureUrl = () => {
    if (!loanRestructureUrl) {
      setErrors((prevState) => ({
        ...prevState,
        loanRestructure: 'Enter a valid loan Restructure Url',
      }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        loanRestructure: '',
      }));
      return true;
    }
  };

  const validateAmountLoaned = () => {
    if (!amountLoaned) {
      setErrors((prevState) => ({
        ...prevState,
        amountLoaned: 'Enter a valid loaned Amount',
      }));
      return false;
    } else {
      setErrors((prevState) => ({
        ...prevState,
        amountLoaned: '',
      }));
      return true;
    }
  };

  const validateGender = () => {
    if (!gender) {
      setErrors((prevState) => ({ ...prevState, gender: 'Select a Gender' }));
      return false;
    } else {
      setErrors((prevState) => ({ ...prevState, gender: '' }));
      return true;
    }
  };

  // const validateTrustGroupName = () => {
  //   if (!trustGroupName) {
  //     setErrors((prevState) => ({ ...prevState, trustGroupName: 'Select a Trust Group' }));
  //     return false;
  //   } else {
  //     setErrors((prevState) => ({ ...prevState, trustGroupName: '' }));
  //     return true;
  //   }
  // };

  const validateFIleUrl = (file) => {
    if (!userData[file]) {
      setErrors((prevState) => ({ ...prevState, [file]: 'Select a file' }));
      return false;
    } else {
      setErrors((prevState) => ({ ...prevState, [file]: '' }));
      return true;
    }
  };

  const validateInput = () => {
    setIsValid(validateFullName());
    setIsValid(validateDateOfBirth());
    setIsValid(validateAddressLine());
    setIsValid(validateEmail());
    setIsValid(validatePhone());
    setIsValid(validateGender());
    setIsValid(validateDesignation());
    setIsValid(validateLoanRestructureUrl());
    setIsValid(validateFIleUrl('approvalLetterUrl'));
    setIsValid(validateFIleUrl('termsAndConditionUrl'));
    return isValid;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateInput()) {
      await apiPost(`/admin/create-member`, userData, {}, true).then(
        (res) => {
          setUserId(res.data.data);
          setShowSuccess(true);
          setLoading(false);
        },
        (err) => {
          if (!err.response) {
            ErrorToast('Something went wrong');
          } else {
            ErrorToast(err.response.data.message);
          }
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const getTrustgroups = async () => {
    await apiGet(`/admin/get-all-trust-group`).then((res) => {
      setTrustGroups(res.data.data);
    });
  };

  useEffect(() => {
    getTrustgroups();
  }, []);

  if (showSuccess) {
    return <UserCreated userId={userId && userId} />;
  }
  return (
    <div style={{ minHeight: '100vh' }}>
      <HeaderWrapper>
        <Div>
          <Heading
            style={{ textAlign: 'left', fontSize: '24px', fontWeight: 'bold' }}
          >
            Create a user account
          </Heading>
          <Paragraph>
            Fill out the form below with correct details as specified
          </Paragraph>
        </Div>
        <Div>
          <Link
            to="/admin/registered-members"
            style={{ textDecoration: 'none' }}
          >
            <ViewMembers>View all members</ViewMembers>
          </Link>
        </Div>
      </HeaderWrapper>
      <AuthFormArea style={{ width: '100%' }}>
        <form>
          <Container>
            <InputWidth>
              <Input
                autoComplete="off"
                type="text"
                label="Full Name"
                name="fullName"
                value={fullName}
                onChange={changeHandler}
                onBlur={validateFullName}
                errorMessage={errors.fullName}
                placeholder="Full Name"
              />
            </InputWidth>
            <InputWidth>
              <Input
                autoComplete="off"
                type="date"
                label="Date of Birth"
                name="dateOfBirth"
                value={dateOfBirth}
                onChange={changeHandler}
                onBlur={validateDateOfBirth}
                errorMessage={errors.dateOfBirth}
              />
            </InputWidth>
            <InputWidth>
              <Input
                autoComplete="off"
                type="email"
                label="Email"
                name="email"
                value={email}
                onChange={changeHandler}
                onBlur={validateEmail}
                errorMessage={errors.email}
                placeholder="Email"
              />
            </InputWidth>

            <InputWidth>
              <Input
                type="tel"
                label="Phone Number"
                name="phone"
                value={phone}
                onChange={changeHandler}
                onBlur={validatePhone}
                errorMessage={errors.phone}
                placeholder="Phone Number"
              />
            </InputWidth>
            <InputWidth>
              <Input
                autoComplete="off"
                type="text"
                label="Address"
                name="addressLine"
                value={addressLine}
                onChange={changeHandler}
                onBlur={validateAddressLine}
                errorMessage={errors.addressLine}
                placeholder="Address"
              />
            </InputWidth>
            <InputWidth>
              <Input
                autoComplete="off"
                type="text"
                label="Designation"
                name="designation"
                value={designation}
                onChange={changeHandler}
                onBlur={validateDesignation}
                errorMessage={errors.designation}
                placeholder="Designation"
              />
            </InputWidth>
            <InputWidth>
              <SelectInput
                name="gender"
                label="Gender"
                onChange={changeHandler}
                onBlur={validateGender}
                errorMessage={errors.gender}
                value={gender}
                placeholder="Select Gender"
                options={genderList.map((gender) => ({
                  name: gender.label,
                  value: gender.value,
                }))}
              />
            </InputWidth>

            <InputWidth>
              <SelectInput
                name="trustGroupName"
                label="Trust Groups"
                onChange={changeHandler}
                value={trustGroupName}
                placeholder="Select Trust Group"
                options={trustGroups.map((trustGroup) => ({
                  name: trustGroup.name,
                  value: trustGroup.name,
                }))}
              />
            </InputWidth>
            <InputWidth>
              <Input
                autoComplete="off"
                type="number"
                label="Amount Loaned"
                name="amountLoaned"
                value={amountLoaned}
                onChange={changeHandler}
                onBlur={validateAmountLoaned}
                errorMessage={errors.amountLoaned}
                placeholder="N 0.00"
              />
            </InputWidth>

            <InputWidth>
              <Input
                autoComplete="off"
                type="text"
                label="Loan Structure/Rental"
                name="loanRestructureUrl"
                value={loanRestructureUrl}
                onChange={changeHandler}
                onBlur={validateLoanRestructureUrl}
                errorMessage={errors.loanRestructureUrl}
                placeholder="N 0.00"
              />
            </InputWidth>
          </Container>
          <UploadButton type="button" onClick={(e) => toggleModal(e)}>
            <UploadIcon src={Upload} alt="" />
            Upload Documents
          </UploadButton>
          {errors.termsAndConditionUrl && (
            <ErrorText>{errors.termsAndConditionUrl}</ErrorText>
          )}
          {errors.approvalLetterUrl && (
            <ErrorText>{errors.approvalLetterUrl}</ErrorText>
          )}

          <Modal
            title="Select files to upload"
            isOpen={showModal}
            closeModal={toggleModal}
          >
            <FileUploadForm
              setUserData={setUserData}
              closeModal={toggleModal}
            />
          </Modal>

          <MarginStyle>
            <Button type="submit" onClick={submitHandler}>
              {loading ? 'Submitting' : 'Submit'}
            </Button>
          </MarginStyle>
        </form>
      </AuthFormArea>
    </div>
  );
}
export default CreateUser;
