import Polygon from '../../assets/Polygon.svg';
import Polygon1 from '../../assets/Polygon1.svg';
import User from '../../assets/User.svg';
import User1 from '../../assets/User1.svg';
import Group from '../../assets/Group.svg';
import Group1 from '../../assets/Group1.svg';
import Settings from '../../assets/Settings-2.svg';
import Settings1 from '../../assets/Settings-1.svg';
import HelpDesk from '../../assets/HelpDesk.svg';
import HelpDesk1 from '../../assets/HelpDesk1.svg';
import Notifications from '../../assets/Notifications1.svg';
import Notifications1 from '../../assets/Notifications11.svg';
import Creditcard1 from '../../assets/Creditcard1.svg';
import Creditcard from '../../assets/Creditcard.svg';

export const adminSidebarMenu = [
  {
    label: 'Home',
    url: '/admin/home',
    icon: Polygon1,
    activeIcon: Polygon,
  },
  {
    label: 'Create Admin',
    url: '/admin/create-admin',
    icon: Polygon1,
    activeIcon: User1,
  },
  {
    label: 'Create User',
    url: '/admin/create-user',
    icon: User,
    activeIcon: User1,
  },
  {
    label: 'Trust Group',
    url:  '/admin/trust-group',
    icon: Group,
    activeIcon: Group1,
  },
  {
    label: 'Payment Record',
    url: '/admin/finance-record', 
    icon: Creditcard1,
    activeIcon: Creditcard,
  },
];

export const adminBottomMenu = [
  {
    label: 'Settings',
    url: '/admin/settings/home',
    icon: Settings,
    activeIcon: Settings1,
  },
];

export const userSidebarMenu = [
  {
    label: 'Home',
    url: '/user/home',
    icon: Polygon1,
    activeIcon: Polygon,
  },
  {
    label: 'Trust Group',
    url: ['/user/trust-group/transaction-history', '/user/trust-group/*'],
    icon: Group,
    activeIcon: Group1,
    multiple: true,
  },
  {
    label: 'Contact Admin',
    url: '/user/contact-admin',
    icon: HelpDesk,
    activeIcon: HelpDesk1,
  },
  {
    label: 'Notifications',
    url: '/user/notifications',
    icon: Notifications,
    activeIcon: Notifications1,
  },
];

export const userBottomMenu = [
  {
    label: 'Settings',
    url: '/user/settings/home',
    icon: Settings,
    activeIcon: Settings1,
  },
];

export const userTopBarMenu = [
  {
    label: 'Update Profile',
    url: '/user/user-profile',
  },
  {
    label: 'Change password',
    url: '/user/settings/home',
  },
  // {
  //   label: 'View Admin Profile',
  //   url: '/user/contact-admin',
  // }, // the view admin profile button was commented out as it is not yet sure if the page is needed since we have the contact admin page
];

export const adminTopBarMenu = [
  {
    label: 'Personal Info',
    url: '/admin/personal-info',
  },
  {
    label: 'Change password',
    url: '/admin/settings/security',
  },
];
