import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiGet } from '../../../utils/apiHelper';
import { TrustGroupDiv } from '../../../layouts/UserTrustGroupLayout';
import {
  ContentArea,
  Table,
  TBody,
  THead,
  TR,
} from '../../admin/CreateUser/AllRegisteredMembers';
import Pagination from '../../../components/common/pagination';
import Modal from '../../../components/common/NewModal';
import UserDetailCard from './UserDetailCard';

export default function TrustGroupMembers() {
  const [members, setMembers] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState('');

  const toggleShowDetails = () => setShowModal(!showModal);
  const ListPerPage = 10;
  const PageVisited = pageNumber * ListPerPage;

  useEffect(() => {
    apiGet(`/trustGroup/members?pageNo=${pageNumber}`, {}).then(
      (res) => {
        console.log({ res });
        setMembers(res.data.data);
      },
      (err) => {
        console.log({ err });
      }
    );
  }, [pageNumber]);

  const TD = styled.td`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    background: rgba(246, 246, 246, 0.49);
    border-bottom: 2px solid #dadada;
    height: 3rem;
    padding-left: 2rem;
    padding: 1rem;
  `;
  const TH = styled.th`
    border-bottom: 2px solid #dadada;
    font-style: normal;
    font-weight: bold;
    background-color: #f7f7f7;
    font-size: 14px;
    margin: 0px;
    padding-left: 2rem;
    padding: 1rem;
  `;
  return (
    <div>
      <Table>
        <THead>
          <TR
            style={{
              height: '4rem',
            }}
          >
            <TH>Name</TH>
            <TH>Gender</TH>
            <TH>Date joined</TH>
            <TH>Time joined</TH>
          </TR>
        </THead>
        <TBody>
          {members
            .slice(PageVisited, PageVisited + ListPerPage)
            .map((member, index) => (
              <TR
                key={index}
                onClick={() => {
                  if (activeIndex === '') {
                    toggleShowDetails();
                    setActiveIndex(index);
                  } else {
                    if (index === activeIndex) {
                      setActiveIndex('');
                      toggleShowDetails();
                    } else {
                      setActiveIndex(index);
                    }
                  }
                }}
                className={activeIndex === index && 'active'}
              >
                <TD>{member.fullName}</TD>
                <TD>{member.gender}</TD>
                <TD>
                  {new Date(member.createdAt).toLocaleDateString('en-NG')}
                </TD>
                <TD>
                  {new Date(member.createdAt).getHours() < 12 ? '0' : ''}
                  {new Date(member.createdAt).getHours()}
                  {':'}
                  {new Date(member.createdAt).getMinutes()}
                  {new Date(member.createdAt).getHours() < 12 ? ' AM' : ' PM'}
                </TD>
              </TR>
            ))}
          <TR>
            <TD>Sunday Ikeji</TD>
            <TD>Male</TD>
            <TD>04/04/2022</TD>
            <TD>04:45 PM</TD>
          </TR>
          <TR>
            <TD>Sunday Ikeji</TD>
            <TD>Male</TD>
            <TD>04/04/2022</TD>
            <TD>04:45 PM</TD>
          </TR>
          <TR>
            <TD>Sunday Ikeji</TD>
            <TD>Male</TD>
            <TD>04/04/2022</TD>
            <TD>04:45 PM</TD>
          </TR>
          <TR>
            <TD>Sunday Ikeji</TD>
            <TD>Male</TD>
            <TD>04/04/2022</TD>
            <TD>04:45 PM</TD>
          </TR>
          <TR>
            <TD>Sunday Ikeji</TD>
            <TD>Male</TD>
            <TD>04/04/2022</TD>
            <TD>04:45 PM</TD>
          </TR>
        </TBody>
      </Table>
      <Modal
        fontSize={'20px'}
        fontColor={'#21334f'}
        title="Profile Detail"
        isOpen={showModal}
        closeModal={toggleShowDetails}
      >
        <UserDetailCard details={members} />
      </Modal>
      
      <Pagination
        fetcheddata={members}
        itemsPerPage={10}
        setPageNumber={setPageNumber}
      />
    </div>
  );
}
