import React, { Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorBoundary from './components/common/ErrorBoundary';
import EmailSent from './pages/EmailSent';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Login from './pages/Login';
import AdminFirstLogin from './pages/AdminFirstLogin';
import LoginUserPage from './pages/LoginUsersPage';
import ChangePassword from './pages/ChangePassword';
import ResetPasswordPage from './pages/ResetPasswordPage';
import Layout from './layouts/Layout';
import Home from './pages/admin/Home';
import AuthLayout from './layouts/AuthLayout';
import CreateUser from './pages/admin/CreateUser';
import PersonalInfo from './pages/admin/PersonalInfo';
import TrustGroup from './pages/admin/TrustGroup/TrustGroup';
import CreateTrustGroup from './pages/admin/TrustGroup/CreateTrustGroup';
import PaymentRecord from './pages/admin/paymentRecord/PaymentRecord';
import SettingsLayout from './layouts/SettingsLayout';
import UserSettingsLayout from './layouts/UserSettingsLayout';
import TrustGroupDiscussion from './pages/admin/TrustGroup/TrustGroupDiscussion';
import AllRegisteredMembers from './pages/admin/CreateUser/AllRegisteredMembers';
import { GlobalProvider } from './context/GlobalState';
import SecurityAndLogin from './pages/admin/Settings/SecurityAndLogin';
import Notifications from './pages/admin/Settings/Notifications';
import AuthState from './context/auth/AuthState';
import 'react-toastify/dist/ReactToastify.css';
import ManageGroups from './pages/admin/Settings/ManageGroups';
import SettingsGeneral from './pages/admin/Settings/SettingsGeneral';
import ProtectedRoute from './context/auth/ProtectedRoute';
//import LoginUniqueId from './pages/LoginUniqueId';
import LoginUniqueIdPage from './pages/LoginUniqueIdPage';
import ContactAdmin from './pages/users/ContactAdmin';
import HomePagePersonal from './pages/Landing/HomePagePersonal';
import HomePageContactAdmin from './pages/Landing/HomePageContactAdmin';
import LoginPage from './pages/LoginPage';
import {
  adminSidebarMenu,
  adminBottomMenu,
  userSidebarMenu,
  userBottomMenu,
  userTopBarMenu,
  adminTopBarMenu,
} from './layouts/MenuItems/Menus';

import UserProfile from './pages/UserProfile';
import CreateAdmin from './pages/admin/CreateAdmin';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logout from './layouts/Logout';
import AllMembers from './pages/users/AllMembers';
// import UserSettingsGeneral from './pages/users/Settings/UserSettingsGeneral';
import UserNotification from './pages/users/Settings/UserNotification';
import UserSecurityAndLogin from './pages/users/Settings/UserSecurityAndLogin';
import UserHome from './pages/users/Home/Home';
import LandingBusiness from './pages/Landing/LandingBusiness';
import Notification from './pages/users/Notification/Notification';
import UserTrustGroupLayout from './layouts/UserTrustGroupLayout';
import TrustGroupTransactionHistory from './pages/users/TrustGroup/TrustGroupTransactionHistory';
import TrustGroupMembers from './pages/users/TrustGroup/TrustGroupMembers';
import MonthlyDues from './pages/users/TrustGroup/MonthlyDues';
import FinanceRecord from './pages/admin/paymentRecord/FinanceRecord';

function App() {
  const admin = 'admin';
  const personal = 'personal';
  return (
    <ErrorBoundary>
      <Suspense fallback="loading">
        <Router>
          <ToastContainer />
          <GlobalProvider>
            <AuthState>
              <Routes>
                <Route exact path="/business" element={<LandingBusiness />} />

                {/*
                <Route path="/trustdiscussion-users" element={<UsersTrustDiscussion />} /> */}
                <Route exact path="/" element={<HomePagePersonal />} />
                <Route exact path="/join" element={<HomePageContactAdmin />} />
                <Route exact path="/" element={<AuthLayout />}>
                  {/* <Route exact path="/" element={<Landing />} /> */}
                  {/* <Route exact path="/" element={<HomePagePersonal />} /> */}
                  <Route path="/email-sent" element={<EmailSent />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route
                    exact
                    path="/reset-password"
                    element={<ResetPasswordPage />}
                  />
                  <Route
                    exact
                    path="/login/new/uniqueId"
                    element={<LoginUniqueIdPage />}
                  />
                  <Route
                    path="/login/new/:uniqueId"
                    element={<LoginUserPage />}
                  />
                  <Route
                    path="/forgot-password"
                    element={<ForgotPasswordPage />}
                  />
                  <Route
                    path="/login"
                    element={<LoginPage newLogin={false} />}
                  />

                  <Route
                    path="/new-login"
                    element={<AdminFirstLogin newLogin={true} />}
                  />
                </Route>
                <Route
                  exact
                  path="/admin"
                  element={
                    <ProtectedRoute>
                      <Layout
                        pageType={admin}
                        sidebarMenuItems={adminSidebarMenu}
                        bottomMenuItems={adminBottomMenu}
                        topbarMenuItems={adminTopBarMenu}
                      />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    exact
                    path="create-admin"
                    element={
                      <ProtectedRoute>
                        <CreateAdmin />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="change-password"
                    element={
                      <ProtectedRoute>
                        <ChangePassword />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="home"
                    element={
                      <ProtectedRoute>
                        <Home />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="create-user"
                    element={
                      <ProtectedRoute>
                        <CreateUser />
                      </ProtectedRoute>
                    }
                  />
                  {/* <Route exact path="user-profile" element={<UserProfile />} /> */}
                  <Route
                    exact
                    path="registered-members"
                    element={
                      <ProtectedRoute>
                        <AllRegisteredMembers />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="trust-group"
                    element={
                      <ProtectedRoute>
                        <TrustGroup />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="create-trust-group"
                    element={
                      <ProtectedRoute>
                        <CreateTrustGroup />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    exact
                    path="finance-record"
                    element={
                      <ProtectedRoute>
                        <FinanceRecord />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    exact
                    path="finance-record/:id"
                    element={
                      <ProtectedRoute>
                        <PaymentRecord />
                      </ProtectedRoute>
                    }
                  />

                  <Route exact path="home" element={<Home />} />
                  <Route exact path="create-user" element={<CreateUser />} />
                  <Route
                    exact
                    path="personal-info"
                    element={<PersonalInfo />}
                  />
                  <Route exact path="trust-group" element={<TrustGroup />} />
                  <Route
                    exact
                    path="trust-group/:id"
                    element={
                      <ProtectedRoute>
                        <TrustGroupDiscussion />
                      </ProtectedRoute>
                    }
                  />
                  <Route exact path="settings" element={<SettingsLayout />}>
                    <Route
                      exact
                      path="security"
                      element={
                        <ProtectedRoute>
                          <SecurityAndLogin />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="manage-groups"
                      element={
                        <ProtectedRoute>
                          <ManageGroups />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="notifications"
                      element={
                        <ProtectedRoute>
                          <Notifications />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="home"
                      element={
                        <ProtectedRoute>
                          <SettingsGeneral />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  exact
                  path="/user"
                  element={
                    <ProtectedRoute>
                      <Layout
                        pageType={personal}
                        sidebarMenuItems={userSidebarMenu}
                        bottomMenuItems={userBottomMenu}
                        topbarMenuItems={userTopBarMenu}
                      />
                    </ProtectedRoute>
                  }
                >
                  <Route path="home" element={<UserHome />} />
                  <Route path="contact-admin" element={<ContactAdmin />} />
                  <Route
                    path="trust-group/all-members"
                    element={<AllMembers />}
                  />
                  <Route path="user-profile" element={<UserProfile />} />
                  <Route path="trust-group" element={<UserTrustGroupLayout />}>
                    <Route
                      exact
                      path="transaction-history"
                      element={<TrustGroupTransactionHistory />}
                    />
                    <Route
                      exact
                      path="members"
                      element={<TrustGroupMembers />}
                    />{' '}
                    <Route
                      exact
                      path="monthly-dues"
                      element={<MonthlyDues />}
                    />
                  </Route>
                  <Route exact path="settings" element={<UserSettingsLayout />}>
                    {/* <Route
                      exact
                      path="home"
                      element={
                        <ProtectedRoute>
                          <UserSettingsGeneral />
                        </ProtectedRoute>
                      }
                    /> */}
                    <Route
                      exact
                      path="notifications"
                      element={
                        <ProtectedRoute>
                          <UserNotification />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="home"
                      element={
                        <ProtectedRoute>
                          <UserSecurityAndLogin />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  <Route path="notifications" element={<Notification />} />
                  {/* <Route path="contact-admin" element={<HomePageContactAdmin />} /> */}
                </Route>
              </Routes>
            </AuthState>
          </GlobalProvider>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
export default App;