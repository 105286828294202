import React, { useState } from 'react';
import SupportShell from '../assets/SupportShell.svg';
import styled from 'styled-components';
import fpsideimage from '../assets/fpsideimage.svg';
import { Anchor, Center } from '../utils/common';
import {
  LoginSection,
  ImageSection,
  Wrapper,
  LogoWrapper,
  FormWrapper,
  Header,
  NavLabel,
  BackButton,
} from '../components/auth/common';
import Input from '../components/auth/newinput';
import Button from '../components/common/Button';
import { BiArrowBack } from 'react-icons/bi';
import { apiPost } from '../utils/apiHelper';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { toast } from 'react-toastify';

const LoginLogo = styled.img.attrs({
  src: `${SupportShell}`,
})``;

const SideImage = styled.img.attrs({
  src: `${fpsideimage}`,
})`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');

  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validateEmail()) {
        await apiPost('/auth/forgot-password', { email }, {}, false).then(
          () => {
            navigate(`/email-sent?email=${email}`);
          },
          (err) => {
            if (err.message === 'Network Error') {
              toast.error(err.message, { position: 'top-right' });
            } else if (!err.response.data) {
              toast.error('Something went wrong', { position: 'top-right' });
            } else {
              toast.error(err.response.data.message, { position: 'top-right' });
            }
          }
        );
      }
    } catch (error) {
      if (error.message === 'Network Error') {
        toast.error(error.message);
      } else if (!error.response.data) {
        toast.error('Something went wrong');
      } else {
        toast.error(error.response.data.message);
      }
    }
  };

  const validateEmail = () => {
    if (validator.isEmail(email)) {
      setError('');
      return true;
    }
    setError('Invalid Email');
    return false;
  };

  return (
    <Wrapper>
      <LoginSection>
        <LogoWrapper>
          <LoginLogo />
          <NavLabel>Support Shell</NavLabel>
        </LogoWrapper>
        <FormWrapper>
          <Header>Forgot Password</Header>
          <form onSubmit={handleSubmit}>
            <Input
              type="email"
              label="Email"
              value={email}
              onChange={handleInputChange}
              helperText="Enter the Email address associated with your account"
              isTrueOrFalse={true}
              error={error}
              errorMessage={error}
            />
            <Button type="submit">Reset Password</Button>
            <Center>
              <Anchor href="/login">
                <BackButton>
                  <BiArrowBack style={{ marginRight: '15px' }} /> Back to Login
                </BackButton>
              </Anchor>
            </Center>
          </form>
        </FormWrapper>
      </LoginSection>
      <ImageSection>
        <SideImage />
      </ImageSection>
    </Wrapper>
  );
}

export default ForgotPasswordPage;
