import React from 'react';
import styled from 'styled-components';
// import UserProfile from './UserProfile';
// import Input from '../../components/common/Input';

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 36px 0 16px 0;
`;

const TopContainer = styled.div`
  display: flex;
  background-color: white;
  padding-bottom: 30px;
  height: 100vh;
`;
// const ViewAll = styled.a`
//   width: 122px;
//   height: 35px;
//   background: rgba(20, 168, 0, 0.05);
//   border-radius: 6px;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   text-decoration: none;
//   color: #14a800;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 12px;
//   &:hover {
//     color: #14a800;
//   }
// `;

const Table = styled.table`
  width: 100%;
  border-right: 1px solid #dadada;
`;

const TR = styled.tr`
  &:hover {
    background-color: #90ee90;
  }
`;

const THead = styled.thead`
  height: 48px;
  }
`;

const TH = styled.td`
  text-align: center;
  border-bottom: 1px solid #dadada;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  background-color: white;
`;

const TBody = styled.tbody`
  background-color: white;
`;

const Completed = styled.span`
  color: #14a800;
  padding: 10px;
`;

const Pending = styled.span`
  color: #ee9b4a;
  padding: 20px;
`;

const TD = styled.td`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  border-bottom: 1px solid #dadada;
  height: 40px;
  text-align: center;
`;

const Showmore = styled.div`
  background: white;
  text-align: center;
  padding-top: 8px;
  width: 10vw;
  height: 5vh;
  border: 1px solid black;
  border-radius: 10px;
  margin-left: Auto;
  margin-right: Auto;
  margin-top: 10px;
`;

const SelectTag = styled.select`
  border: none;
  background-color: rgba(20, 168, 0, 0.05);
`;

const SpanTag = styled.span`
  border: none;
  background-color: rgba(20, 168, 0, 0.05);
  padding: 15px;
`;

export default function AllMembers() {
  return (
    <div>
      <Top>
        <Title>All Registered Members</Title>
        <div>
          <SpanTag>
            Sort by :
            <SelectTag>
              <option>Newest</option>
            </SelectTag>
          </SpanTag>
        </div>
      </Top>
      <TopContainer>
        <Table>
          <THead>
            <TR>
              <TH>Name</TH>
              <TH>Address</TH>
              <TH>Date</TH>
              <TH>Status</TH>
            </TR>
          </THead>
          <TBody>
            <TR>
              <TD>Chiebuka Ikenna</TD>
              <TD>10, Ayo Street, Ikoyi, Lagos</TD>
              <TD>1/02/2022</TD>
              <TD>
                <Completed>Complete</Completed>
              </TD>
            </TR>
            <TR>
              <TD>Chiebuka Ikenna</TD>
              <TD>10, Ayo Street, Ikoyi, Lagos</TD>
              <TD>1/02/2022</TD>
              <TD>
                <Completed>Complete</Completed>
              </TD>
            </TR>
            <TR>
              <TD>Chiebuka Ikenna</TD>
              <TD>10, Ayo Street, Ikoyi, Lagos</TD>
              <TD>1/02/2022</TD>
              <TD>
                <Pending>Pending</Pending>
              </TD>
            </TR>
            <TR>
              <TD>Chiebuka Ikenna</TD>
              <TD>10, Ayo Street, Ikoyi, Lagos</TD>
              <TD>1/02/2022</TD>
              <TD>
                <Completed>Complete</Completed>
              </TD>
            </TR>
            <TR>
              <TD>Chiebuka Ikenna</TD>
              <TD>10, Ayo Street, Ikoyi, Lagos</TD>
              <TD>1/02/2022</TD>
              <TD>
                <Completed>Complete</Completed>
              </TD>
            </TR>
            <TR>
              <TD>Chiebuka Ikenna</TD>
              <TD>10, Ayo Street, Ikoyi, Lagos</TD>
              <TD>1/02/2022</TD>
              <TD>
                <Completed>Complete</Completed>
              </TD>
            </TR>
            <TR>
              <TD>Chiebuka Ikenna</TD>
              <TD>10, Ayo Street, Ikoyi, Lagos</TD>
              <TD>1/02/2022</TD>
              <TD>
                <Pending>Pending</Pending>
              </TD>
            </TR>
            <TR>
              <TD>Chiebuka Ikenna</TD>
              <TD>10, Ayo Street, Ikoyi, Lagos</TD>
              <TD>1/02/2022</TD>
              <TD>
                <Pending>Pending</Pending>
              </TD>
            </TR>
            <TR>
              <TD>Chiebuka Ikenna</TD>
              <TD>10, Ayo Street, Ikoyi, Lagos</TD>
              <TD>1/02/2022</TD>
              <TD>
                <Pending>Pending</Pending>
              </TD>
            </TR>
            <TR>
              <TD>Chiebuka Ikenna</TD>
              <TD>10, Ayo Street, Ikoyi, Lagos</TD>
              <TD>1/02/2022</TD>
              <TD>
                <Pending>Pending</Pending>
              </TD>
            </TR>
          </TBody>
        </Table>
      </TopContainer>
      <Showmore>See More</Showmore>
    </div>
  );
}
