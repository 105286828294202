import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router';
// import { SpaceBetween } from '../../../utils/common';
import TrustGroupCard from './TrustGroupCard';
import { apiGet } from '../../../utils/apiHelper';
import { toast } from 'react-toastify';
import Pagination from '../../../components/common/pagination';

const PageContainer = styled.div`
  min-height: 100vh;
`;

const PageArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  justify-content: space-between;
  margin-top: 30px;
  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 420px) {
    grid-template-columns: 1fr;
  }
`;

const PageTitle = styled.h1`
  font-style: normal;
  font-weight: bold;
  color: #21334f;
  font-size: 24px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 420px) {
    font-style: normal;
    display: flex;
    align-items: left;
  }

  @media screen and (max-width: 768px) {
    font-style: normal;
    align-items: left;
  }
`;

const AddIconDiv = styled.div`
  background: #ffffff;
  mix-blend-mode: normal;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonText = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 10px;
  color: #ffffff;
  margin-left: 10px;
`;



const ButtonDiv = styled.div`
  width: 231px;
  height: 56px;
  background: #14a800;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 170px;
    height: 50px;
} 
`;

export default function TrustGroup() {
  const [trustGroups, setTrustGroups] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  let navigate = useNavigate();
  const ListPerPage = 6;
  const PageVisited = pageNumber * ListPerPage;
  const HandleNavigate = () => {
    navigate('/admin/create-trust-group');
  };

  useEffect(() => {
    getTrustGroup();
  }, []);

  const getTrustGroup = async () => {
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await apiGet(`/admin/get-all-trust-group`, config, true).then(
      (res) => {
        setTrustGroups(res?.data?.data.reverse());
      },
      (err) => {
        toast.error(err?.response?.data?.message || "An Error Occured", { position: 'top-right' });
      }
    );
  };

  return (
    <PageContainer>
      <SpaceBetween>
        <PageTitle>All Trust Groups</PageTitle>
        <ButtonDiv onClick={HandleNavigate}>
          <AddIconDiv>
            <AiOutlinePlus style={{ color: '#14A800' }} />
          </AddIconDiv>
          <ButtonText>Create Group</ButtonText>
        </ButtonDiv>
      </SpaceBetween>
      <PageArea>
        {trustGroups
          .slice(PageVisited, PageVisited + ListPerPage)
          .map((group) => {
            // let formattedDate = new Date(group.createDate);
            return (
              <TrustGroupCard
                title={group.name}
                // date={formattedDate.toLocaleDateString()}
                // time={formattedDate.toLocaleTimeString()}
                // createdBy="Adekunle"
                members={group.members}
                id={'1'}
              />
            );
          })}
      </PageArea>
      <Pagination
        fetcheddata={trustGroups}
        itemsPerPage={6}
        setPageNumber={setPageNumber}
      />
    </PageContainer>
  );
}
