import React from 'react';
import styled from 'styled-components';
import { Box } from '../home/TotalMembers';
import BalanceBackground from '../../assets/LoanBalanceBackground.svg';
import Defaulter from '../../assets/PaidDefault.svg';
import Pending from '../../assets/PaidPending.svg';
import Wallet from '../../assets/Wallet.svg';
import { SpaceBetween } from '../../utils/common';

const LoanBalanceBox = styled(Box)`
  color: #ffffff;
  background: url(${({ status }) =>
    status === 'Cleared'
      ? BalanceBackground
      : status === 'Defaulter'
      ? Defaulter
      : Pending});
  background-size: cover;
  background-position: center center;
`;
const SpaceBetweenExt = styled(SpaceBetween)`
  align-items: flex-start;
`;

const Balance = styled.div`
  font-size: 44px;
  font-weight: bold;
`;

const BalanceLabel = styled.p`
  margin-bottom: 0;
`;

const Status = styled.div`
  border: 1px solid #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  width: auto;
  height: 27px;
  font-size: 12px;
`;

const AmountLoaned = styled.div`
  width: 201px;
  height: 62px;
  background: rgba(0, 0, 0, 0.05);
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const Icon = styled.div`
  padding-right: 10px;
  display: flex;
  align-items: center;
`;

const AmountLoanedLabel = styled.div`
  font-size: 14px;
  margin-bottom: 0;
`;

const AmountLoanedFig = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

const LabelArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default function LoanBalance({ status }) {
  return (
    <LoanBalanceBox status={status || 'Cleared'}>
      <SpaceBetweenExt>
        <div>
          <BalanceLabel>Loan Balance</BalanceLabel>
          <Balance>N3,000,000</Balance>
        </div>
        <Status>
          <div>Status: {status || 'Cleared'}</div>
        </Status>
      </SpaceBetweenExt>
      <AmountLoaned>
        <Icon>
          <img src={Wallet} alt="" />
        </Icon>
        <LabelArea>
          <AmountLoanedLabel>Total amount loaned</AmountLoanedLabel>
          <AmountLoanedFig>N3,000,000</AmountLoanedFig>
        </LabelArea>
      </AmountLoaned>
    </LoanBalanceBox>
  );
}
