import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextInputArea = styled.textarea`
  background: transparent;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  box-sizing: border-box;
  margin-bottom: 1rem;
`;

const Label = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 10px;
  color: ${({ labelColor }) => (labelColor ? labelColor : '#21334f')};
`;
// const FormGroup = styled.div`
//   position: relative;
// `;

const ErrorText = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: ${({ errorMessage }) => (errorMessage ? errorMessage : '#de1e1e')};
  margin-bottom: 0;
  margin-top: 10px;
`;

export default function TextArea({
  label,
  labelColor,
  placeholder,
  onChange,
  value,
  name,
  style,
  rows,
  columns,
  className,
  error,
  errorMessage,
  onBlur,
  isTrueOrFalse = false,
}) {
  return (
    <div>
      <InputArea>
        <Label labelColor={labelColor}>{label}</Label>
        <TextInputArea
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`${className} ${error ? 'input-error' : ''}`}
          name={name}
          rows={rows}
          cols={columns}
          style={style}
          onBlur={onBlur}
          required={isTrueOrFalse}
        />
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      </InputArea>
    </div>
  );
}

TextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  rows: PropTypes.number,
  columns: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};
