import React from 'react';
import styled from 'styled-components';
import { Title, Top } from '../home/RegisteredMembers';

const Clear = styled.p`
  color: #979797;
  font-size: 14px;
`;
const Table = styled.table`
  width: 100%;
`;

const TR = styled.tr`
  &:nth-child(2n + 1) {
    background: #ffffff;
  }
`;

const THead = styled.thead`
  background: #e5e5e5;
  height: 48px;
  ${TR} {
    background: #e5e5e5;
  }
`;

const TH = styled.td`
  border: 1px solid #dadada;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 0 20px 0 20px;
`;

const TBody = styled.tbody`
  height: 305px;
  overflow: hidden;
`;

const TD = styled.td`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  background: rgba(246, 246, 246, 0.49);
  border: 1px solid #dadada;
  height: 40px;
  padding: 0 20px 0 20px;
`;

export const Badge = styled.span`
  background: ${({ status }) =>
    status ? 'rgba(20, 168, 0, 0.05)' : 'rgba(222, 30, 30, 0.1)'};
  color: ${({ status }) => (status ? '#14A800' : '#DE1E1E')};
  border-radius: 100px;
  padding: 4px 6px;
  width: auto;
`;

export default function TransactionHistory() {
  return (
    <div>
      <Top>
        <Title>Personal Transactions</Title>
        <Clear>Clear</Clear>
      </Top>
      <Table>
        <THead>
          <TR>
            <TH>Transaction</TH>
            <TH>Date</TH>
            <TH>Amount</TH>
            <TH>Status</TH>
          </TR>
        </THead>
        <TBody>
          <TR>
            <TD>Part Loan Payment</TD>
            <TD>22/12/2021</TD>
            <TD>N80,000</TD>
            <TD>
              <Badge status={true}>Paid</Badge>
            </TD>
          </TR>
          <TR>
            <TD>Trust Group payment by Michael</TD>
            <TD>22/12/2021</TD>
            <TD>N9,000</TD>
            <TD>
              <Badge status={false}>Declined</Badge>
            </TD>
          </TR>
          <TR>
            <TD>Trust Group payment by Onyinye</TD>
            <TD>20/12/2021</TD>
            <TD>N70,000</TD>
            <TD>
              <Badge status={true}>Paid</Badge>
            </TD>
          </TR>
          <TR>
            <TD>Trust Group payment by Sanusi</TD>
            <TD>19/12/2021</TD>
            <TD>N80,000</TD>
            <TD>
              <Badge status={false}>Declined</Badge>
            </TD>
          </TR>
          <TR>
            <TD>Part loan payment</TD>
            <TD>19/11/2021</TD>
            <TD>N70,000</TD>
            <TD>
              <Badge status={true}>Paid</Badge>
            </TD>
          </TR>
          <TR>
            <TD>Trust Group payment by Nkechi</TD>
            <TD>17/11/2021</TD>
            <TD>N9,000</TD>
            <TD>
              <Badge status={false}>Declined</Badge>
            </TD>
          </TR>
          <TR>
            <TD>Trust Group payment by Onyinye</TD>
            <TD>14/11/2021</TD>
            <TD>N70,000</TD>
            <TD>
              <Badge status={false}>Declined</Badge>
            </TD>
          </TR>
        </TBody>
      </Table>
    </div>
  );
}
