import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/common/Button';
import Input from '../../components/common/Input';
import Success from '../../components/modal/Success';
import { apiPost } from '../../utils/apiHelper';
import validator from 'validator';

import {
  AuthFormArea,
  BackButton,
  Heading,
  Container,
  InputWidth,
  MarginStyle,
  Paragraph,
  Div,
} from '../../utils/common';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/auth/AuthState';

function CreateAdmin() {
  
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
  });
  const [error, setError] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    phoneNo: '',
  });
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { firstName, lastName, email, phoneNo } = userData;
  console.log(isLoading);


  const { organizationId } = useAuth();

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateInput = (input) => {
    if (validator.isEmpty(userData[input])) {
      setError((prev) => {
        return { ...prev, [input]: 'Invalid Input' };
      });
      setIsValid(false);
    } else {
      setError((prev) => {
        return { ...prev, [input]: '' };
      });
      setIsValid(true);
    }
  };

  const validateEmail = () => {
    if (!validator.isEmail(email)) {
      setError((prevState) => ({
        ...prevState,
        email: 'Enter a valid email',
      }));
      setIsValid(false);
      return false;
    } else {
      setError((prevState) => ({
        ...prevState,
        email: '',
      }));
      setIsValid(true);
      return true;
    }
  };

  const validatephone = () => {
    if (phoneNo.length < 8 ) {
      setError((prevState) => ({
        ...prevState,
        phoneNo: 'Enter a valid phone',
      }));
      setIsValid(false);
      return false;
    } else {
      setError((prevState) => ({
        ...prevState,
        phoneNo: '',
      }));
      setIsValid(true);
      return true;
    }
  };

  const validate = () => {
    setIsValid(validateInput('firstName'));
    setIsValid(validateInput('lastName'));
    setIsValid(validateEmail());
    setIsValid(validatephone());

    return isValid;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (validate()) {
      const config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      try {
        const createData = {
          email: userData.email,
          fullName: `${userData.firstName} ${userData.lastName}`,
          phoneNo: userData.phoneNo,
          orgId: organizationId,
        };

        apiPost(`/admin/create-admin`, createData, config, true).then(
          (res) => {
            console.log({ res });
            toast.success(res.message, { position: 'top-right' });
            setShowSuccess(true);
            setTimeout(() => {
              setUserData({
                email: '',
                firstName: '',
                lastName: '',
                phoneNo: '',
              });
              setShowSuccess(false);
              setIsLoading(false);
            }, 5000);
          },
          (err) => {
            console.log({ err });
            if (err.message === 'Network Error') {
              toast.error(err.message, { position: 'top-right' });
            } else if (!err.response.data) {
              toast.error('Something went wrong', { position: 'top-right' });
            } else {
              toast.error(err.response.data.message, { position: 'top-right' });
            }
            setIsLoading(false);
          }
        );
      } catch (error) {
        console.log({ error });
        if (error.message === 'Network Error') {
          toast.error(error.message, { position: 'top-right' });
        } else if (!error.response.data) {
          toast.error('Something went wrong', { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
        setIsLoading(false);
      }
    }
  };

  if (showSuccess) {
    return <Success message="User Created Successfully" />;
  }
  return (
    <div style={{ height: '100vh' }}>
      <Container>
        <Div>
          <Heading
            style={{ textAlign: 'left', fontSize: '24px', fontWeight: 'bold' }}
          >
            Create an Admin account
          </Heading>
          <Paragraph>
            Fill out the form below with correct details as specified
          </Paragraph>
        </Div>
        <Div>
          <Link to="/registered-members" style={{ textDecoration: 'none' }}>
            <BackButton>View all Admins</BackButton>
          </Link>
        </Div>
      </Container>
      <AuthFormArea style={{ width: '100%' }}>
        <form onSubmit={submitHandler}>
          <Container>
            <InputWidth>
              <Input
                type="text"
                label="First Name"
                name="firstName"
                value={firstName}
                onChange={changeHandler}
                onBlur={() => validateInput('firstName')}
                errorMessage={error.firstName}
                error={error.firstName ? true : false}
              />
            </InputWidth>
            <InputWidth>
              <Input
                type="text"
                label="Last Name"
                name="lastName"
                value={lastName}
                onChange={changeHandler}
                onBlur={() => validateInput('lastName')}
                errorMessage={error.lastName}
                error={error.lastName ? true : false}
              />
            </InputWidth>
          </Container>
          <Container>
            <InputWidth>
              <Input
                type="email"
                label="Email"
                name="email"
                value={userData.email}
                onChange={changeHandler}
                onBlur={validateEmail}
                errorMessage={error.email}
                error={error.email ? true : false}
              />
            </InputWidth>
            <InputWidth>
              <Input
                type="tel"
                label="Telephone Number"
                name="phoneNo"
                value={userData.phoneNo}
                onChange={changeHandler}
                onBlur={validatephone}
                errorMessage={error.phoneNo}
                error={error.phoneNo ? true : false}
              />
            </InputWidth>
          </Container>
          <MarginStyle>
            <Button type="submit" disabled={!isValid}>
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          </MarginStyle>
        </form>
      </AuthFormArea>
    </div>
  );
}
export default CreateAdmin;
