import React from 'react'
import styled from 'styled-components'
import Group from '../../../assets/Group.svg'

const ItemArea = styled.div`
  padding: 20px 20px;
  height: 88px;
  background: #FFFFFF;
  border-radius: 4px;
  display: flex;
  margin-bottom: 20px;
`

const TitleDateTime = styled.div`
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  @media (max-width: 768px) {
   
  }
`;

const Item = styled.p`
  padding-right: 43px;
  margin-bottom: 0;
  @media (max-width: 768px) {
    padding-right: 0px;
  }
`

const ItemRight = styled.div`
  display: flex;
  justify-content: end;
`

const Description = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;

const Icon = styled.div`
  background: rgba(0, 0, 0, 0.05);
  width: 42px;
  height: 42px;
  border-radius: 100%;
  margin-right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;


export default function NotificationItem({title, description, date, count = 0}) {
  return (
    <ItemArea>
      <Icon>
        <img src={Group} alt="" />
      </Icon>
      <div>
        <TitleDateTime>
          <Item>Payment updated by Admin</Item>
          <ItemRight>04/12/2021 04:15PM</ItemRight>
        </TitleDateTime>
        <Description>Multiple payments was made</Description>
      </div>
    </ItemArea>
  );
}
